import * as actions from './actions'
import { appTypeConfig, orderListTimeTypes, shownPriceTypes } from '../config'
import * as authActions from '../auth/actions'

export const tracerDefaultConfig = {
  // fileOnly: false,
  tracerKey: '',
  name: '',
  urlName: '',
  baud: '',
  flow: '',
  parity: '',
  data: '',
  stop: '',
  vca: false,
  showVca: false,
  usb: false,
  lan: false,
  tracerIp: '', // ip address of tracer, used with VSRLAN, VCRLAN
  bridgeIp: '127.0.0.1:33333',
  port: '',
  tcpPort: '',
}

export const State = {
  visualiserScale: null,
  tracerEnabled: false,

  // this is old tracer settings
  tracerKey: '',
  tracer: {
    // ...tracerDefaultConfig,
  },
  tracerPort: '',
  tracerBridgeIp: '',
  tracerIp: '',
  tcpPort: '',

  // this is new
  defaultTracer: null,
  tracers: [],
  tracersLoaded: false,

  tablet: {
    width: '', // real width of tablet in cm
    height: '',
    xRatio: 1,
    yRatio: 1,
    calibrationDone: false, // calibration scale (real mm vs visible)
    ratio: false, // ratio based on real tablet width x height
    visualiserScale: 1,
    axisDeformation: null,
  },
  orderListTimeType: orderListTimeTypes.ALL_STATES,
  isReferenceDuplicityCheckEnabled: false,
  copyReferenceOnDuplication: appTypeConfig.defaultCopyReferenceOnDuplication,
  isSummaryTooltipDisabled: false,
  isPriceShown: true,
  priceType: shownPriceTypes.RECOM_SELLINGPRICE_LEVEL2,
  defaultChamfer: appTypeConfig.defaultDefaultChamferSettings,
  isWorkerInOrdersList: false, // adds workers name to orders list as a column
}

export const getTracerSettingsKeys = () => Object.keys(tracerDefaultConfig)

const configReducer = (state = State, action) => {
  switch (action.type) {
    case actions.SET_VISUALISER_SCALE_CONFIG: {
      return {
        ...state,
        visualiserScale: action.payload.scale,
      }
    }

    case authActions.SIGN_OUT: {
      // clear everything tied to logged user
      return {
        ...state,
        // TODO: co jeste budeme mazat???
        tracers: [],
        tracersLoaded: false,
        defaultTracer: null,
      }
    }

    case actions.FETCH_TRACERS_SUCCESS: {
      const { data } = action.payload

      return {
        ...state,
        tracers: data,
        tracersLoaded: true,
      }
    }

    case actions.DELETE_TRACER_SUCCESS: {
      const { tracers } = state
      const { _id } = action.meta.action
      return {
        ...state,
        tracers: tracers.filter(w => w._id !== _id),
      }
    }

    case actions.EDIT_TRACER_SUCCESS: {
      const { tracers } = state
      const { _id } = action.meta.action
      const { data } = action.payload
      return {
        ...state,
        tracers: tracers.map(w => {
          if (w._id !== _id) {
            return w
          }
          return {
            ...w,
            ...data,
          }
        }),
      }
    }
    case actions.CREATE_TRACER_SUCCESS: {
      const { tracers } = state
      const { data } = action.payload
      return {
        ...state,
        tracers: [...tracers, data],
      }
    }

    case actions.SET_ORDERS_CONFIG: {
      const {
        orderListTimeType,
        isReferenceDuplicityCheckEnabled,
        copyReferenceOnDuplication,
        isSummaryTooltipDisabled,
        isWorkerInOrdersList,
      } = action.payload || {}
      return {
        ...state,
        orderListTimeType,
        isReferenceDuplicityCheckEnabled,
        copyReferenceOnDuplication,
        isSummaryTooltipDisabled,
        isWorkerInOrdersList,
      }
    }

    case actions.SET_PRICES_CONFIG: {
      const { isPriceShown, priceType } = action.payload
      const newState = { ...state }
      if (typeof isPriceShown !== 'undefined') newState.isPriceShown = isPriceShown
      if (typeof priceType !== 'undefined') newState.priceType = priceType
      return newState
    }

    case actions.SET_STEP2_CONFIG: {
      const { defaultChamfer } = action.payload
      const newState = { ...state, defaultChamfer }
      return newState
    }

    case actions.SET_TABLET_CONFIG: {
      const { width, height, xRatio, yRatio } = action.payload

      let { calibrationDone, visualiserScale, axisDeformation } = state
      if (xRatio && yRatio) {
        visualiserScale = yRatio
        axisDeformation = yRatio / xRatio
        calibrationDone = true
      }
      console.log('visualiserScale', visualiserScale, axisDeformation)
      return {
        ...state,
        tablet: {
          ...state.tablet,
          ...action.payload,
          calibrationDone,
          visualiserScale,
          axisDeformation,
        },
      }
    }

    case actions.SET_TRACER_CONFIG: {
      const {
        tracerEnabled,
        // tracerKey,
        // tracerBridgeIp,
        // tracerPort,
        // ...rest
      } = action.payload
      return {
        ...state,
        // tracer: {
        //   ...rest,
        // },
        tracerEnabled,
        // tracerKey,
        // tracerBridgeIp,
        // tracerPort,
      }
    }
    case actions.SET_DEFAULT_TRACER: {
      return {
        ...state,
        defaultTracer: action.payload,
      }
    }
    case actions.MERGE_CONFIG: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state
  }
}

export default configReducer
