import i18n from '../i18n/i18n'

export const allTracerConfigKeys = [
  'baud',
  'data',
  'flow',
  'lan',
  'name',
  'parity',
  'showVca',
  'stop',
  'urlName',
  'usb',
  'vca',
  'tcpPort',
]

// default config for different tracers
export const tracers = {
  // fileOnly: {
  //   name: i18n.t('fileOnly'),
  //   fileOnly: true,
  //   urlName: '', // tracer name for url config
  //   baud: '',
  //   flow: '',
  //   parity: '',
  //   data: '',
  //   stop: '',
  //   vca: false,
  //   showVca: false,
  // },
  itronic: {
    name: 'i-Tronics 1000',
    urlName: 'ESLTES',
    baud: '19200',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: true,
  },
  // ESLLAN: i-Tronics 1000 LAN
  itronics1000Lan: {
    name: 'i-Tronics 1000 LAN',
    urlName: 'ESLLAN',
    baud: '0',
    flow: '1',
    parity: '0',
    data: '0',
    stop: '0',
    vca: true,
    showVca: false,
    lan: true,
  },
  bricva: {
    name: 'Briot Scanform Net II', // tracer name for settings form
    urlName: 'BRIVCA', // tracer name for url config
    baud: '19200',
    flow: '1',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  bricva3: {
    name: 'Briot Scanform Net III',
    urlName: 'BRIVCA',
    baud: '19200',
    flow: '1',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  // VCRLAN: Briot Scan 8 LAN
  briotScan8Lan: {
    name: 'Briot Scan 8 LAN',
    urlName: 'VCRLAN',
    baud: '0',
    flow: '1',
    parity: '0',
    data: '0',
    stop: '0',
    vca: true,
    showVca: false,
    lan: true,
    tcpPort: '50000',
  },
  eslkap: {
    name: 'Essilor Kappa',
    urlName: 'ESLKAP',
    baud: '19200',
    flow: '2',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: false,
    showVca: false,
  },
  eslBlue: {
    name: 'Essilor Mr. Blue',
    urlName: 'ESLVCA',
    baud: '19200',
    flow: '1',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  // ESLLAN: Essilor Mr. Blue LAN
  essilorMrBlueLan: {
    name: 'Essilor Mr. Blue LAN',
    urlName: 'ESLLAN',
    baud: '0',
    flow: '1',
    parity: '0',
    data: '0',
    stop: '0',
    vca: true,
    showVca: false,
    lan: true,
  },
  eslOrange: {
    name: 'Essilor Mr. Orange',
    urlName: 'ESLVCA',
    baud: '19200',
    flow: '1',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  // ESLLAN: Essilor Mr. Orange LAN
  essilorMrOrangeLan: {
    name: 'Essilor Mr. Orange LAN',
    urlName: 'ESLLAN',
    baud: '0',
    flow: '1',
    parity: '0',
    data: '0',
    stop: '0',
    vca: true,
    showVca: false,
    lan: true,
  },
  eslPhi: {
    name: 'Essilor Phi',
    urlName: 'ESLNAT',
    baud: '19200',
    flow: '2',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: true,
  },
  eslTes: {
    name: 'Essilor Tess',
    urlName: 'ESLTES',
    baud: '19200',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: true,
  },
  // ESLLAN: Essilor Tess LAN
  essilorTessLan: {
    name: 'Essilor Tess LAN',
    urlName: 'ESLLAN',
    baud: '0',
    flow: '1',
    parity: '0',
    data: '0',
    stop: '0',
    vca: true,
    showVca: false,
    lan: true,
  },
  eslLan: {
    name: 'Essilor LAN',
    urlName: 'LANVCA',
    baud: '0',
    flow: '1',
    parity: '0',
    data: '0',
    stop: '0',
    vca: true,
    lan: true,
    showVca: false,
  },
  fuji: {
    name: 'Fuji',
    urlName: 'FJINAT',
    baud: '19200',
    flow: '0',
    parity: 'E',
    data: '8',
    stop: '2',
    vca: true,
    showVca: true,
  },
  hoyaGt: {
    name: 'Hoya GT series',
    urlName: 'HOYNAT',
    baud: '9600',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: true,
  },
  hoyaGt3: {
    name: 'Hoya GT 3000 USB',
    urlName: fields => fields.vca ? 'HG3VCA' : 'HG3NAT',
    flow: '',
    parity: '',
    data: '',
    stop: '',
    vca: true,
    usb: true,
    showVca: true,
  },
  hoyaGt5: {
    name: 'Hoya GT 5000 USB',
    urlName: 'H5UVCA',
    flow: '',
    parity: '',
    data: '',
    stop: '',
    vca: true,
    usb: true,
    showVca: false,
  },
  hoyaGt5H3usb: {
    name: 'Hoya GT 5000 USB Hoya3',
    urlName: 'H5UHO3',
    flow: '',
    parity: '',
    data: '',
    stop: '',
    vca: false,
    usb: true,
    showVca: false,
  },
  hoyaGt5H3: {
    name: 'Hoya GT 5000 Hoya3',
    urlName: 'HGSHO3',
    baud: '9600',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: false,
    showVca: false,
  },
  hoyaUt: {
    name: 'Hoya UT series',
    urlName: 'HOYVCA',
    baud: '19200',
    flow: '2',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  huvitz: {
    name: 'Huvitz CRF-4000',
    urlName: 'HVZVCA',
    baud: '38400',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  indoCombimax: {
    name: 'Indo Combimax',
    urlName: 'INDVCA',
    baud: '9600',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  indoSchoene: {
    name: 'Indo Schoene',
    urlName: 'INDNAT',
    baud: '9600',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: true,
  },
  natOpt4t: {
    name: 'Nat. Opt. 4T',
    urlName: 'NOPVCA',
    baud: '19200',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  natOpt5t: {
    name: 'Nat. Opt. 5T',
    urlName: 'NOPVCA',
    baud: '19200',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  natOptDimension: {
    name: 'Nat. Opt. Dimension',
    urlName: 'NOPVCA',
    baud: '19200',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  nidek: {
    name: 'Nidek LT series',
    urlName: 'NDKNAT',
    baud: '9600',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: true,
  },
  // NDKDEV: Nidek LT 980
  nidekLt980: {
    name: 'Nidek LT 980',
    urlName: 'NDKDEV',
    baud: '9600',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: false,
    showVca: false,
  },
  takubomatic: {
    name: 'Takubomatic FD-80',
    urlName: 'TKBNAT',
    baud: '9600',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: true,
  },
  topcon: {
    name: 'Topcon FR-50',
    urlName: 'TPCVCA',
    baud: '9600',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  // VCRLAN: Universal VCA LAN Client
  universalVcaLanClient: {
    name: 'Universal VCA LAN Client',
    urlName: 'VCRLAN',
    baud: '0',
    flow: '1',
    parity: '0',
    data: '0',
    stop: '0',
    vca: true,
    showVca: false,
    lan: true,
    tcpPort: '50000',
  },
  // VSRLAN: Universal VCA LAN Server
  universalVcaLanServer: {
    name: 'Universal VCA LAN Server',
    urlName: 'VSRLAN',
    baud: '0',
    flow: '1',
    parity: '0',
    data: '0',
    stop: '0',
    vca: true,
    showVca: false,
    lan: true,
    tcpPort: '50000',
  },
  visslo: {
    name: 'Visslo ST-88',
    urlName: 'TPCVCA',
    baud: '57600',
    flow: '0',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  weco2: {
    name: 'Weco Trace II',
    urlName: 'WCONAT',
    baud: '19200',
    flow: '3',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: false,
    showVca: false,
  },
  weco2Hc: {
    name: 'Weco Trace II HC',
    urlName: 'WCOVCA',
    baud: '9600',
    flow: '3',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  weco3: {
    name: 'Weco Trace IIII',
    urlName: 'WCOVCA',
    baud: '19200',
    flow: '1',
    parity: 'N',
    data: '8',
    stop: '1',
    vca: true,
    showVca: false,
  },
  // VCRLAN: Weco T6 LAN
  wecot6Lan: {
    name: 'Weco T6 LAN',
    urlName: 'VCRLAN',
    baud: '0',
    flow: '1',
    parity: '0',
    data: '0',
    stop: '0',
    vca: true,
    showVca: true,
    lan: true,
    tcpPort: '50000',
  },

  custom: {
    name: i18n.t('Custom'),
    urlName: 'CUSTOM',
    baud: '',
    flow: '',
    parity: '',
    data: '',
    stop: '',
    vca: false,
    showVca: true,
  },
}


export const options = {
  flow: [
    {
      label: i18n.t('No flow control'),
      value: '0',
    },
    {
      label: 'RTS/CTS',
      value: '1',
    },
    {
      label: 'XON/XOFF',
      value: '2',
    },
    {
      label: 'DSR/DTR',
      value: '3',
    },
  ],
  baud: [
    {
      value: '',
    },
    {
      value: '9600',
    },
    {
      value: '19200',
    },
    {
      value: '38400',
    },
    {
      value: '57600',
    },
    {
      value: '115200',
    },
  ],
  parity: [
    {
      label: 'None',
      value: 'N',
    },
    {
      label: 'Even',
      value: 'E', // TODO: je to skutecne E??
    },
  ],
  data: [
    {
      value: '',
    },
    {
      value: '8',
    },
  ],
  stop: [
    {
      value: '',
    },
    {
      value: '1', // TODO:
    },
    {
      value: '2', // TODO:
    },
  ],
}
