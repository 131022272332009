import { bevelPositions, frameTypes } from '../config'

const colors = {
  violet: '#3d0e50',
  red: '#e71815',
  gray: '#e3e3e3',

  violet80: 'rgba(53,24,80,0.8)',
  violet20: 'rgba(53,24,80,0.2)',
  logoColor: '#410f61',
}

const REQUIRED = true
const OPTIONAL = false

export default {
  APP_TYPE: 'reize',
  isSummaryTooltipEnabled: true, // shows summary tooltip on hover over order in list
  isCanCreateOrdersForOthersEnabled: true,
  isOrderCancellationEnabled: true,
  orderCancellationTime: 1,
  isBifocalVisualisationEnabled: true,
  showShapeGeometryCenterAtStep2: false,
  showFooterImpressum: true,
  showFooterGdprDoc: true,
  showFooterLegalDoc: false,
  showOrdersTableIdColumn: true,
  hideOrdersSourceSwitch: false, // hide order source switch on HP
  useNativeCookieConsent: false,
  defaultCopyReferenceOnDuplication: true,
  safetyMarginStock: 2, // value added to radius
  safetyMarginManufacturer: 2, // value added to radius
  orderSanitizeStringsVariant: 'SOFTER', // FULL or SOFTER
  isMiLensEnabled: false,
  areFtpDeliveryNotesEnabled: false,
  // select base curve field after diameter selection with options from catalog
  isBaseCurveSelectEnabled: true,
  isLensBaseEnabled: false, // base curve field in refractions section
  hostConf: {
    isGoToWowEnabled: false,
    wowUrl: '',
    htmlTitle: 'MyReize',
    logo: {
      publicPath: '/public/images/logo_reize.png?v=3',
      // publicPath: '/public/images/logo-reize.png',
    },
    navbarLogoMaxHeight: 5,
    favicon: {
      publicPath: '/public/favicon-reize.ico?v=2',
    },
    areDeliveryNotesEnabled: false,
  },
  uniqueTranslationKeyPrefix: 'reize;',
  versionPrefix: 'r',
  // considerDesignShift: true, // in lens diameter calculation (getFinalRadiiByShapeAndLenses) - not used anymore
  isGoToWowEnabled: false,
  languages: ['en', 'fr', 'de', 'it'],
  isCustomDiameterEnabled: false,
  isEllipticalDiameterEnabled: false,
  isInternalNoteEnabled: true,
  isLensBaseEnabled: false, // base curve
  isSphAndCylInHundredthsEnabled: true,
  sphAndCylInHundredthsGroups: ['DPT100'],
  isWithShapeOnlyLensesEnabled: true,
  showCalcSurfacePoints: process.env.SERVER_ENV === 'dev' || process.env.SERVER_ENV === 'local',
  isExpressOrderEnabled: false,
  isCenterThicknessEnabled: false,
  step2DefaultBevelPositionForEdgingOrder: undefined,
  step2AutoPolish: false,
  isSpectaclesPolishEnabled: false,
  isPinBevelEnabled: true, // pin bevel = chamfer
  defaultDefaultChamferSettings: false, // default value for def chamfer in settings form
  areFramesEnabled: true,
  isCtoEnabled: true,
  ctoOptionsCodes: ['B8O', 'B8M', 'B88', 'B8'],
  isCtoCheckedByDefault: true,
  isUvSelectionEnabled: false,
  headerLogoPath: '/public/images/logo_reize.png?v=3',
  isLogoOnRight: false,
  hasLogoutInHeader: true,
  hasPriceList: true,
  isFrameCurveStep2Visible: false,
  isSortLensOptionsByNameEnabled: false,
  isMandatoryColorPreselectEnabled: false,

  isUsersPasswordSyncDisabled: true,

  // headerBackgroundColor: 'linear-gradient(to right, #351850, #ffffff 100%)',
  headerBackgroundColor: 'white',
  // headerFontColor: 'white',
  headerFontColor: '#2c0a3a',
  headerHoverFontColor: '#8b6e96',
  headerFontWeight: '400',
  headerActiveFontWeight: '500',
  // headerActiveFontColor: '#ffc107',
  headerActiveFontColor: '#3d0e50', // dark
  // headerActiveFontColor: '#8b6e96', // medium
  // headerActiveFontColor: '#b19fb9', // bright
  // headerActiveFontColor: colors.red, // bright

  headerBottomBorder: '2px solid #3d0e50',
  headerIconsColor: '#b19fb9',

  isDecentrationEnabled: false,
  isSoapEnabled: true,

  arePromotionsEnabled: true,

  soapWsdlDev: 'order-import-dev', // deprecated
  soapWsdl: 'order-import', // deprecated
  soapDevUrl: 'https://soap.reize.azure.virtii.com',
  // soapDevUrl: 'https://reizesoap.virtii.com',
  soapProdUrl: 'https://soap.myreize-ordering.ch',

  isPmsImportEnabled: true,
  isEyestationImportEnabled: true,

  areEmptySectionsInPrintHidden: true,



  catalogExtensions: {
    FRAME_ONLY_WITH_GROUP: {
      // if compatible coating exists, it must be selected
      // otherwise lens won't be compatible with frame brand
      isCompatibleOnlyWithCoatingIfAvailable: true,
    },
  },

  lensBrands: {
    REIZE: 'Reize',
    NIKON: 'Nikon',
    FREE_MOTION: 'FreeMotion',
  },

  // deprecated, used as fallback
  // set it in keystone frameBrand
  suppliersMap: {
    INV: 'INVU',
    REB: 'Reebok',
    NKA: 'Nika',
    FUR: 'iGard',
    NKO: 'Nikon',
  },

  colors: {
    primary: colors.violet,
    secondary: colors.red,
    red: colors.red,
    pageBackground: colors.gray,
    authBackground: '#f5f5f5',
  },

  lensFocusTypes: {
    'Single vision': {
      A: 'Single vision Premium',
      B: 'Single vision Quality',
      C: 'Single vision Standard',
    },
    Progressive: {
      D: 'Progressive Premium',
      E: 'Progressive Quality',
      F: 'Progressive Standard',
    },
    G: 'Indoor',
    H: 'Bifocal',
  },

  orderSchemaOverride: {
    pairKeys: {
      minEdgeThickness: {
        field: {
          min: 0.6,
          max: 9.9,
        },
      },
      prism1: {
        field: {
          minStep: 0.01,
        },
      },
      prism2: {
        field: {
          minStep: 0.01,
        },
      },
    },
    singleKeys: {
      frameBowAngle: {
        field: {
          min: 0,
          max: 25,
        },
      },
      panto: {
        field: {
          min: 0,
          max: 30,
        },
      },
      reference: {
        field: {
          maxLength: 30,
        },
      },
      bevelModifier: {
        field: {
          min: 0.8, // <-- this is changed
          max: 99,
        },
      },
    },
  },

  individualMenus: {
    // true = mandatory field
    // false = optional TODO - předělat na omeze a obecně to pořešit
    // order matters! Higher wins if two different lenses selected

    /* ALL FIELDS
      cvd,
      cvdApprox,
      readdist
      readdistApprox
      laterality
      headtilt --- not yet
      proglen
      frameBowAngle
      frameBowAngleApprox
      panto
      pantoApprox
    */
    BB2: {
      cvd: {
        isRequired: true,
        min: 5,
        max: 22,
      },
      cvdApprox: OPTIONAL,
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 25,
      },
      frameBowAngleApprox: OPTIONAL,
      panto: {
        isRequired: true,
        min: 0,
        max: 25,
      },
      pantoApprox: OPTIONAL,
      readdist: {
        isRequired: true,
        min: 20,
        max: 60,
      },
      readdistApprox: OPTIONAL,
    },

    BB3: {
      cvd: {
        isRequired: true,
        min: 5,
        max: 22,
      },
      cvdApprox: OPTIONAL,
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 25,
      },
      frameBowAngleApprox: OPTIONAL,
      panto: {
        isRequired: true,
        min: 0,
        max: 25,
      },
      pantoApprox: OPTIONAL,
      readdist: {
        isRequired: true,
        min: 20,
        max: 60,
      },
      readdistApprox: OPTIONAL,
      laterality: REQUIRED,
    },

    BB4: {
      laterality: REQUIRED,
    },

    NIK: {
      cvd: {
        isRequired: true,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 25,
      },
      panto: {
        isRequired: true,
        min: 0,
        max: 25,
      },
      readdist: {
        isRequired: true,
        min: 20,
        max: 60,
      },
    },

    RZV4: {
      cvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 20,
      },
      panto: {
        isRequired: false,
        min: 0,
        max: 30,
      },
      readdist: {
        isRequired: false,
        min: 20,
        max: 50,
      },
      proglen: {
        isRequired: false,
        options: ['', 14, 16, 18, 20],
      },
    },

    RZV10: {
      cvd: {
        isRequired: false,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 20,
      },
      panto: {
        isRequired: false,
        min: 0,
        max: 30,
      },
      readdist: {
        isRequired: false,
        min: 20,
        max: 50,
      },
      proglen: {
        isRequired: true,
        options: [14, 15, 16, 17, 18],
      },
    },

    RZV11: {
      cvd: {
        isRequired: false,
        min: 5,
        max: 22,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 25,
      },
      panto: {
        isRequired: false,
        min: -5,
        max: 25,
      },
    },

    RZV12: {
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 35,
      },
    },

    RZV13: {
      cvd: {
        isRequired: true,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 30,
      },
      panto: {
        isRequired: true,
        min: 0,
        max: 25,
      },
    },

    RZV14: {
      cvd: {
        isRequired: true,
        min: 5,
        max: 20,
      },
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 25,
      },
      panto: {
        isRequired: true,
        min: 0,
        max: 25,
      },
    },

    RZV16: {
      proglen: {
        isRequired: true,
        options: [12, 14, 17, 19],
      },
    },

    RZV17: {
      cvd: {
        isRequired: true,
        min: 5,
        max: 22,
      },
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 25,
      },
      panto: {
        isRequired: true,
        min: -5,
        max: 25,
      },
      readdist: {
        isRequired: true,
        min: 20,
        max: 60,
      },
      proglen: {
        isRequired: true,
        options: [12, 14, 17, 19],
      },
    },

    FFUNIK: {
      cvd: {
        isRequired: true,
        min: 5,
        max: 20,
      },
      panto: {
        isRequired: true,
        min: 0,
        max: 25,
      },
      frameBowAngle: {
        isRequired: true,
        min: 0,
        max: 25,
      },
      readdist: {
        isRequired: true,
        min: 20,
        max: 60,
      },
      // far vision sensitivity
      fvs: {
        isRequired: true,
        min: -5,
        max: 5,
      },
      // near vision sensitivity
      nvs: {
        isRequired: true,
        min: -5,
        max: 5,
      },
      designType: {
        isRequired: true,
        min: 10,
        max: 99,
      },
    },
  },
  getBevelPositions: () => [
    bevelPositions.AUTO,
    bevelPositions.MANUAL,
    bevelPositions.FRONT,
    bevelPositions.BACK,
    bevelPositions.RELATION30,
    bevelPositions.RELATION40,
    bevelPositions.HALF,
    bevelPositions.RELATION60,
    bevelPositions.RELATION70,
  ],
  // should be function returning array, otherwise default - all; check jzo for reference
  getFrameTypes: () => [
    frameTypes.METAL,
    frameTypes.PLASTIC,
    frameTypes.SPECIAL,
    frameTypes.NYLOR,
    frameTypes.DRILLED,
    frameTypes.GROOVEDDRILLED,
  ],
}
