import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import PopupPresentational from './PopupPresentational'
import { TextField, SelectField } from '../fields'
import { Row, Col, FieldsGroup, Button, Text } from '..'
// import Table from '../Table'
import Table from '../TableSimple'
import Visualiser from '../vca/Visualiser'
import { fetchFramesList } from '../../../common/catalog/actions'
import { fields } from '../../../common/lib/redux-fields'
import { getDisabledFrameBrands } from '../../../common/catalogExtensions'
import { PopTooltip, TinyPopTooltip } from '../Tooltip'
import { BrandIcon } from '../order/FrameBrandIcons'
import { getFrameBrandName } from '../../../common/catalog/utils'

const StyledVisualiser = styled(Visualiser)`
  /* display: inline-block; */
  border: none;
`
const FrameImage = styled.img`
  max-width: 100%;
  /* display: inline-block; */
  /* border: solid 1px #F2F2F2; */
  /* border: none; */
`
const FrameImageThumb = styled.img`
  /* display: inline-block; */
  border: solid 1px #f2f2f2;
  border-radius: 8px;
  /* border: none; */
`

const createSortProps = fields => item => ({
  sortable: true,
  sortHandler: item => {
    const sortBy = fields.sortBy.value
    const newValue = sortBy === item && fields.sortDirection.value === 1 ? -1 : 1
    fields.sortBy.onChange({ value: item })
    fields.sortDirection.onChange({ value: newValue })
  },
  sortState: fields.sortBy.value === item ? fields.sortDirection.value : 0,
})

const getCompareFunction = (item, direction) => {
  let result = () => 0
  switch (item) {
    case 'modelCode': {
      if (direction === 1) {
        result = (a, b) => a.modelCode > b.modelCode ? 1 : -1
      } else {
        result = (a, b) => a.modelCode > b.modelCode ? -1 : 1
      }
      break
    }
    case 'fsupplier': {
      if (direction === 1) {
        result = (a, b) => a.fsupplier > b.fsupplier ? 1 : -1
      } else {
        result = (a, b) => a.fsupplier > b.fsupplier ? -1 : 1
      }
      break
    }
    // no default
  }
  return result
}

class FrameCatalogPopup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      limit: 100,
      selectedFrame: null,
    }
  }

  componentDidMount() {
    const { fetchFramesList, framesList, frameListIsLoading } = this.props
    if (!frameListIsLoading) {
      fetchFramesList()
    }
  }

  lazyRenderOnScroll = e => {
    const element = e.nativeEvent.target
    const maxScroll = element.scrollHeight - element.offsetHeight
    const currentScroll = element.scrollTop
    if (maxScroll - currentScroll < 300) {
      requestAnimationFrame(() => {
        this.setState({ limit: this.state.limit + 100 })
      })
    }
  }

  filterFrameList = list => {
    const { fields } = this.props
    const { modelCode: modelSearch, supplier } = fields.$values()

    return list.filter(({ modelCode = '', fsupplier }) => {
      if (modelSearch && !modelCode.toLowerCase().includes(modelSearch.toLowerCase())) return false
      if (supplier && fsupplier !== supplier) return false
      return true
    })
  }

  getFiltersOptions = () => {
    const { framesList, frameBrands } = this.props

    const occurringSuppliers = new Set()
    framesList.forEach(frame => {
      occurringSuppliers.add(frame.fsupplier)
    })

    const supplierOptions = []
    occurringSuppliers.forEach(key => {
      supplierOptions.push({ label: getFrameBrandName(frameBrands, key), value: key })
    })
    return {
      supplierOptions,
    }
  }

  onRowClick = frame => {
    this.setState({ selectedFrame: frame })
  }

  disableIncompatibleFrames = frames => {
    const { catalogExtensions, currentLensR, currentLensL, args, t } = this.props

    const { orderFieldValues } = args

    // get array of disabled brands for current lens and options
    const disabledBrands = getDisabledFrameBrands({
      catalogExtensions,
      currentLensR,
      currentLensL,
      orderFieldValues,
    })

    if (!disabledBrands || disabledBrands.length === 0) return frames

    return frames.map(frame => {
      const isDisabled = disabledBrands.includes(frame.fsupplier)
      const extension = catalogExtensions.find(
        extension => extension.frameBrand === frame.fsupplier,
      )
      const incompatibleMessage =
        extension?.incompatibleMessage || 'This frame is only available for lenses marked with icon'
      const result = {
        ...frame,
        isDisabled,
      }
      if (isDisabled) {
        result.disabledReasonText = (
          <div>
            {t(incompatibleMessage)}
            <BrandIcon brand={frame.fsupplier} inline />
          </div>
        )
      }
      return result
    })
  }

  render() {
    const { t, close, fields, args, framesList, frameListIsLoading, frameBrands } = this.props
    const { selectedFrame } = this.state
    const { onFrameSelect } = args

    const sortProps = createSortProps(fields)
    let frames = framesList

    // const sortBy = fields.sortBy.value
    // if (sortBy) {
    //   const compareFunction = getCompareFunction(sortBy, fields.sortDirection.value)
    //   frames = frames.sort((a, b) => compareFunction(a, b))
    // }

    frames = this.filterFrameList(frames)

    frames = this.disableIncompatibleFrames(frames)

    const { supplierOptions } = this.getFiltersOptions()

    const isLoading = frameListIsLoading
    return (
      <PopupPresentational
        title={t('reize; searching in frames catalog')}
        close={close}
        type="big"
        showControlButtons={false}
        style={{ width: '90%', maxWidth: '130rem', minHeight: '75%' }}
        contentStyle={{ overflow: 'visible' }}
        displayChildrenInMessage={false}
      >
        <Row style={{ overflow: 'visible' }}>
          <Col grow={100} shrink={1}>
            <Row>
              <Col width="100%">
                <FieldsGroup title={t('filters')}>
                  <Row>
                    <Col width="10rem">
                      <TextField label={t('reize; Frame modelCode')} {...fields.modelCode} />
                    </Col>
                    <Col width="15rem">
                      <SelectField
                        label={t('reize; Frame supplier')}
                        options={supplierOptions}
                        isClearable
                        {...fields.supplier}
                      />
                    </Col>
                  </Row>
                </FieldsGroup>
              </Col>
            </Row>
            {selectedFrame && (
              <Row>
                <Col width="100%">
                  <FieldsGroup title={t('reize; Frame')}>
                    <Col alignItems="center">
                      <Row>
                        <Text style={{ fontSize: '1.6rem' }}>
                          <strong>{t('reize; Frame color')}: </strong> {selectedFrame.colorText}
                        </Text>
                      </Row>

                      <FrameImage src={selectedFrame.frameImagePath} alt="" />
                      <StyledVisualiser
                        {...selectedFrame}
                        width={400}
                        height={180}
                        hideToggleButton
                      />
                    </Col>
                  </FieldsGroup>
                </Col>
              </Row>
            )}
          </Col>
          <Col shrink={1} grow={0}>
            {frames.length !== 0 && (
              <Table
                // onScroll={this.lazyRenderOnScroll}
                onBodyScroll={this.lazyRenderOnScroll}
                name="catalog-table"
                data={frames}
                onRowClick={this.onRowClick}
                activeItemId={selectedFrame && selectedFrame._id}
                compact
                columns={[
                  {
                    header: t('reize; Frame supplier'),
                    item: 'fsupplier',
                    colProps: {
                      width: '6rem',
                      shrink: 0,
                      compact: true,
                      marginRight: '1rem',
                    },
                    renderCol: value => (
                      <Text style={{ whiteSpace: 'nowrap' }}>
                        {getFrameBrandName(frameBrands, value)}
                      </Text>
                    ),
                    // ...sortProps('fsupplier'),
                  },
                  {
                    header: t('reize; Frame modelCode'),
                    item: 'modelCode',
                    colProps: {
                      width: '6rem',
                      compact: true,
                      marginRight: '1rem',
                    },
                    // ...sortProps('modelCode'),
                  },
                  {
                    header: t('reize; Eyesize'),
                    item: 'formCode',
                    colProps: {
                      width: '6rem',
                    },
                    // ...sortProps('modelCode'),
                  },
                  {
                    header: t('DBL'),
                    item: 'nomDBL',
                    colProps: {
                      width: '4rem',
                      compact: true,
                      marginRight: '1rem',
                    },
                    // ...sortProps('modelCode'),
                  },
                  {
                    header: t('reize; Frame color'),
                    item: 'colorText',
                    colProps: {
                      width: '14rem',
                    },
                  },
                  {
                    header: '',
                    item: 'frameImagePath',
                    colProps: {
                      width: '12rem',
                    },
                    renderCol: value => (
                      <div>
                        <FrameImageThumb src={value} alt="" style={{ height: '5rem' }} />
                      </div>
                    ),
                  },
                  {
                    header: '',
                    headerColProps: {
                      alignItems: 'center',
                    },
                    colProps: {
                      width: '8rem',
                      shrink: 0,
                    },
                    renderCol: (value, row) => {
                      const Wrap = row.isDisabled ? TinyPopTooltip : Fragment
                      return (
                        <Wrap text={row.disabledReasonText}>
                          <Button
                            disabled={row.isDisabled}
                            onClick={() => {
                              if (row.isDisabled) return
                              onFrameSelect(row._id, row)
                              close()
                            }}
                          >
                            {t('choose')}
                          </Button>
                        </Wrap>
                      )
                    },
                  },
                ]}
              />
            )}

            {isLoading && framesList.length === 0 && (
              <Text big center style={{ marginTop: '2rem' }}>
                {t('Loading')}
              </Text>
            )}

            {!isLoading && frames.length === 0 && (
              <>
                <Text big center style={{ marginTop: '3rem' }}>
                  🕸 {t('Nothing found')}
                </Text>
                <Text center style={{ marginTop: '0.8rem' }}>
                  {t('Try to change filters...')}
                </Text>
              </>
            )}
          </Col>
        </Row>
      </PopupPresentational>
    )
  }
}

FrameCatalogPopup.defaultProps = {
  args: {},
  framesList: [],
}

FrameCatalogPopup.propTypes = {
  close: PropTypes.func.isRequired, // is injected by PopupsGate
  args: PropTypes.object,
  fields: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  fetchFramesList: PropTypes.func.isRequired,
  frameListIsLoading: PropTypes.bool.isRequired,
  framesList: PropTypes.array,
}

const enhance = compose(
  connect(
    ({ catalog, fields }) => ({
      frameListIsLoading: catalog.frameListIsLoading,
      framesList: catalog.framesList,

      catalogExtensions: catalog.catalogExtensions,
      currentLensR: catalog.currentLensR,
      currentLensL: catalog.currentLensL,

      frameBrands: catalog.frameBrands,
    }),
    {
      fetchFramesList,
    },
  ),
  fields({
    path: 'FrameCatalogForm',
    fields: ['modelCode', 'supplier', 'sortBy', 'sortDirection'],
    getInitialState: () => ({
      sortBy: 'modelCode',
      sortDirection: 1,
    }),
  }),
  withTranslation(),
)

export default enhance(FrameCatalogPopup)
