/**
 * fields definitions
 * vca:
 *  - vcaKey: 'KEY='
 *  - format: number of decimal places or 'string'
 *  - fakePair: true - used in single values when outputting vca as pair values
 *  - transformation: fn, used when outputting (do not forget to check if value is empty)
 *  - afterParseTransformation: async fn, used when parsing and we have basic parse done. For singleKey return value directly, for pairKeys return Object {r: 'some value', l: '...' } Example pair usage: height.
 *  - afterParseTransformationImportant: if true, fn above is run BEFORE other functions (so other fns get results from the priority ones)
 *  - alternative: ['READ_DIST='] array of keys, when outputting
 *  - alternativeForParse: ['READ_DIST='], array of keys, used when parsing. Can be two types: just key or whole config object
 *
 * db:
 *  - if Model name is false or undefined --> wont save in DB
 *  - if Model name is different than boolean - it's db schema
 *  - if Model name is true - default config will be used
 *  defaultVcaDbFormat = { type: Number, hidden: true }
 *  defaultOrderDbFormat = { type: Number }
 */
import { appTypeConfig, frameTypes, bevelPositions } from './config'
// import { getCombinedPrism } from '../common/orders/utils'

let Types = {
  Relationship: {},
}

console.log('process.env.IS_BROWSER', process.env.IS_BROWSER)
if (!process.env.IS_BROWSER) {
  const keystone = require('keystone')
  Types = keystone.Field.Types
}

export const getFieldConfig = (name, isNameWithSide = true) => {
  const nameWithoutSide = isNameWithSide ? name.slice(0, name.length - 1) : name
  const config = fieldsConfig[nameWithoutSide] || {}
  return config
}

// fields definition for DB Model + form steps, cuz we hate writin'

// fields for redux-fields form
export const getFormKeys = () => {
  const fields = []
  Object.keys(pairKeys).forEach(k => {
    fields.push(`${k}R`)
    fields.push(`${k}L`)
  })
  Object.keys(singleKeys).forEach(k => {
    fields.push(k)
  })
  Object.keys(specialKeys).forEach(k => {
    fields.push(k)
  })
  return fields
}

// get all keys as constants (without R \ L)
export const getConstants = () =>
  [...Object.keys(pairKeys), ...Object.keys(singleKeys), ...Object.keys(specialKeys)].reduce(
    (acc, k) => ({ ...acc, [k]: k }),
    {},
  )

export const getVcaPairKeys = () => {
  const result = {}
  Object.keys(pairKeys)
    .filter(k => pairKeys[k].vca)
    .forEach(k => {
      result[k] = pairKeys[k].vca
    })
  return result
}
export const getVcaSingleKeys = () => {
  const result = {}
  Object.keys(singleKeys)
    .filter(k => singleKeys[k].vca)
    .forEach(k => {
      result[k] = singleKeys[k].vca
    })
  return result
}
export const getVcaSpecialKeys = () => {
  const result = {}
  Object.keys(specialKeys)
    .filter(k => specialKeys[k].vca)
    .forEach(k => {
      result[k] = specialKeys[k].vca
    })
  return result
}

export const getFieldsConfig = () =>
  Object.keys(pairKeys)
    .filter(key => pairKeys[key].field)
    .reduce(
      (result, key) => {
        result[key] = { ...pairKeys[key].field, isPair: true }
        return result
      },
      Object.keys(singleKeys)
        .filter(key => singleKeys[key].field)
        .reduce((result, key) => {
          result[key] = { ...singleKeys[key].field, isPair: false }
          return result
        }, {}),
    )

const defaultVcaDbFormat = { type: Number, hidden: true }
const defaultOrderDbFormat = { type: Number }

const fillDbFormat = (field, modelName, defaultFormat) => {
  if (!field.db || field.db[modelName] === true || typeof field.db[modelName] === 'undefined') {
    return defaultFormat
  }
  return field.db[modelName]
}

export const getVcaSchema = () => {
  const schema = {
    ...getShapeDataSchema(),
  }
  Object.keys(pairKeys).forEach(k => {
    const field = pairKeys[k]
    if (!field.db || !field.db.Vca) {
      return
    }
    schema[`${k}R`] = fillDbFormat(field, 'Vca', defaultVcaDbFormat)
    schema[`${k}L`] = fillDbFormat(field, 'Vca', defaultVcaDbFormat)
  })

  Object.keys(singleKeys).forEach(k => {
    const field = singleKeys[k]
    if (!field.db || !field.db.Vca) {
      return
    }
    schema[k] = fillDbFormat(field, 'Vca', defaultVcaDbFormat)
  })
  return schema
}

export const getFrameSchema = () => {
  const schema = {
    ...getShapeDataSchema(),
  }
  Object.keys(pairKeys).forEach(k => {
    const field = pairKeys[k]
    if (!field.db || (!field.db.Vca && !field.db.Frame)) {
      return
    }
    // if there is definition for Frame, use it
    if (field.db.Frame) {
      schema[`${k}R`] = fillDbFormat(field, 'Frame', defaultVcaDbFormat)
      schema[`${k}L`] = fillDbFormat(field, 'Frame', defaultVcaDbFormat)
    } else {
      // otherwise use VCA definition
      schema[`${k}R`] = fillDbFormat(field, 'Vca', defaultVcaDbFormat)
      schema[`${k}L`] = fillDbFormat(field, 'Vca', defaultVcaDbFormat)
    }
  })

  Object.keys(singleKeys).forEach(k => {
    const field = singleKeys[k]
    if (!field.db || (!field.db.Vca && !field.db.Frame)) {
      return
    }
    // if there is definition for Frame, use it
    if (field.db.Frame) {
      schema[k] = fillDbFormat(field, 'Frame', defaultVcaDbFormat)
    } else {
      // otherwise use VCA definition
      schema[k] = fillDbFormat(field, 'Vca', defaultVcaDbFormat)
    }
  })
  return schema
}

// todo - musí toto být funkce? Nemůže se prostě vygenerovat objekt na začátku?
export const getOrderSchema = () => {
  const schema = {
    ...getShapeDataSchema(),
  }
  Object.keys(pairKeys).forEach(k => {
    const field = pairKeys[k]
    if (field.db && field.db.Order === false) {
      return
    }
    schema[`${k}R`] = fillDbFormat(field, 'Order', defaultOrderDbFormat)
    schema[`${k}L`] = fillDbFormat(field, 'Order', defaultOrderDbFormat)
  })

  Object.keys(singleKeys).forEach(k => {
    const field = singleKeys[k]
    if (field.db && field.db.Order === false) {
      return
    }
    schema[k] = fillDbFormat(field, 'Order', defaultOrderDbFormat)
  })

  return schema
}

export const getShapeDataSchema = () => ({
  shapeData: {
    initialLineR: { type: String, hidden: true },
    initialLineL: { type: String, hidden: true },
    r: { type: Types.NumberArray, hidden: true },
    l: { type: Types.NumberArray, hidden: true },
  },
})

const applySchemaOverride = (type, defaultConfig) => {
  const { orderSchemaOverride = {} } = appTypeConfig
  const overrideData = orderSchemaOverride[type]
  if (!overrideData) return defaultConfig

  const result = defaultConfig
  Object.keys(overrideData).forEach(fieldKey => {
    const fieldConfig = overrideData[fieldKey]
    Object.keys(fieldConfig).forEach(fieldConfigPropKey => {
      const defaultValues = result[fieldKey][fieldConfigPropKey]
      const overrideValues = overrideData[fieldKey][fieldConfigPropKey]

      result[fieldKey][fieldConfigPropKey] = {
        ...defaultValues,
        ...overrideValues,
      }
    })
  })

  return result
}

export const getPairKeys = () => pairKeys

export const pairKeys = applySchemaOverride('pairKeys', {
  // starts with VCA keys
  sphere: {
    vca: {
      vcaKey: 'SPH=',
      format: 2,
      calcRequired: true,
    },
    field: {
      step: 0.25,
      decimals: 2,
    },
  },
  cylinder: {
    vca: {
      vcaKey: 'CYL=',
      format: 2,
      calcRequired: true,
    },
    field: {
      step: 0.25,
      decimals: 2,
    },
  },
  axis: {
    vca: {
      vcaKey: 'AX=',
      format: 0,
      calcRequired: true,
    },
    field: {
      min: 0,
      max: 180,
      decimals: 0,
    },
  },
  addition: {
    vca: {
      vcaKey: 'ADD=',
      format: 2,
      calcRequired: true,
    },
    field: {
      step: 0.25,
      decimals: 2,
    },
  },
  prism1: {
    vca: {
      vcaKey: 'PRVM=',
      format: 2,
      calcRequired: true,
      transformation: (value, allValues, side) => {
        const prism1 = allValues[`prism1${side}`]
        const prism2 = allValues[`prism2${side}`]
        return getCombinedPrism(prism1, prism2)
      },
    },
    field: {
      step: 0.25,
      decimals: 2,
    },
  },
  base1: {
    vca: {
      vcaKey: 'PRVA=',
      format: 0,
      calcRequired: true,
      transformation: (value, allValues, side) => {
        const prism1 = allValues[`prism1${side}`] // horizontal
        const base1 = allValues[`base1${side}`]
        const prism2 = allValues[`prism2${side}`] // vertical
        const base2 = allValues[`base2${side}`]

        return getCombinedBase({ prism1, prism2, base1, base2 })
      },
    },
    field: {
      min: 0,
      max: 360,
    },
  },
  prism2: {
    field: {
      step: 0.25,
      decimals: 2,
    },
  },
  base2: {
    field: {
      min: 0,
      max: 360,
    },
  },
  _prism1: {
    vca: {
      vcaKey: '_PRVM1=',
      format: 2,
      transformation: (value, allValues, side) => allValues[`prism1${side}`],
    },
  },
  _prism2: {
    vca: {
      vcaKey: '_PRVM2=',
      format: 2,
      transformation: (value, allValues, side) => allValues[`prism2${side}`],
    },
  },
  _base1: {
    vca: {
      vcaKey: '_PRVA1=',
      transformation: (value, allValues, side) => allValues[`base1${side}`],
    },
  },
  _base2: {
    vca: {
      vcaKey: '_PRVA2=',
      transformation: (value, allValues, side) => allValues[`base2${side}`],
    },
  },
  pd: {
    vca: {
      vcaKey: 'IPD=',
      format: 2,
      alternative: ['NPD='],
    },
    field: {
      type: 'float',
      min: 20,
      max: 80,
      step: 0.1,
      decimals: 1,
    },
  },
  height: {
    vca: {
      vcaKey: 'OCHT=',
      format: 2,
      alternative: ['SEGHT='],
      afterParseTransformation: async ({ fieldValueR, fieldValueL, eyestation }) => {
        const res = {
          r: fieldValueR,
          l: fieldValueL,
        }
        if (eyestation) {
          // https://trello.com/c/q27QVhfU/7-eyestation-oma-file-import
          res.r = Math.round((fieldValueR - 2) * 10) / 10 // because of JS float random 00000000003 feature <3
          res.l = Math.round((fieldValueL - 2) * 10) / 10
        }
        return res
      },
    },
    field: {
      step: 0.1,
      decimals: 1,
      min: 10,
      max: 60,
    },
  },
  base: {},
  bvd: {
    vca: {
      vcaKey: 'BVD=',
      format: 2,
    },
    field: {
      min: 5,
      max: 20,
    },
    // vrcholova vzdalenost obruby (indiv)
  },
  lensCode: {
    vca: {
      vcaKey: 'LNAM=',
      format: 'string',
    },
  },
  // VBOX + HBOX - we used FE calculated values, but order needs this for XML
  vbox: {
    vca: {
      vcaKey: 'VBOX=',
      format: 2,
    },
    field: {
      min: 10,
      max: 80,
    },
  },
  hbox: {
    vca: {
      vcaKey: 'HBOX=',
      format: 2,
    },
    field: {
      min: 10,
      max: 80,
    },
  },
  frameCurve: {
    // je to parove ve vca, ale ve skutecnosti se pouziva pouze R (pro calc a b2b)
    // pro krok 2, jen tracer, jen informacne - neni pravda, posilame do calculace a objednavky
    vca: {
      vcaKey: 'FCRV=',
      format: 2,
    },
    db: {
      Vca: true,
    },
    field: {
      min: 0.5,
      max: 12,
    },
  },
  totalInset: {
    // dulezite pro kalkulaci
    vca: {
      vcaKey: 'FCSGIN=',
      format: 2,
    },
    db: {
      Vca: false,
      Order: false,
    },
  },
  segmentDrop: {
    // dulezite pro kalkulaci
    vca: {
      vcaKey: 'FCSGUP=',
      format: 2,
    },
    db: {
      Vca: false,
      Order: false,
    },
  },
  // --------------------
  // VCA calculation result variables bellow
  weight: {
    vca: {
      vcaKey: 'WEIGHT=',
      format: 1,
    },
    db: { Order: false },
  },
  centerThickness: {
    vca: {
      vcaKey: 'INSCTHK=',
      format: 2,
      alternativeForParse: ['CTHICK='],
    },
    db: { Order: false },
  },
  baseCurve: {
    vca: {
      vcaKey: 'MBASE=',
      format: 2,
    },
    field: {
      step: 0.01,
      decimals: 2,
    },
  },
  calculatedBaseCurve: {
    // vypocitana baze cocky
    vca: {
      vcaKey: 'MBASE=',
      format: 2,
    },
  },
  thinnestPointShape: {
    vca: {
      vcaKey: '_THNP=',
      format: 2,
    },
    db: { Order: false },
  },
  thickestPointShape: {
    vca: {
      vcaKey: '_THKP=',
      format: 2,
    },
    db: { Order: false },
  },
  thinnestPointLense: {
    vca: {
      vcaKey: 'THNP=',
      format: 2,
    },
    db: { Order: false },
  },
  thickestPointLense: {
    vca: {
      vcaKey: 'THKP=',
      format: 2,
    },
    db: { Order: false },
  },
  calculatedDiameter: {
    vca: {
      vcaKey: 'CRIB=',
      format: 2,
    },
    db: { Order: false },
  },
  calculatedDiameterE: {
    vca: {
      vcaKey: 'ELLH=',
      format: 2,
    },
    db: { Order: false },
  },
  // in jzo, when this is returned from calc for stock lenses, we need to move
  // calced shape thickness by this offset. only shape, only stock, everything else is ok.
  lenseOffsetX: {
    vca: {
      vcaKey: 'FCOCIN=',
      format: 2,
    },
  },
  lenseOffsetY: {
    vca: {
      vcaKey: 'FCOCUP=',
      format: 2,
    },
  },

  // order props only (form)
  decX: {
    vca: {
      vcaKey: 'SBOCIN=',
      format: 2,
    },
    field: {
      min: -20,
      max: 20,
      step: 0.1,
      decimals: 1,
    },
  },
  decY: {
    vca: {
      vcaKey: 'SBOCUP=',
      format: 2,
    },
    field: {
      min: -20,
      max: 20,
      step: 0.1,
      decimals: 1,
    },
  },
  minEdgeThickness: {
    field: {
      min: 0.7,
      max: 19.9,
      step: 0.1,
      decimals: 1,
    },
    vca: {
      vcaKey: 'MINEDG=',
      format: 2,
    },
  },
  minCenterThickness: {
    field: {
      min: 1.5,
      max: 19.9,
      step: 0.1,
      decimals: 1,
    },
    vca: {
      vcaKey: 'MINCTR=',
      format: 2,
    },
  },
  lens: {
    // TODO: db --- id??? MICHAL: to je vybraný typ čočky uživatelem ze selectu. Vloží se tam idčko čočky v naší databázi.
    // Vycházel jsem z názvu pole, což je typ čočky. Ale asi to může být prostě lens, ať to odpovídá modelu db.
    db: {
      Order: { type: Types.Relationship, ref: 'Lens', hidden: true, mongoose: true },
    },
  },
  diameterPhysical: {
    vca: {
      vcaKey: 'CRIB=',
      format: 0,
      alternative: ['FED='],
    },
  },
  diameterOptical: {},
  diameterElliptical: {
    vca: {
      vcaKey: 'ELLH=',
      format: 0,
    },
    db: { Order: false, Vca: false },
  },
  isElliptical: {
    db: {
      Order: { type: Boolean },
    },
  },
  coating: {
    db: {
      Order: { type: Types.Relationship, ref: 'Option', hidden: true, mongoose: true },
    },
  },
  color: {
    db: {
      Order: { type: Types.Relationship, ref: 'Option', hidden: true, mongoose: true },
    },
  },
  uv: {
    db: {
      Order: { type: Types.Relationship, ref: 'Option', hidden: true, mongoose: true },
    },
  },
  cto: {
    db: {
      Order: { type: String },
    },
  },
  isCtoCapable: {
    db: {
      Order: { type: Boolean },
    },
  },
  // special option code for reize when shp or cyl is in 1/100
  // but only if this option is available in option list
  ecs: {
    db: {
      Order: { type: String },
    },
  },
  inset: {
    field: {
      type: 'float',
      step: 0.1,
      decimals: 1,
    },
    vca: {
      vcaKey: 'ERNRIN=',
      format: 2,
    },
  },
})

// single values
export const singleKeys = applySchemaOverride('singleKeys', {
  internalId: {
    // unique value to prevent duplicit send
    // it does not have unique index, because we would have to generate index for all old orders
    // we check it manualy before saving the order
    db: {
      Order: { type: String, select: false }, // do not return field to browser
    },
  },
  tempEyestationShape: {
    db: {
      Order: false,
      Vca: false,
    },
    vca: {
      vcaKey: '_DIGIT360=',
      format: 'string',
      alternativeForParse: ['_DIGIT='],
    },
  },
  frameImagePath: {
    db: {
      Order: { type: String },
    },
  },
  worker: {
    db: {
      Order: { type: Types.Relationship, ref: 'Worker' },
    },
    vca: {
      vcaKey: '_SALENAME=',
      format: 'string',
      // only used for eyestation import!
      afterParseTransformation: async ({ fieldValue, req, keystone }) => {
        if (process.env.IS_BROWSER || !req || !req.user || !keystone) {
          return null
        }
        try {
          const Worker = keystone.list('Worker').model
          const worker = await Worker.findOne({
            user: req.user,
            isArchived: false,
            name: fieldValue,
          })

          if (!worker) {
            return null
          }

          return worker
        } catch (error) {
          return null
        }
      },
    },
  },
  firstPair: {
    db: {
      Order: { type: Types.Relationship, ref: 'Order' },
    },
  },
  frame: {
    db: {
      Order: { type: Types.Relationship, ref: 'Frame' },
    },
  },
  isSecondPairCapable: {
    db: {
      Order: { type: Boolean },
    },
  },
  minHoleThickness: {
    db: {
      Vca: true,
    },
    vca: {
      vcaKey: '_DRHTHK=',
      format: 2,
    },
    field: {
      // min: 1.5,
      // max: 19.9,
      // step: 0.1,
      // decimals: 1,
    },
  },
  panto: {
    vca: {
      vcaKey: 'PANTO=',
      format: 2,
      fakePair: true,
    },
    field: {
      min: -5,
      max: 20,
    },
  },
  frameBowAngle: {
    // face form angle == uhel zakriveni obruby
    vca: {
      vcaKey: 'ZTILT=', // frameBowAngle (indiv. parameter)
      format: 2,
      fakePair: true,
    },
    field: {
      min: 0,
      max: 35,
    },
  },
  dbl: {
    db: {
      Vca: true,
    },
    vca: {
      vcaKey: 'DBL=',
      format: 2,
    },
    field: {
      min: 1,
      max: 50,
    },
  },
  importedDbl: {
    db: {
      Vca: false,
    },
  },
  importedHBox: {
    db: {
      Vca: false,
    },
  },
  importedVBox: {
    db: {
      Vca: false,
    },
  },
  bevelPosition: {
    db: {
      Order: { type: String },
      // one of AUTO (AUTO), FOLLOW_FRONT (FRONT), FOLLOW_BACK (BACK), HALF (RELATION), PERCENT (RELATION)
    },
    vca: {
      vcaKey: 'BEVP=',
      format: 0,
      fakePair: true,
      transformation: value => {
        // console.log('value BEVPPP', value)
        // check vca doc, page 91
        switch (value) {
          case bevelPositions.AUTO:
            return 7
          case bevelPositions.MANUAL:
            return 0
          case bevelPositions.FRONT:
            return 1
          case bevelPositions.BACK:
            return 5
          case bevelPositions.HALF:
            return 4
          case bevelPositions.PERCENT:
          case bevelPositions.RELATION30:
          case bevelPositions.RELATION40:
          case bevelPositions.RELATION50:
          case bevelPositions.RELATION60:
          case bevelPositions.RELATION70:
            return 2
          case bevelPositions.SMART:
          case bevelPositions.FRAMECURVE:
            return 3
          default:
            return undefined
        }
      },
    },
  },
  // this is overridden in reize app type config! update config there as well
  bevelModifier: {
    field: {
      min: 1,
      max: 99,
    },
    db: {
      Vca: true,
    },
    vca: {
      vcaKey: 'BEVM=',
      format: 2,
    },
  },
  frameType: {
    db: {
      Order: { type: String },
      Vca: { type: String },
      // one of METAL, CELL, TITAN, RIMLESS_GROOVED, RIMLESS_DRILLED, RIMLESS_GROOVED_DRILLED
    },
    vca: {
      vcaKey: 'FTYP=',
      format: 0,
      alternativeForParse: [{ vcaKey: '_FRAMETYPE=', format: 'string' }],
      afterParseTransformationImportant: true,
      afterParseTransformation: ({ allValues: { frameType, eType } }) => {
        if (typeof frameType === 'string') {
          frameType = frameType.toUpperCase()
        }
        if (Object.values(frameTypes).includes(frameType)) {
          // if already changed, or exact value like from eyestation sometimes
          return frameType
        }
        if (frameType === 'RIMLESS') {
          return frameTypes.DRILLED
        }

        // TODO - toto je duplicitní v server/lib/vca
        switch (frameType) {
          case 2:
            return frameTypes.METAL
          case 1:
            return frameTypes.PLASTIC
          case 5:
            return frameTypes.SPECIAL
          case 4:
            return frameTypes.OPTYL
          case 3:
            switch (eType) {
              case 3:
                return frameTypes.NYLOR
              case 2:
                return frameTypes.DRILLED
              case 33:
                return frameTypes.GROOVEDDRILLED
              // no default
            }
          // no default
        }
        return null
      },
    },
  },
  eType: {
    vca: {
      vcaKey: 'ETYP=',
      format: 0,
    },
  },
  polish: {
    vca: {
      vcaKey: 'POLISH=',
      afterParseTransformation: ({ fieldValue, allValues: { frameType } }) => {
        if (fieldValue === "0" || fieldValue === 0) return false
        if (fieldValue) return true

        if ([frameTypes.DRILLED, frameTypes.GROOVEDDRILLED, frameTypes.NYLOR].includes(frameType)) {
          return true
        }
        return false
      },
    },
    db: {
      Order: { type: Boolean, default: false },
      Vca: { type: Boolean, default: false },
      Frame: { type: Boolean, default: false },
    },
  },
  pinBevel: {
    vca: {
      vcaKey: 'PINB=',
      format: 2,
    },
    db: {
      Order: { type: Boolean, default: false },
    },
  },

  // edgingType: {
  //   db: {
  //     Order: { type: Boolean, default: false },
  //   },
  // },
  grooveDepth: {
    field: {
      min: 0.4,
      max: 0.8,
    },
  },
  grooveWidth: {
    field: {
      min: 0.6,
      max: 1.2,
    },
  },
  reference: {
    db: {
      Order: { type: String },
    },
    field: {
      maxLength: 10,
    },
  },
  miLensId: {
    db: {
      Order: { type: String },
    },
  },
  clientName: {
    db: {
      Order: { type: String },
    },
    field: {
      maxLength: 16,
    },
    vca: {
      vcaKey: 'CLIENT=',
      format: 'string',
      afterParseTransformation: async ({ fieldValue, allValues: { tempClientFirstName } }) => {
        let result = ''
        if (tempClientFirstName) {
          result = tempClientFirstName

          if (fieldValue) {
            result += ' '
          }
        }
        if (fieldValue) {
          result += fieldValue
        }
        return result
      },
    },
  },
  // only used for eyestation concatation name
  tempClientFirstName: {
    db: {
      Order: false,
      Vca: false,
    },
    vca: {
      vcaKey: '_FNCLIENT=',
      format: 'string',
    },
  },
  internalNote: {
    db: {
      Order: { type: String },
    },
  },
  note: {
    db: {
      Order: { type: String },
    },
    field: {
      maxLength: 50,
    },
  },
  isExpress: {
    db: {
      Order: { type: Boolean },
    },
  },
  // we can have EXP24 and EXP48
  // EXP48 was added later. Empty value means EXP24, for old orders with isExpress: true
  expressCode: {
    db: {
      Order: { type: String },
    },
  },
  orderType: {
    db: {
      Order: { type: String }, // TODO: string? Michal: Asi string s tím, že tady bude enum možných hodnot pro validaci
    },
  },
  calculationError: {
    vca: {
      vcaKey: 'XSTATUS=',
    },
  },
  rightLensEnabled: {
    db: {
      Order: { type: Boolean },
    },
  },
  leftLensEnabled: {
    db: {
      Order: { type: Boolean },
    },
  },

  // reize only
  cvd: {
    vca: {
      vcaKey: 'BVD=', // is custom filtered and transformed to pair BVD
      format: 2,
      fakePair: true,
    },
    field: {
      min: 5,
      max: 20,
    },
  },
  readdist: {
    field: {
      min: 20,
      max: 50,
    },
    vca: {
      vcaKey: 'NWD=',
      format: 4,
      fakePair: true,
      transformation: value => value ? value / 100 : value,
      alternative: ['READ_DIST='],
      alternativeForParse: ['_READ_DIST='],
    },
  },
  laterality: {
    db: {
      Order: { type: String },
    },
    field: {}, // right;left
  },
  headtilt: {
    field: {
      min: -5,
      max: 90,
    },
  },
  proglen: {
    // ;14;15;16;17;18 first is empty and displayed as auto
    field: {},
    vca: {
      vcaKey: 'CORRLEN=',
      format: 0,
      fakePair: true,
    },
  },
  // FACEFORMANGLE_APPROX=2;11 ("Normal";"Curved")
  frameBowAngleApprox: {
    db: {
      Order: { type: String },
    },
    field: {},
  },
  bvdApprox: {
    db: {
      Order: { type: String },
    },
    field: {},
  },
  // CVD_APPROX=9;12;15 ("Short";"Normal";"Long")
  cvdApprox: {
    db: {
      Order: { type: String },
    },
    field: {},
  },
  // PANTOSCOPIC_APPROX=8;13 ("Normal";"Strong")
  pantoApprox: {
    db: {
      Order: { type: String },
    },
    field: {},
  },
  // READDIST_APPROX=38;41;44("< 160";"160 - 180";"> 180")
  readdistApprox: {
    db: {
      Order: { type: String },
    },
    field: {},
  },

  // far vision sensitivity
  fvs: {
    field: {
      min: -5,
      max: 5,
    },
  },
  // near vision sensitivity
  nvs: {
    field: {
      min: -5,
      max: 5,
    },
  },
  designType: {
    field: {
      min: 5,
      max: 90,
    },
  },
  shapeSizeAdjustment: {
    field: {
      min: -1,
      max: 1,
      step: 0.01,
      decimals: 2,
      showSign: true,
    },
  },

  // / TEST CALC

  // uni: {
  //   vca: {
  //     vcaKey: 'UNI=',
  //   },
  // },
  // acoat: {
  //   vca: {
  //     vcaKey: 'ACOAT=',
  //   },
  // },
  // client: {
  //   vca: {
  //     vcaKey: 'CLIENT=',
  //   },
  // },
  // edi: {
  //   vca: {
  //     vcaKey: '_EDI=',
  //   },
  // },
  // recType: {
  //   vca: {
  //     vcaKey: '_RECTYPE=',
  //   },
  // },
  // ordType: {
  //   vca: {
  //     vcaKey: '_ORDTYPE=',
  //   },
  // },
  // sport: {
  //   vca: {
  //     vcaKey: '_SPORT=',
  //   },
  // },
  // userr: {
  //   vca: {
  //     vcaKey: '_USER=',
  //   },
  // },
  // job: {
  //   vca: {
  //     vcaKey: 'JOB=',
  //   },
  // },
  // job2: {
  //   vca: {
  //     vcaKey: '_JOBX=',
  //   },
  // },
  // rxnm: {
  //   vca: {
  //     vcaKey: 'RXNM=',
  //   },
  // },
  // eid: {
  //   vca: {
  //     vcaKey: '_EID=',
  //   },
  // },
  // account: {
  //   vca: {
  //     vcaKey: 'ACCN=',
  //   },
  // },
})

export const specialKeys = applySchemaOverride('specialKeys', {
  shapeData: {
    vca: {
      initialVcaKey: 'TRCFMT=',
    },
  },
  d3: {
    vca: {
      initialVcaKey: '_C3DFMT=',
    },
  },
  thickness: {
    vca: {
      initialVcaKey: 'STHKFMT=',
    },
  },
  holes: {
    vca: {
      vcaKey: 'DRILLE=',
    },
    db: {
      // cuz keystone does not know object array -- create it directly in models
      Vca: false,
      Order: false,
    },
  },
})

export const fieldsConfig = getFieldsConfig()
export const constants = getConstants()
export const noStockParams = (() => {
  const {
    prism1,
    base1,
    prism2,
    base2,
    decX,
    decY,
    minEdgeThickness,
    minCenterThickness,
    baseCurve,
  } = getConstants()
  return [prism1, base1, prism2, base2, decX, decY, minEdgeThickness, minCenterThickness, baseCurve]
})()

export const getHolesSchema = () => {
  const attributes = {
    // holes: { type: Types.NumberArray }, // just dummy for pre save hook
  }
  // 8 for each lens is maximum
  for (let i = 0; i < 16; i++) {
    attributes[`hole${i}`] = {
      side: { type: String },
      startX: { type: Number },
      startY: { type: Number },
      endX: { type: Number },
      endY: { type: Number },
      depth: { type: Number },
      diameter: { type: Number },
    }
  }
  return attributes
}

// mutates original object
export const transformHoles = (obj, holes) => {
  if (!holes) {
    return obj
  }
  for (let i = 0; i < 16; i++) {
    obj[`hole${i}`] = null
  }
  holes.forEach((h, i) => {
    obj[`hole${i}`] = { ...h }
    // console.log('h', h)
  })

  return obj
}

// mutates original object
export const transformHolesToJSON = obj => {
  const holes = []
  for (let i = 0; i < 16; i++) {
    const h = obj[`hole${i}`]
    if (h) {
      holes.push(h)
    }
  }
  obj.holes = holes
  return obj
}

const roundPrism = prism => Math.round(prism * 100) / 100
const isValueFilled = value => value || value === 0
const calcHypotenuse = (a, b) => Math.sqrt(a * a + b * b)

export function getCombinedPrism(prism1, prism2) {
  let resultedPrism
  if (isValueFilled(prism1) && isValueFilled(prism2)) {
    resultedPrism = calcHypotenuse(prism1, prism2)
  } else if (isValueFilled(prism1)) {
    resultedPrism = prism1
  } else if (isValueFilled(prism2)) {
    resultedPrism = prism2
  }

  return roundPrism(resultedPrism)
}

const radiansToDegrees = radians => (radians * 180) / Math.PI

function getCombinedBase({ prism1, prism2, base1, base2 }) {
  // check we have all values
  if (
    !(
      isValueFilled(prism1) &&
      isValueFilled(prism2) &&
      isValueFilled(base1) &&
      isValueFilled(base2)
    )
  ) {
    // return base1, if we don't have all data filled in
    return base1
  }

  // get angle shift against base1
  const degrees = radiansToDegrees(Math.atan(prism2 / prism1))

  // get angle between base1 and base2
  const baseDiff = base1 - base2

  let result

  // now we have to shift base1 in correct way
  // angle will be 90 or 270. If angle is 270, we have to move other way
  if (Math.abs(baseDiff) < 180) {
    if (baseDiff > 0) {
      result = base1 - degrees
    } else {
      result = base1 + degrees
    }
  } else if (baseDiff > 0) {
    result = base1 + degrees
  } else {
    result = base1 - degrees
  }

  // we may end up with negative value. Adding 360 will fix it
  if (result < 0) result += 360

  return result
}
