import React from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { selectFrameBrands } from '../../../common/catalog/reducer'
import Row from '../Row'
import { getFrameBrandName } from '../../../common/catalog/utils'

const PATH_BASE = '/public/images/frameBrands'

const iGardSrc = `${PATH_BASE}/iGard.png`

const icons = {
  FUR: iGardSrc,
}

const BrandIconContent = ({ brand }) => {
  const frameBrands = useSelector(selectFrameBrands)
  const src = icons[brand]
  const brandName = getFrameBrandName(frameBrands, brand)
  if (!src) return brandName
  return <img src={src} alt={`${brandName} Logo`} />
}

const BrandIconWrapper = styled.div`
  ${({ inline }) => css`
    font-size: 12px;
    padding-left: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 20px;
      width: auto;
    }

    ${inline && css`
      display: inline-block;
      vertical-align: middle;
    `}
  `}
`

export const BrandIcon = ({ brand, inline }) => (
  <BrandIconWrapper inline={inline}>
    <BrandIconContent brand={brand} />
  </BrandIconWrapper>
)

export const BrandsIconsList = ({ brands }) => {
  const hasBrands = brands && brands.length > 0
  if (!hasBrands) return null
  return (
    <Row alignItems="center" justifyContent="center">
      {brands.map(brand => (
        <BrandIconWrapper>
          <BrandIconContent brand={brand} />
        </BrandIconWrapper>
      ))}
    </Row>
  )
}
