import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { connect, useSelector } from 'react-redux'
import moment from 'moment'
import styled from 'styled-components'
import i18n from '../../../../common/i18n/i18n'
import { GroupTitle, Box, Row, Col, Icon, HiddenInPrint, VisibleInPrint, Text, Gap } from '../..'
import PricesSection from '../PricesSection'
import SummaryTitle from '../SummaryTitle'
import { Visualiser } from '../../vca'
import SummaryTable from '../SummaryTable'
import { constants, getFieldConfig } from '../../../../common/schemaConfig'
import {
  orderTypes,
  lensOptionsSelectTypes,
  R,
  frameTypes,
  bevelPositions,
  appTypeConfig,
  lateralities,
} from '../../../../common/config'
import { getMultifocalConfig } from '../../../../common/lib/vca'
import { fetchWorkers } from '../../../../common/app/actions'
import IndividualParamsSummary from './IndividualParamsSummary'
import CorrectionsSummary from './CorrectionsSummary'
import { renderFormattedNumber, renderWithSign } from './utils'
import { getIsPolishDisabledForFrameType } from '../../../../common/orders/utils'
import { getFrameBrandName } from '../../../../common/catalog/utils'
import { selectFrameBrands } from '../../../../common/catalog/reducer'

const FrameImage = styled.img`
  border: solid 1px #f2f2f2;
  border-radius: ${({ theme }) => theme.field.borderRadius};
`

const MetaDataOfCreation = styled(Text)`
  position: absolute;
  right: 0;
  color: ${({ theme, color }) => color || theme.colors.text2};
  font-family: 'Roboto', sans-serif;
  text-transform: none;
  letter-spacing: initial;
`

const renderBooleanValue = ({ value }) => value ? i18n.t('Yes') : i18n.t('No')
const translateValue = ({ value }) => {
  if (!value && value !== 0) return '---'
  return i18n.t(value)
}

const renderIsExpress = ({ value, values }) => {
  const yesNoValue = renderBooleanValue({ value })
  if (!value) return yesNoValue
  if (!appTypeConfig.isExpress48Enabled) return yesNoValue
  // there is EXP24 and EXP48 used in some apps
  // we need to show if it is 24 or 48
  // and it can be extracted from expressCode
  const hours = values.expressCode.replace('EXP', '')
  return `${yesNoValue} (${hours})`
}

const NotesSummary = props => {
  const { t, values, isPrint } = props

  const data = []

  if (appTypeConfig.isInternalNoteEnabled !== false) {
    data.push({
      header: t('internal remark'),
      fieldName: constants.internalNote,
    })
  }
  if (appTypeConfig.isNoteEnabled !== false) {
    data.push({
      header: t('remark for manufacturer'),
      fieldName: constants.note,
    })
  }

  if (data.length === 0) return null

  return (
    <SummaryTable
      values={values}
      isDisplayedInColumn
      isHiddenIfEmpty={isPrint}
      render={children => (
        <Row>
          <Col>{children}</Col>
        </Row>
      )}
      data={data}
    />
  )
}

export const ProductSummary = ({ t, values, currentLensL, currentLensR, hasNoTitle }) => {
  const getOptionValue = ({ coatingType, side, value }) => {
    if (!value) return '---'
    const lens = side === R ? currentLensR : currentLensL
    if (!lens) return '---'
    const foundOption =
      lens.optionsArray && lens.optionsArray.find(o => o.selectType === coatingType)
    if (!foundOption || !foundOption.options) {
      return '---'
    }
    const option = foundOption.options.find(({ _id }) => _id === value)
    if (!option || !option.names || !option.names[0]) {
      return '---'
    }
    const name = option.names[0].long
    return name
  }
  return (
    <Row>
      <Col>
        {!hasNoTitle && <SummaryTitle>{t('product')}</SummaryTitle>}

        <SummaryTable
          values={values}
          isPair
          disableR={!values.rightLensEnabled}
          disableL={!values.leftLensEnabled}
          data={[
            {
              header: t('lens type'),
              fieldName: constants.lens,
              renderValue: ({ side, value, values }) => {
                if (!value) return '---'
                const lens = side === R ? currentLensR : currentLensL
                const name = lens && lens.names ? lens.names[0].long : values[`lensName${side}`]
                const stockLens =
                  lens && lens.ranges ? lens.ranges[0].range.stockLens : values[`stockLens${side}`]
                return (
                  <Row alignItems="center">
                    {name}
                    &nbsp;
                    {stockLens 
                      ? <Icon size={20} name="CustomStock" />
                     :                       <Icon size={20} name="CustomManufactured" />
                    }
                  </Row>
                )
              },
              // colProps: {
              //   grow: 1,
              //   minWidth: '20rem',
              //   maxWidth: '50%',
              // },
            },
            {
              header: t('diameter'),
              renderValue: ({ value, side, values }) => {
                const isDiameterOnly = values.orderType === orderTypes.DIAMETER_ONLY
                const cto = side === R ? values.ctoR : values.ctoL
                const isCtoCapable = side === R ? values.isCtoCapableR : values.isCtoCapableL
                const diameterOptical =
                  side === R ? values.diameterOpticalR : values.diameterOpticalL
                const isElliptical = side === R ? values.isEllipticalR : values.isEllipticalL
                let diameterString = `${value && Math.round(value)} ${
                  diameterOptical ? `/ ${diameterOptical}` : ''
                }  ${isElliptical ? 'E' : ''}`

                // prevent showing undefined in tooltip preview
                if (diameterString?.trim() === 'undefined' || !diameterString) {
                  diameterString = '---'
                }

                return isDiameterOnly || (isCtoCapable && !cto)
                  ? // ? Math.round(value)
                    diameterString
                  : t('automatic')
              },
              fieldName: constants.diameterPhysical,
              // colProps: {
              //   width: '7rem',
              // },
            },
            appTypeConfig.isBaseCurveSelectEnabled && {
              header: t('basecurve'),
              fieldName: constants.baseCurve,
            },
            {
              header: t('coating'),
              fieldName: constants.coating,
              renderValue: ({ side, value, values }) => {
                const coating = values[`coatingName${side}`]
                const text =
                  coating ||
                  getOptionValue({
                    side,
                    value,
                    coatingType: lensOptionsSelectTypes.COATING,
                    values,
                  })
                return <span>{text}</span>
              },
              // colProps: {
              //   width: '24rem',
              // },
            },
            {
              header: t('color'),
              fieldName: constants.color,
              renderValue: ({ side, value, values }) => {
                const color = values[`colorName${side}`]
                const text =
                  color ||
                  getOptionValue({
                    side,
                    value,
                    coatingType: lensOptionsSelectTypes.COLOR,
                    values,
                  })
                return <span>{text}</span>
              },
              // colProps: {
              //   width: '24rem',
              // },
            },
            appTypeConfig.isUvSelectionEnabled && {
              header: t('UV'),
              fieldName: constants.uv,
              renderValue: renderBooleanValue,
              // colProps: {
              //   width: '5rem',
              // },
            },
          ]}
        />
      </Col>
    </Row>
  )
}

const OrderSpecificationSummary = props => {
  const { t, values, workers, isCreatingOrder, workersWithArchived } = props
  return (
    <Row large justifyContent="space-between">
      <Col>
        <SummaryTable
          {...props}
          values={values}
          data={[
            {
              header: t('reference'),
              fieldName: constants.reference,
              colProps: {
                // width: '1rem',
                fitContent: true,
              },
            },
            {
              header: t('order id'),
              fieldName: 'manufacturerId',
              hidden: !values.manufacturerId,
              colProps: {
                // width: '1rem',
                fitContent: true,
              },
            },
            {
              header: t('client name'),
              fieldName: constants.clientName,
              colProps: {
                // width: '1rem',
                fitContent: true,
              },
            },
            {
              header: t('order type'),
              fieldName: constants.orderType,
              renderValue: ({ value }) => t(value),
              colProps: {
                // width: '1rem',
                fitContent: true,
              },
            },
            appTypeConfig.isExpressOrderEnabled && {
              header: t('express order'),
              fieldName: constants.isExpress,
              // renderValue: renderBooleanValue,
              renderValue: renderIsExpress,
              colProps: {
                // width: '1rem',
                fitContent: true,
              },
            },
            // only show this column when 1) creating order and currently has at least 1 worker
            // or 2) we are in detail page and had at least one worker in history (archived ones)
            (isCreatingOrder && workers && workers.length > 0) ||
            (!isCreatingOrder && workersWithArchived && workersWithArchived.length > 0)
              ? {
                  header: t('who filled order'),
                  fieldName: constants.worker,
                  renderValue: ({ value }) => {
                    const w = workersWithArchived.find(w => w._id === value)
                    return w ? w.name : '--'
                  },
                  colProps: {
                    // width: '20rem',
                  },
                }
              : undefined,
          ]}
        />
      </Col>
    </Row>
  )
}

const MinThicknessSummary = props => {
  const { showHoles = false, t, values, isPrint } = props
  const data = [
    {
      header: t('edge'),
      fieldName: constants.minEdgeThickness,
      renderValue: renderFormattedNumber,
      colProps: {
        maxWidth: !appTypeConfig.isCenterThicknessEnabled && !showHoles ? '14rem' : '12rem',
      },
    },
  ]
  if (appTypeConfig.isCenterThicknessEnabled) {
    data.push({
      header: t('center'),
      fieldName: constants.minCenterThickness,
      renderValue: renderFormattedNumber,
      // colProps: {
      //   width: '7rem',
      // },
    })
  }

  if (showHoles) {
    data.push({
      header: t('hole'),
      fieldName: constants.minHoleThickness,
      renderValue: renderFormattedNumber,
      fakePair: true,
      colProps: {
        isDisabled: ![frameTypes.DRILLED, frameTypes.GROOVEDDRILLED].includes(values.frameType),
        // width: '3rem',
      },
    })
  }
  return (
    <SummaryTable
      {...props}
      title={t('minimal thickness')}
      values={values}
      isPair
      data={data}
      disableR={!values.rightLensEnabled}
      disableL={!values.leftLensEnabled}
      isHiddenIfEmpty={isPrint}
    />
  )
}

const FrameAndBevelSummary = props => {
  const { t, values, vca } = props

  const areFramePropsDisabled =
    values.frameType === '' || !values.frameType || orderTypes.WITH_SHAPE === values.orderType

  const isPolishDisabledForFrameType = getIsPolishDisabledForFrameType(values.frameType)

  // TODO: frameType vs. order type???? is frame type mandatory for 3-4 type?

  const areGroovePropsDisabled = ![frameTypes.GROOVEDDRILLED, frameTypes.NYLOR].includes(
    values.frameType,
  )

  const isBevelPositionDisabled = values.frameType === frameTypes.DRILLED

  const data = [
    {
      header: t('frame type'),
      fieldName: constants.frameType,
      renderValue: translateValue,
    },
    {
      header: t('bevel position'),
      fieldName: constants.bevelPosition,
      rowProps: {
        isDisabled: areFramePropsDisabled || isBevelPositionDisabled,
      },
      renderValue: ({ value, values }) =>
        isBevelPositionDisabled
          ? '---'
          : [bevelPositions.FRONT, bevelPositions.BACK, bevelPositions.PERCENT].includes(
              values.bevelPosition,
            )
          ? `${values.bevelModifier}${translateValue({ value })}`
          : translateValue({ value }),
    },
    {
      header: t('polish'),
      fieldName: constants.polish,
      renderValue: renderBooleanValue,
      rowProps: {
        isDisabled: areFramePropsDisabled || isPolishDisabledForFrameType,
      },
      valueProps: {
        isValueHidden: isPolishDisabledForFrameType,
      },
    },
    appTypeConfig.isPinBevelEnabled && {
      header: t('Pin bevel'),
      fieldName: constants.pinBevel,
      renderValue: renderBooleanValue,
    },
    {
      header: t('grooveWidth'),
      fieldName: constants.grooveWidth,
      rowProps: {
        isDisabled: areFramePropsDisabled || areGroovePropsDisabled,
      },
    },
    {
      header: t('grooveDepth'),
      fieldName: constants.grooveDepth,
      rowProps: {
        isDisabled: areFramePropsDisabled || areGroovePropsDisabled,
      },
    },
  ]

  if (vca?.name) {
    data.unshift({
      header: t('shape name'),
      fieldName: 'shapeName',
      renderValue: () => vca.name,
    })
  }

  if (
    appTypeConfig.isShapeSizeAdjustmentEnabled &&
    (values.shapeSizeAdjustment || values.shapeSizeAdjustment === 0)
  ) {
    data.push({
      header: t('shapeSizeAdjustment'),
      fieldName: constants.shapeSizeAdjustment,
      renderValue: renderWithSign,
    })
  }

  return (
    <>
      <SummaryTable
        {...props}
        title={t('frame and bevel')}
        values={values}
        isDisplayedInColumn
        columnConfig={{
          header: {
            width: 'max-content',
          },
          data: {
            width: 'auto',
          },
        }}
        data={data}
      />
    </>
  )
}

const HboxVboxSummary = props => {
  const { vca, t } = props
  if (!vca) return null
  return (
    <SummaryTable
      {...props}
      values={{
        hbox: vca?.box?.r?.hbox,
        vbox: vca?.box?.r?.vbox,
        dbl: vca?.dbl,
      }}
      isDisplayedInColumn
      data={[
        {
          header: t('HBOX'),
          fieldName: 'hbox',
          renderValue: ({ value }) => Math.round(value * 10) / 10,
        },
        {
          header: t('VBOX'),
          fieldName: 'vbox',
          renderValue: ({ value }) => Math.round(value * 10) / 10,
        },
        {
          header: t('DBL'),
          fieldName: 'dbl',
        },
      ]}
    />
  )
}

export const FrameSummary = props => {
  const { t, vca, isPrint, hasNoTitle } = props
  const frameBrands = useSelector(selectFrameBrands)
  if (!vca) return null

  const data = [
    {
      header: t('reize; Frame supplier'),
      fieldName: 'fsupplier',
      renderValue: ({ value, values }) =>
        getFrameBrandName(frameBrands, value || values.frameSupplier),
    },
    {
      header: t('reize; Frame modelCode'),
      fieldName: 'modelCode',
      renderValue: ({ value, values }) => value || values.frameModelCode,
    },
    {
      header: t('reize; Eyesize'),
      fieldName: 'formCode',
      renderValue: ({ value, values }) => value || values.frameFormCode,
    },
    {
      header: t('reize; Frame color'),
      fieldName: 'colorText',
      renderValue: ({ value, values }) => value || values.frameColor,
    },
  ]

  if (isPrint) {
    data.push({
      header: t('frameNo'),
      fieldName: 'frameNo',
      renderValue: ({ value, values }) => value || values.frameNo,
    })
  }

  return (
    <SummaryTable
      {...props}
      values={vca}
      title={!hasNoTitle ? t('reize; Frame') : ''}
      isDisplayedInColumn
      data={data}
    />
  )
}

class Summary extends React.Component {
  componentDidMount() {
    const { fetchWorkers } = this.props
    fetchWorkers()
  }

  getOptionValue({ coatingType, side, value }) {
    const { currentLensL, currentLensR } = this.props
    if (!value) return '---'
    const lens = side === R ? currentLensR : currentLensL
    if (!lens) return '---'
    const foundOption =
      lens.optionsArray && lens.optionsArray.find(o => o.selectType === coatingType)
    if (!foundOption || !foundOption.options) {
      return '---'
    }
    const option = foundOption.options.find(({ _id }) => _id === value)
    if (!option || !option.names || !option.names[0]) {
      return '---'
    }
    const name = option.names[0].long
    return name
  }

  isSomeFieldFilled = fieldsArray => {
    const { values } = this.props
    return fieldsArray.some(f => {
      const valueR = values[`${f}R`]
      const valueL = values[`${f}L`]
      return !!valueR || valueR === 0 || !!valueL || valueL === 0
    })
  }

  render() {
    const {
      t,
      currentLensL,
      currentLensR,
      vca,
      isCreatingOrder,
      isCalculationSummary,
      isPrint,
      viewer,
    } = this.props
    const { values } = this.props

    return (
      <>
        <Box>
          <HiddenInPrint>
            <Box.Header>
              <Box.Title>
                {t('step 1')} - {t('STEP_1_NAME')}
              </Box.Title>
            </Box.Header>
          </HiddenInPrint>
          <Box.Content>
            <Col width="100%" position="relative">
              <SummaryTitle>{t('order specification')}</SummaryTitle>
              {isPrint && (
                <MetaDataOfCreation>
                  Username: {viewer.username} {t('created at')}:{' '}
                  {moment(values.createdAt || undefined).format('DD.MM.YYYY')}
                </MetaDataOfCreation>
              )}
            </Col>
            <OrderSpecificationSummary {...this.props} />
            <NotesSummary {...this.props} />
            <CorrectionsSummary {...this.props} />
            <ProductSummary {...this.props} />

            <Row>
              <MinThicknessSummary
                {...this.props}
                isVisible={values.orderType === orderTypes.DIAMETER_ONLY}
                render={child => <Col>{child}</Col>}
              />
              <IndividualParamsSummary {...this.props} />

              {/* ONLY IN PRINT START */}
              <FrameAndBevelSummary
                {...this.props}
                render={child => <Col>{child}</Col>}
                isVisible={isPrint && values.orderType !== orderTypes.DIAMETER_ONLY}
              />
              <MinThicknessSummary
                {...this.props}
                showHoles
                isVisible={isPrint && values.orderType !== orderTypes.DIAMETER_ONLY}
                render={child => <Col>{child}</Col>}
              />
              {/* ONLY IN PRINT END */}
            </Row>

            <Row>
              {/* ONLY IN PRINT START */}
              <HboxVboxSummary
                {...this.props}
                render={child => <Col>{child}</Col>}
                isVisible={
                  isPrint &&
                  isCalculationSummary &&
                  values.orderType !== orderTypes.DIAMETER_ONLY &&
                  vca
                }
              />
              <FrameSummary
                {...this.props}
                isVisible={isPrint && values.orderType === orderTypes.WITH_FRAME}
                render={child => (
                  <Row>
                    <Col>{child}</Col>
                  </Row>
                )}
              />
              {/* ONLY IN PRINT END */}
            </Row>
          </Box.Content>
        </Box>
        {values.orderType !== orderTypes.DIAMETER_ONLY && (
          <HiddenInPrint>
            <Box>
              <Box.Header>
                <Box.Title>
                  {t('step 2')} - {t('STEP_2_NAME')}
                </Box.Title>
              </Box.Header>
              <Box.Content>
                <Row>
                  <Col width="30%">
                    <FrameAndBevelSummary
                      {...this.props}
                      render={child => (
                        <Row>
                          <Col>{child}</Col>
                        </Row>
                      )}
                    />
                    <MinThicknessSummary
                      {...this.props}
                      showHoles
                      render={child => (
                        <Row>
                          <Col>{child}</Col>
                        </Row>
                      )}
                    />
                  </Col>
                  <Col width="70%">
                    {vca && vca.frameImagePath && <SummaryTitle>{t('Shape data')}</SummaryTitle>}
                    <Visualiser
                      {...vca}
                      pdR={values.pdR}
                      pdL={values.pdL}
                      heightR={values.heightR}
                      heightL={values.heightL}
                      withoutShape={values.orderType === orderTypes.DIAMETER_ONLY || !vca}
                      // showRadius
                      // showLensesGeometryCenter
                      disableL={!values.leftLensEnabled}
                      disableR={!values.rightLensEnabled}
                      fitsRadius
                      showMultifocal
                      multifocalConfig={getMultifocalConfig(currentLensR, values.proglen)}
                      multifocalConfigL={getMultifocalConfig(currentLensL, values.proglen)}
                      showPupil
                      withBox
                      withDbl
                      // TODO totot by chtělo udělat responzivně
                      // teoreticky uvnitř visualiseru se může width a height modifikovat podle šířky obraovky
                      width="750"
                      height="350"
                      hidden={values.orderType !== orderTypes.DIAMETER_ONLY && !vca}
                      overlayMessage={
                        values.orderType !== orderTypes.DIAMETER_ONLY && !vca
                          ? t('Loading')
                          : undefined
                      }
                      hideHoles={
                        ![frameTypes.DRILLED, frameTypes.GROOVEDDRILLED].includes(values.frameType)
                      }
                    />
                    {vca && (vca.frameImagePath || vca.modelCode) && (
                      <>
                        <Gap gap="2rem" />
                        <Row>
                          <Col>
                            <SummaryTitle>{t('reize; Frame thumbnail')}</SummaryTitle>
                            <FrameImage src={vca.frameImagePath} alt="" />
                          </Col>
                          <FrameSummary
                            {...this.props}
                            render={child => <Col grow={1}>{child}</Col>}
                          />
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </Box.Content>
            </Box>
          </HiddenInPrint>
        )}
        <HiddenInPrint>
          <PricesSection values={values} isCreatingOrder={isCreatingOrder} />
        </HiddenInPrint>
      </>
    )
  }
}

Summary.defaultProps = {
  currentLensL: null,
  currentLensR: null,
  vca: null,
  isCreatingOrder: false,
  isCalculationSummary: false,
  isPrint: false,
}

Summary.propTypes = {
  t: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  currentLensL: PropTypes.object,
  currentLensR: PropTypes.object,
  workersWithArchived: PropTypes.array.isRequired,
  workers: PropTypes.array.isRequired,
  isCreatingOrder: PropTypes.bool,
  fetchWorkers: PropTypes.func.isRequired,
  vca: PropTypes.object,
  isCalculationSummary: PropTypes.bool,
  isPrint: PropTypes.bool,
  viewer: PropTypes.object.isRequired,
}

const enhance = compose(
  connect(
    ({ app }) => ({
      workersWithArchived: app.workersWithArchived,
      workers: app.workers,
      viewer: app.viewer,
    }),
    {
      fetchWorkers,
    },
  ),
  withTranslation(),
)

export default enhance(Summary)
