import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { GroupTitle, Row, Button, CreateOrderButtonsWrapper, Gap, Link } from '..'
import { Summary, ShowWarningPopupIfNeeded } from '.'
import { fetchVca, calcVca3d } from '../../../common/vca/actions'
import { createOrUpdateOrder, setActiveOrderStep } from '../../../common/orders/actions'
import { prepareOrderToSend, totalResetOrder } from '../../../common/orders/utils'
import { orderTypes, R } from '../../../common/config'
import { constants } from '../../../common/schemaConfig'
import Popup from '../popups/Popup'
import withRouter from '../hoc/withRouter'

class CreateOrderStep4Page extends Component {
  state = {
    closestIndex: 0,
    thicknessSet: 'd3',
  }

  componentDidMount() {
    const { setActiveOrderStep, fetchLensPrice, fields } = this.props
    setActiveOrderStep(4)
    if (process.env.IS_BROWSER) {
      window.scrollTo(0, 0)
    }
  }

  getOptionValue({ coatingType, side, value }) {
    const { currentLensL, currentLensR } = this.props
    if (!value) return '---'
    const lens = side === R ? currentLensR : currentLensL
    const { options } = lens.optionsArray.find(o => o.selectType === coatingType)
    const option = options.find(({ _id }) => _id === value)
    const name = option.names[0].long
    return name
  }

  submitFunc = async ({ sendImmediately } = {}) => {
    const {
      createOrUpdateOrder,
      selectedVca,
      fields,
      history,
      currentId,
      currentLensR,
      currentLensL,
    } = this.props

    const order = prepareOrderToSend({
      orderValues: fields.$cleanValues(),
      selectedVca,
      currentLensR,
      currentLensL,
    })

    try {
      const returnedOrder = await createOrUpdateOrder({ order, sendImmediately, currentId }).meta
        .action.payload
      const statusNumberText = returnedOrder?.data?.order?.status?.split('_')[0]
      const statusNumber = parseInt(statusNumberText, 10)

      // status over 20 means order was send and we should clean it
      if (statusNumber > 20) {
        totalResetOrder()
      }
      history.push(Link.HOME)
    } catch (e) {

      const bugReport = {
        message: 'Error při odesílání objednávky',
        uniqueId: order?.uniqueId,
      }

      // todo JSON.stringify(e) nic nevrací. 
      // Nevim, proč je to napsáno takto, ale nechci to ještě mazat.
      console.notifyWarning(new Error('Error při odesílání objednávky'), {
        err: JSON.stringify(e),
        ...bugReport,
      })

      console.notifyWarning(e, bugReport)

      console.log('error odesilani objednavky', e)
    }
  }

  isSomeFieldFilled = fieldsArray => {
    const { fields } = this.props
    return fieldsArray.some(f => {
      const valueR = fields[`${f}R`].value
      const valueL = fields[`${f}L`].value
      return !!valueR || valueR === 0 || !!valueL || valueL === 0
    })
  }

  isPrismDisabled = () =>
    !this.isSomeFieldFilled([constants.prism1, constants.base1, constants.prism2, constants.base2])

  isDecentrationDisabled = () => !this.isSomeFieldFilled([constants.decX, constants.decY])

  isCentrationDisabled = () => !this.isSomeFieldFilled([constants.pd, constants.height])

  isBaseDisabled = () => !this.isSomeFieldFilled([constants.baseCurve])

  render() {
    const {
      holes, // for visualiser TODO:
      wantCalculation,
      sendingOrderError,
      sendingOrder,
      fields,
      currentLensL,
      currentLensR,
      selectedVca,
      t,
      viewer,
    } = this.props
    const orderValues = fields.$values()
    const backLink =
      orderValues.orderType === orderTypes.DIAMETER_ONLY
        ? !wantCalculation
          ? Link.CREATE_ORDER_STEP_1
          : Link.CREATE_ORDER_STEP_3
        : !wantCalculation
          ? Link.CREATE_ORDER_STEP_2
          : Link.CREATE_ORDER_STEP_3

    // TODO: nazvy kroku nekam centralizovat?? budou se opakovat..
    let backLinkText
    switch (backLink) {
      case Link.CREATE_ORDER_STEP_1: {
        backLinkText = t('STEP_1_NAME')
        break
      }
      case Link.CREATE_ORDER_STEP_2: {
        backLinkText = t('STEP_2_NAME')
        break
      }
      case Link.CREATE_ORDER_STEP_3: {
        backLinkText = t('STEP_3_NAME')
        break
      }
      // no default
    }
    return (
      <>
        <Summary
          values={orderValues}
          currentLensL={currentLensL}
          currentLensR={currentLensR}
          vca={selectedVca}
          isCreatingOrder
        />
        <CreateOrderButtonsWrapper>
          <Row alignItems="stretch">
            <Button.StepBack to={backLink}>{backLinkText}</Button.StepBack>
          </Row>
          <Row alignItems="stretch">
            <Button.TrashOrder fields={fields} />
            <Gap />
            <ShowWarningPopupIfNeeded
              onConfirm={this.innerOnClick || (() => null)}
              okText={t('save concept')}
              values={fields.$values()}
            >
              {({ open }) => (
                <Button.SaveConcept
                  onClick={open}
                  fields={fields}
                  getInnerOnClick={onClick => {
                    this.innerOnClick = onClick
                  }}
                />
              )}
            </ShowWarningPopupIfNeeded>
            <Gap />
            <Button.PrintOrder />
          </Row>
          <Row justifyContent="flex-end" alignItems="stretch">
            <ShowWarningPopupIfNeeded
              onConfirm={() => this.submitFunc()}
              okText={t('prepare to send')}
              values={fields.$values()}
              disabled={!!sendingOrder}
            >
              {({ open }) => (
                <Button
                  large
                  primary
                  rightIcon="IosArrowForward"
                  onClick={open}
                  disabled={!!sendingOrder}
                  maxWidth="20rem"
                >
                  {t('prepare to send')}
                </Button>
              )}
            </ShowWarningPopupIfNeeded>
            <Gap />
            <ShowWarningPopupIfNeeded
              onConfirm={() => this.submitFunc({ sendImmediately: true })}
              okText={t('send immediately')}
              values={fields.$values()}
              disabled={!!sendingOrder || !viewer.isActive}
            >
              {({ open }) => (
                <Button
                  disabled={!!sendingOrder || !viewer.isActive}
                  large
                  primary
                  rightIcon="CustomDoubleArrow"
                  maxWidth="20rem"
                  onClick={open}
                >
                  {t('send immediately')}
                </Button>
              )}
            </ShowWarningPopupIfNeeded>
          </Row>
        </CreateOrderButtonsWrapper>
      </>
    )
  }
}

CreateOrderStep4Page.defaultProps = {
  calculatedVca: null,
  selectedVca: null,
  currentLensR: null,
  currentLensL: null,
  currentId: null,
  sendingOrderError: null,
}

CreateOrderStep4Page.propTypes = {
  setActiveOrderStep: PropTypes.func.isRequired,
  fetchVca: PropTypes.func.isRequired,
  createOrUpdateOrder: PropTypes.func.isRequired,
  calcVca3d: PropTypes.func.isRequired,
  selectedVca: PropTypes.object,
  calculatedVca: PropTypes.object,
  holes: PropTypes.array.isRequired,
  wantCalculation: PropTypes.bool.isRequired,
  sendingOrderError: PropTypes.string,
  sendingOrder: PropTypes.bool.isRequired,
  currentId: PropTypes.string,
  fields: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  currentLensR: PropTypes.object,
  currentLensL: PropTypes.object,
  viewer: PropTypes.object.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      selectedVca: state.vca.selectedVca,
      calculatedVca: state.vca.calculatedVca,
      holes: state.vca.holes,
      wantCalculation: state.vca.wantCalculation,
      sendingOrderError: state.orders.sendingOrderError,
      sendingOrder: state.orders.sendingOrder,
      currentId: state.orders.currentId,

      currentLensR: state.catalog.currentLensR,
      currentLensL: state.catalog.currentLensL,
      viewer: state.app.viewer,
    }),
    {
      fetchVca,
      calcVca3d,
      createOrUpdateOrder,
      setActiveOrderStep,
    },
  ),
  withRouter,
  withTranslation(),
)

export default enhance(CreateOrderStep4Page)
