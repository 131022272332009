// LW returns a calculations warnings and errors
// This function transforms the response to a format that can be used in the frontend
// Results are messages keys that might be translated in locize
// The keys are in the format CALC_E_1234 for errors and CALC_W_1234 for warnings

const getKey = status => {
  if (!status?.code) return ''
  if (status?.severity === 'E') return `CALC_E_${status.code}`
  return `CALC_W_${status.code}`
}

const getText = (status, t) => {
  const key = getKey(status)
  console.log('CALCULATION STATUS KEY', key)
  if (!key) return ''
  const translatedKey = t(key)
  // if key is not translated, return empty string
  if (translatedKey === key) return ''
  return translatedKey
}

const getHasData = arr => {
  if (!arr || arr.length === 0) return false
  return arr.some(e => e.text)
}

const splitBySide = allMessages => {
  const l = []
  const r = []
  allMessages.forEach(message => {
    const target = message.side === 'L' ? l : r
    target.push(message)
  })

  const hasData = getHasData(l) || getHasData(r)

  return { l, r, hasData }
}

const getUniqueMessages = allMessages => {
  const uniqueMessages = [...new Set(allMessages.map(e => e.text))]
  return uniqueMessages
}

const splitBySeverity = allMessages => {
  const errors = []
  const warnings = []
  allMessages.forEach(message => {
    const target = message.severity === 'E' ? errors : warnings
    target.push(message)
  })

  return { errors, warnings, uniqueErrors: getUniqueMessages(errors) }
}

const splitBySeverityAndSide = allMessages => {
  const result = splitBySeverity(allMessages)
  result.errors = splitBySide(result.errors)
  result.warnings = splitBySide(result.warnings)
  return result
}

export const transformCalculationStatus = (calculationStatus, t) => {
  if (!calculationStatus) return null

  const allStatusValues = calculationStatus?.value || []

  const transformed = allStatusValues.map(status => {
    const text = getText(status, t)
    return { ...status, text }
  })

  const splitResult = splitBySeverityAndSide(transformed)

  return splitResult
}
