import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as notifs from '../../common/lib/notifs'

import { addIoListener, emitStats, getIo, useEmitAuth } from '../io'
import { useServerConnectionStatus } from './ServerConnectionWatcher'
import { fetchMe, fetchNews, fetchWorkers } from '../../common/app/actions'
import { fetchTracers } from '../../common/config/actions'
import { setShouldUpdateOrders } from '../../common/orders/actions'
import { fetchFrameBrands, fetchCatalogExtensions } from '../../common/catalog/actions'

function DataUpdateService() {
  const dispatch = useDispatch()

  const onReconnected = useCallback(() => {
    emitStats(stats => {
      console.log('server stats', stats)

      // only trigger data update if it is not server restart
      if (!stats.serverStartedRecently) {
        dispatch(fetchNews())
        dispatch(fetchWorkers())
        dispatch(setShouldUpdateOrders())
      }
    })
  }, [dispatch])

  // download all data on server reconnect
  useServerConnectionStatus({
    onReconnected,
  })

  // refresh news on server update (randomly)
  const refreshNewsTimeout = useRef(null)
  useEffect(
    () =>
      addIoListener('shouldUpdateNews', () => {
        // trigger update in next 120s, cuz we don't want to overload server at once from all clients!
        clearTimeout(refreshNewsTimeout.current)
        const time = Math.random() * 120 * 1000

        console.log('news should be updated - refresh them in ', time)

        refreshNewsTimeout.current = setTimeout(() => {
          dispatch(fetchNews())
        }, time)
      }),
    [dispatch],
  )

  // refresh workers on server update
  useEffect(
    () =>
      addIoListener('shouldUpdateWorkers', () => {
        dispatch(fetchWorkers())
      }),
    [dispatch],
  )
  // refresh tracers on server update
  useEffect(
    () =>
      addIoListener('shouldUpdateTracers', () => {
        dispatch(fetchTracers())
      }),
    [dispatch],
  )

  // setup backup interval,
  // it's meant to be used when there is long inactivity
  const backupRefreshInterval = useRef(null)
  useEffect(() => {
    // console.log('backup data update interval refreshed')
    clearInterval(backupRefreshInterval.current)
    backupRefreshInterval.current = setInterval(() => {
      dispatch(fetchNews())
      dispatch(fetchWorkers())
      dispatch(fetchTracers())
      dispatch(fetchMe())
      dispatch(setShouldUpdateOrders())
    }, 1000 * 60 * 60 * 6)
    // per 6 hours
  }, [dispatch])

  // initial not important data load after app is opened
  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchWorkers())
      dispatch(fetchFrameBrands())
      dispatch(fetchCatalogExtensions())
    }, 1000 * 3)
    setTimeout(() => {
      dispatch(fetchTracers())
    }, 1000 * 4)
    setTimeout(() => {
      dispatch(fetchMe())
    }, 1000 * 4)
  }, [dispatch])

  // render nothing
  return null
}

export default DataUpdateService
