import now from 'performance-now'
import { v4 as uuid } from 'uuid'
import i18n from '../i18n/i18n'
import * as actions from './actions'
import * as authActions from '../auth/actions'
import * as fieldsActions from '../lib/redux-fields/actions'
import * as ordersActions from '../orders/actions'
import * as notifs from '../lib/notifs'
import { singleKeys } from '../schemaConfig'
import { orderTypes } from '../config'
import {
  recalcVca,
  radiiToXY,
  deformVertical,
  deformHorizontal,
  deformABCD,
  process3d,
  getDefaultRawDrawingVca,
  xyToRadii,
  findSmallestCircle,
  findBoxSystemXY,
  flipXYData,
  centerXYData,
  processVcaPoints,
  fullNormalize,
  processHoles,
  processVca,
} from '../lib/vca'
import { transformCalculationStatus } from '../orders/calculationStatus'

const State = {
  shapesCollections: [], // new admin assigned shape collections (optional)
  selectedVca: null,
  selectedVcaDeformed: null,
  // scale: null, // one mm is multiplied by this number, output is in pixels. If null, canvas will try to fit max width
  standardLib: [],
  standardLibDeformed: [],
  myLib: [],
  myLibDeformed: [],
  deformThumbsVBox: '',
  deformThumbsHBox: '',
  deformSelectedRight: 0,
  deformSelectedLeft: 0,
  deformSelectedTop: 0,
  deformSelectedBottom: 0,
  calculatedVca: null,
  calculatedComparedVca: null,
  calculating: false,
  calculatingCompared: false,
  comparedLens: null,
  calculationError: false,
  calculationErrorCompared: false,
  calculationStatus: null, // calculation status for selected lens, errors, warnings etc.
  calculationStatusCompared: null, // calculation status for compared lens
  fromTracer: false,
  uploadedVca: null,
  wantCalculation: false,
  fetchingOneVca: false,
  holes: [],
  // radius: 0,
  // radiusX: 0,
  // radiusY: 0,
  validCalculation: false,
  drawingVca: null,
  // indicates if selected vca was modified manually in deformator
  isSelectedVcaModified: false,
  // original vca is set only once, when user opens deformator
  // it is used to reset to unmodified state
  originalVca: null,
}

const resetDeformations = () => ({
  deformThumbsVBox: 0,
  deformThumbsHBox: 0,
  deformSelectedRight: 0,
  deformSelectedLeft: 0,
  deformSelectedTop: 0,
  deformSelectedBottom: 0,
  // radius: 0,
  // radiusX: 0,
  // radiusY: 0,
})

// TODO: use this
const unselectVca = () => ({
  fromTracer: false,
  calculatedVca: null,
  calculatedComparedVca: null,
  calculationError: false,
  calculationStatus: null,
  calculationStatusCompared: null,
  selectedVca: null,
  selectedVcaDeformed: null,
  uploadedVca: null,
  calculating: false,
  calculatingCompared: false,
  holes: [], // currently edited holes
  originalVca: null,
  isSelectedVcaModified: false,
  // radius: 0,
  // radiusX: 0,
  // radiusY: 0,
})

// TODO: use this when leaving create order page
const cleanOrder = () => ({
  ...unselectVca(),
  ...resetDeformations(),
  wantCalculation: false,
  comparedLens: null,
})

const selectOrderValues = rootState => rootState.fields.OrderForm

const vcaReducer = (state = State, action, rootState) => {
  switch (action.type) {
    case authActions.SIGN_OUT: {
      return {
        ...State,
      }
    }
    case actions.SET_ORIGINAL_VCA: {
      if (state.originalVca) return state
      return {
        ...state,
        originalVca: state.selectedVca,
      }
    }
    case actions.RESET_TO_ORIGINAL_VCA: {
      return {
        ...state,
        selectedVca: state.originalVca,
        selectedVcaDeformed: state.originalVca,
        isSelectedVcaModified: false,
      }
    }
    case actions.FETCH_VCA_SUCCESS: {
      const { standardShapes, myShapes, collections } = action.payload.data

      const shapesCollections = collections.map(col => ({
        ...col,
        shapes: col.shapes
          .sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1)
          .map(vca => processVca(vca)),
      }))

      const standardLib = (standardShapes || []).map(vca => processVca(vca))
      const standardLibDeformed = standardLib
        .map(vca => deformVertical(vca, state.deformThumbsVBox))
        .map(vca => deformHorizontal(vca, state.deformThumbsHBox))

      const myLib = (myShapes || []).map(vca => processVca(vca))
      const myLibDeformed = myLib
        .map(vca => deformVertical(vca, state.deformThumbsVBox))
        .map(vca => deformHorizontal(vca, state.deformThumbsHBox))

      return {
        ...state,
        standardLib,
        standardLibDeformed,
        myLib,
        myLibDeformed,
        shapesCollections,
      }
    }

    case actions.FETCH_TRACED_VCA_SUCCESS: {
      const { tracedShape } = action.payload.data

      const selectedVca = processVca(tracedShape, selectOrderValues(rootState))
      const selectedVcaDeformed = processVca(tracedShape, selectOrderValues(rootState))

      if (!selectedVca.shapeData) {
        // probably some error in shape data
        notifs.error({
          title: i18n.t('Tracing error'),
          message: i18n.t('Error - try again'),
        })
        return {
          ...state,
        }
      }

      return {
        ...state,
        selectedVca,
        selectedVcaDeformed,
        fromTracer: true,
        ...resetDeformations(),
      }
    }

    case actions.UPLOAD_VCA_SUCCESS: {
      const { vca } = action.payload.data

      const uploadedVca = processVca(vca, selectOrderValues(rootState))

      return {
        ...state,
        uploadedVca,
      }
    }

    case actions.UPLOAD_VCA_ERROR: {
      return {
        ...state,
        uploadedVca: null,
      }
    }

    case actions.SET_DRAWING_VCA: {
      let { drawingVca } = action.payload
      if (typeof drawingVca === 'undefined') {
        // vychozi tvar
        drawingVca = processVca(getDefaultRawDrawingVca())
      }
      return {
        ...state,
        drawingVca,
      }
    }

    case actions.FLIP_DRAW_VCA_HORIZONTALLY: {
      let { drawingVca } = state

      const newShapeXY = flipXYData(drawingVca.shapeDataXY.r)
      const newShapeRadii = xyToRadii(newShapeXY)

      drawingVca = {
        ...drawingVca,
        invalidRadii: true,
        shapeDataXY: {
          ...drawingVca.shapeDataXY,
          r: newShapeXY, // right side only
        },
        shapeData: {
          ...drawingVca.shapeData,
          r: newShapeRadii, // right side only
        },
      }
      return {
        ...state,
        drawingVca,
      }
    }

    case actions.SAVE_DRAWING_VCA: {
      const { yRatio, xRatio, ratio } = action.payload
      let { drawingVca } = state

      // needs to be centered, otherwise BOX wont work
      const newShapeXYR = centerXYData(drawingVca.shapeDataXY.r)
      const newShapeXYL = flipXYData(newShapeXYR)
      const newShapeRadiiR = xyToRadii(drawingVca.shapeDataXY.r)
      const newShapeRadiiL = xyToRadii(newShapeXYL)

      drawingVca = {
        ...drawingVca,
        invalidRadii: true,
        shapeDataXY: {
          ...drawingVca.shapeDataXY,
          r: newShapeXYR,
          l: newShapeXYL,
        },
        shapeData: {
          ...drawingVca.shapeData,
          r: newShapeRadiiR,
          l: newShapeRadiiL,
        },
      }
      const selectedVca = processVca(drawingVca, selectOrderValues(rootState))
      const selectedVcaDeformed = { ...selectedVca }

      return {
        ...state,
        selectedVca,
        selectedVcaDeformed,
        validCalculation: false,
      }
    }

    case actions.UPDATE_VCA_DRAW_POINT: {
      const { index, point } = action.payload
      let { drawingVca } = state

      const newShapeXY = [...drawingVca.shapeDataXY.r]
      newShapeXY[index] = point

      // TODO:  on DONE do this
      // const newShapeRadii = xyToRadii(newShapeXY) // this approximates XY to correct radii angles
      // now we have to transform correct radii angles back to XY positions
      // newShapeXY = radiiToXY(newShapeRadii)
      // this two steps ensure that we have correct evenly (angle) distributed positions

      drawingVca = {
        ...drawingVca,
        invalidRadii: true,
        shapeDataXY: {
          ...drawingVca.shapeDataXY,
          r: newShapeXY, // right side only
          // TODO: left???? --> mirror when done
        },
        // shapeData: {
        //   ...drawingVca.shapeData,
        //   r: newShapeRadii,
        // },
      }

      // console.log('drawingVca', drawingVca)

      return {
        ...state,
        drawingVca,
      }
    }

    //
    case actions.NORMALIZE_DRAW_VCA: {
      const { justCoords } = action.payload
      let { drawingVca } = state

      if (!drawingVca) {
        // this should not happen, pretty wierd
        console.log('drawing vca is not set, but it should be')
        if (process.env.IS_BROWSER) {
          // bugsnagClient.notify('drawing vca is not set, but it should be') TODO:
          console.notifyWarning('drawing vca is not set, but it should be')
        }
        return {
          ...state,
        }
      }

      // Just COORDS normalization (used for drawer - when drawing)
      if (justCoords) {
        let newShapeXY = [...drawingVca.shapeDataXY.r]
        const newShapeRadii = xyToRadii(newShapeXY)
        newShapeXY = radiiToXY(newShapeRadii)
        drawingVca = {
          ...drawingVca,
          invalidRadii: true,
          shapeDataXY: {
            ...drawingVca.shapeDataXY,
            r: newShapeXY, // right side only
          },
          shapeData: {
            ...drawingVca.shapeData,
            r: newShapeRadii,
          },
        }

        return {
          ...state,
          drawingVca,
        }
      }

      // FULL normalization
      drawingVca = fullNormalize(drawingVca, selectOrderValues(rootState))

      return {
        ...state,
        drawingVca,
      }
    }

    case actions.CENTER_DRAW_VCA: {
      let { drawingVca } = state
      let newShapeXY = [...drawingVca.shapeDataXY.r]

      newShapeXY = centerXYData(newShapeXY)

      // normalize
      const newShapeRadii = xyToRadii(newShapeXY) // this approximates XY to correct radii angles
      newShapeXY = radiiToXY(newShapeRadii)

      drawingVca = {
        ...drawingVca,
        invalidRadii: true,
        shapeDataXY: {
          ...drawingVca.shapeDataXY,
          r: newShapeXY, // right side only
        },
        shapeData: {
          ...drawingVca.shapeData,
          r: newShapeRadii,
        },
      }

      return {
        ...state,
        drawingVca,
      }
    }
    case actions.SMOOTH_DRAW_VCA: {
      // for every point:
      // look at 3 neighbours at each side
      // measure delta X and Y (create edges / lines)
      // look for:
      // - big differences e.g. average is 10mm on some axis
      //   if edge on current point is +- 30 %, its ok, if not bend the value
      // TODO: nebo se podivat na radiusy a jak se mezi sebou lisi, obdobne...
      const scope = 15 // in each direction
      const maxDeviation = 1.2 // 1.XX %
      const bending = 0.1

      let { drawingVca } = state
      const newShapeRadii = [...drawingVca.shapeData.r]

      const smooth = () => {
        // const newShapeRadii = xyToRadii(newShapeXY) // this approximates XY to correct radii angles
        // now we have to transform correct radii angles back to XY positions
        // newShapeXY = radiiToXY(newShapeRadii)
        // this two steps ensure that we have correct evenly (angle) distributed positions
        const diffs = newShapeRadii.map(
          (r, index) => r - (newShapeRadii[index - 1] || newShapeRadii[newShapeRadii.length - 1])
        )
        diffs.forEach((d, index) => {
          let pointer = index
          let sum = 0

          // right direction (counter clock wise)
          while (pointer < index + scope) {
            pointer += 1
            const overflowed = diffs.length - pointer - 1
            let neighbour
            if (overflowed < 0) {
              neighbour = diffs[-overflowed]
            } else {
              neighbour = diffs[pointer]
            }
            sum += neighbour
          }

          pointer = index
          while (pointer > index - scope) {
            pointer -= 1
            let neighbour
            if (pointer < 0) {
              neighbour = diffs[diffs.length + pointer]
            } else {
              neighbour = diffs[pointer]
            }

            sum += neighbour
          }

          const avg = sum / (scope * 2)

          const max = avg * maxDeviation

          if (Math.abs(d) > max) {
            newShapeRadii[index] -= d * bending
            newShapeRadii[index - 1] += d * bending
          }
        })
      }

      for (let i = 0; i < 10; i++) {
        smooth()
      }

      const newShapeXY = radiiToXY(newShapeRadii)

      drawingVca = {
        ...drawingVca,
        invalidRadii: true,
        shapeDataXY: {
          ...drawingVca.shapeDataXY,
          r: newShapeXY, // right side only
        },
        shapeData: {
          ...drawingVca.shapeData,
          r: newShapeRadii,
        },
      }

      return {
        ...state,
        drawingVca,
      }
    }

    case actions.SELECT_VCA: {
      const {
        _id,
        // fromStandardLib,
        // fromMyLib,
        uploaded,
      } = action.payload

      // const {
      //   deformThumbsHBox,
      //   deformThumbsVBox,
      // } = state

      let selectedVca = null

      // let lib
      // if (fromStandardLib) {
      //   lib = 'standardLib'
      // }
      // if (fromMyLib) {
      //   lib = 'myLib'
      // }
      // if (lib) {
      //   selectedVca = state[lib].find(vca => vca._id === _id)

      //   if (selectedVca) {
      //     selectedVca = { ...selectedVca }
      //     if (deformThumbsHBox) {
      //       selectedVca = deformHorizontal(selectedVca, deformThumbsHBox)
      //     }
      //     if (deformThumbsVBox) {
      //       selectedVca = deformVertical(selectedVca, deformThumbsVBox)
      //     }
      //   }
      // }

      if (uploaded) {
        selectedVca = state.uploadedVca
      }

      if (!_id && !uploaded) {
        return {
          ...state,
          ...unselectVca(),
        }
      }
      selectedVca = { ...selectedVca }
      const selectedVcaDeformed = { ...selectedVca }

      return {
        ...state,
        selectedVca,
        selectedVcaDeformed,
        validCalculation: false,
        // !remeber - save_draw_vca has duplicit functionality! copy changes over there too
      }
    }

    case actions.FETCH_ONE_VCA_SUCCESS: {
      let { vca } = action.payload.data

      const { hbox, vbox, dbl } = action.meta.action

      vca = processVca(vca, selectOrderValues(rootState))

      if (hbox) {
        vca = deformHorizontal(vca, hbox)
      }
      if (vbox) {
        vca = deformVertical(vca, vbox)
      }
      if (dbl) {
        vca = { ...vca, dbl }
      }

      // recalc it with order values to get more accurate DM calc after deformation
      vca = recalcVca({ vca, orderValues: selectOrderValues(rootState) })

      return {
        ...state,
        selectedVca: vca,
        dbl,
        selectedVcaDeformed: { ...vca },
        fetchingOneVca: false,
        validCalculation: false,
      }
    }

    case actions.FETCH_ONE_VCA_START: {
      return {
        ...state,
        fetchingOneVca: true,
      }
    }

    case actions.FETCH_ONE_VCA_ERROR: {
      notifs.error({
        title: i18n.t('Error downloading lens data'),
        // title: 'Chyba při stažení dat čočky',
        message: i18n.t('Error - try again'),
        // message: 'Zkuste znovu. Pokud přetrvává, kontaktuje prosím technickou podporu.',
      })
      return {
        ...state,
        fetchingOneVca: false,
      }
    }

    case actions.SET_VISUALISER_SCALE: {
      const { scale } = action.payload
      return {
        ...state,
        scale: scale && scale > 0 ? scale : null,
      }
    }

    case actions.DEFORM_THUMBS_VERTICAL: {
      const { vbox } = action.payload
      const { standardLib, myLib, deformThumbsHBox } = state

      const deformedLibs = {
        standardLibDeformed: standardLib
          .map(vca => deformVertical(vca, vbox))
          .map(vca => deformHorizontal(vca, deformThumbsHBox)),
        myLibDeformed: myLib
          .map(vca => deformVertical(vca, vbox))
          .map(vca => deformHorizontal(vca, deformThumbsHBox)),
      }

      return {
        ...state,
        ...deformedLibs,
        deformThumbsVBox: vbox,
        validCalculation: false,
      }
    }

    case actions.DEFORM_THUMBS_HORIZONTAL: {
      const { hbox } = action.payload
      const { standardLib, myLib, deformThumbsVBox } = state

      const deformedLibs = {
        standardLibDeformed: standardLib
          .map(vca => deformVertical(vca, deformThumbsVBox))
          .map(vca => deformHorizontal(vca, hbox)),
        myLibDeformed: myLib
          .map(vca => deformVertical(vca, deformThumbsVBox))
          .map(vca => deformHorizontal(vca, hbox)),
      }

      return {
        ...state,
        ...deformedLibs,
        deformThumbsHBox: hbox,
        validCalculation: false,
      }
    }

    case actions.DEFORM_SELECTED_BOX: {
      const { hbox, vbox } = action.payload
      let { selectedVca } = state

      if (hbox) {
        selectedVca = deformHorizontal(selectedVca, hbox)
      }

      if (vbox) {
        selectedVca = deformVertical(selectedVca, vbox)
      }

      // recalc it with order values to get more accurate DM calc
      selectedVca = recalcVca({ vca: selectedVca, orderValues: selectOrderValues(rootState) })

      return {
        ...state,
        selectedVca,
        selectedVcaDeformed: selectedVca,
        validCalculation: false,
      }
    }

    case actions.SET_SELECTED_VCA_DBL: {
      const { dbl } = action.payload
      let { selectedVca } = state

      if (typeof dbl !== 'undefined') {
        console.log('!!!!', rootState.fields.OrderForm)
        selectedVca = recalcVca({
          vca: {
            ...selectedVca,
            dbl,
          },
          orderValues: selectOrderValues(rootState),
        })
      }

      return {
        ...state,
        selectedVca,
        selectedVcaDeformed: selectedVca,
        validCalculation: false,
      }
    }

    case actions.SET_SELECTED_VCA_VALUES: {
      const { dbl, frameCurveR, frameCurveL } = action.payload?.values
      let { selectedVca } = state

      selectedVca = recalcVca({
        vca: {
          ...selectedVca,
          dbl: dbl ?? selectedVca.dbl,
          frameCurveR: frameCurveR ?? selectedVca.frameCurveR,
          frameCurveL: frameCurveL ?? selectedVca.frameCurveL,
        },
        orderValues: selectOrderValues(rootState),
      })

      return {
        ...state,
        selectedVca,
        selectedVcaDeformed: selectedVca,
        validCalculation: false,
      }
    }

    case actions.DEFORM_SELECTED: {
      const {
        right = state.deformSelectedRight,
        top = state.deformSelectedTop,
        left = state.deformSelectedLeft,
        bottom = state.deformSelectedBottom,
      } = action.payload
      const { selectedVca } = state

      let selectedVcaDeformed = deformABCD(selectedVca, { right, top, left, bottom })
      selectedVcaDeformed = recalcVca({
        vca: selectedVcaDeformed,
        orderValues: selectOrderValues(rootState),
      })

      return {
        ...state,
        selectedVcaDeformed,
        deformSelectedRight: right,
        deformSelectedTop: top,
        deformSelectedLeft: left,
        deformSelectedBottom: bottom,
      }
    }

    case actions.SAVE_DEFORMED_SELECTED: {
      const normalized = fullNormalize(
        { ...state.selectedVcaDeformed },
        selectOrderValues(rootState),
      )

      // if action was called after shape modification, check if shape was modified
      // if yes, set isSelectedVcaModified to true, so we can show the reset button only if needed
      const newState = {}
      if (action.payload.isShapeModification && !state.isSelectedVcaModified) {
        const wasShapeModified =
          JSON.stringify(state.selectedVcaDeformed.shapeData) !==
          JSON.stringify(state.selectedVca.shapeData)
        newState.isSelectedVcaModified = wasShapeModified
      }

      return {
        ...state,
        ...newState,
        selectedVca: { ...normalized },
        selectedVcaDeformed: { ...normalized },
        deformSelectedRight: 0,
        deformSelectedLeft: 0,
        deformSelectedTop: 0,
        deformSelectedBottom: 0,
        validCalculation: false,
      }
    }

    case actions.RESET_DEFORMED_SELECTED: {
      return {
        ...state,
        selectedVcaDeformed: { ...state.selectedVca },
        deformSelectedRight: 0,
        deformSelectedLeft: 0,
        deformSelectedTop: 0,
        deformSelectedBottom: 0,
      }
    }

    case actions.CALC_VCA_START: {
      const { comparing, lens } = action.meta.action
      return {
        ...state,
        comparedLens: lens || state.comparedLens,
        calculating: !comparing || state.calculating,
        calculatingCompared: comparing || state.calculatingCompared,
        validCalculation: true,
      }
    }

    case actions.CALC_VCA_SUCCESS: {
      const { comparing } = action.meta.action
      let { calculatedVca } = action.payload.data

      const { calculationError, calculationStatus } = calculatedVca

      // get error messages from LW
      const calcStats = transformCalculationStatus(calculationStatus, i18n.t)
      const errorMessages = calcStats?.uniqueErrors || []

      if (calculationError) {
        const notifContent = {
          title: i18n.t('Specific lens calculation error'),
          message: i18n.t('Specific lens calculation error - message'),
        }

        // use custom messages if there are any
        if (errorMessages.length > 0) {
          // this wont build here. TODO move to react code
          // notifContent.message = errorMessages.map((e, key) => 
          //   <div key={key}>{e}</div>,
          // )
          notifContent.message = errorMessages.join(', ')
        }

        notifs.error(notifContent)
      }

      calculatedVca = !calculationError ? processVcaPoints(calculatedVca) : null
      const result = {
        ...state,
        calculatedVca: !comparing ? calculatedVca : state.calculatedVca,
        calculatedComparedVca: comparing ? calculatedVca : state.calculatedComparedVca,
        calculationError: !comparing ? calculationError : state.calculationError,
        calculationErrorCompared: comparing ? calculationError : state.calculationErrorCompared,
        comparedLens: calculationError ? null : state.comparedLens,
        calculating: !comparing ? false : state.calculating,
        calculatingCompared: comparing ? false : state.calculatingCompared,
        validCalculation: true,
      }

      if (comparing) {
        result.calculationStatusCompared = calculationStatus
      } else {
        result.calculationStatus = calculationStatus
      }

      return result
    }

    case actions.CALC_VCA_ERROR: {
      notifs.error({
        // title: 'Chyba kalkulace',
        title: i18n.t('Calculation error'),
        message: i18n.t('Error - try again'),
        // message: 'Zkuste prosím znovu později.',
      })
      const { comparing } = action.meta.action

      return {
        ...state,
        // calculatedVca: null,
        calculationError: !comparing ? 'server' : state.calculationError,
        calculationErrorCompared: comparing ? 'server' : state.calculationErrorCompared,
        comparedLens: comparing ? null : state.comparedLens,
        calculating: false,
        calculatingCompared: false,
        validCalculation: false,
      }
    }

    case actions.CLEAR_CALCULATED_VCA: {
      return {
        ...state,
        calculatedVca: null,
        calculationError: false,
        validCalculation: false,
        calculationStatus: null,
      }
    }
    case actions.CLEAR_CALCULATED_COMPARED_VCA: {
      return {
        ...state,
        calculatedComparedVca: null,
        calculationErrorCompared: false,
        calculationStatusCompared: null,
      }
    }

    case actions.INVALIDATE_CALCULATION: {
      const { inversed } = action.payload
      return {
        ...state,
        validCalculation: inversed || false,
      }
    }

    case actions.PICK_ONE_LENS: {
      const { compared } = action.payload
      if (!compared) {
        return {
          ...state,
          calculatedComparedVca: null,
          comparedLens: null,
        }
      }

      return {
        ...state,
        calculatedComparedVca: null,
        comparedLens: null,
        calculatedVca: state.calculatedComparedVca,
      }
    }

    case actions.SET_HOLES_FOR_EDIT: {
      const { holes } = action.payload
      if (!holes) {
        return {
          ...state,
          holes: [],
        }
      }
      return {
        ...state,
        holes: [...holes],
        validCalculation: false,
      }
    }

    case actions.SAVE_HOLES_AFTER_EDIT: {
      const { selectedVca, selectedVcaDeformed, holes } = state
      return {
        ...state,
        selectedVca: {
          ...selectedVca,
          holes: [...holes],
        },
        selectedVcaDeformed: {
          ...selectedVcaDeformed,
          holes: [...holes],
        },
        validCalculation: false,
      }
    }

    case actions.SET_WANT_CALCULATION: {
      return {
        ...state,
        wantCalculation: action.payload.value,
      }
    }

    case actions.SAVE_VCA_TO_MY_SHAPES_SUCCESS: {
      const { vca } = action.payload.data

      notifs.success({
        // title: 'Uloženo',
        title: i18n.t('Shape was saved'),
        // message: 'Tvar byl uložen'
      })

      const shape = processVca(vca)
      let shapeDeformed = deformVertical(shape, state.deformThumbsVBox)
      shapeDeformed = deformHorizontal(shapeDeformed, state.deformThumbsHBox)
      // recalc it with order values to get more accurate DM calc
      shapeDeformed = recalcVca({ vca: shapeDeformed, orderValues: selectOrderValues(rootState) })

      return {
        ...state,
        myLib: [...state.myLib, shape],
        myLibDeformed: [...state.myLibDeformed, shapeDeformed],
      }
    }
    case actions.SAVE_VCA_TO_MY_SHAPES_ERROR: {
      notifs.error({
        // title: 'Chyba uložení',
        title: i18n.t('Error saving shape'),
        // message: 'Tvar nebyl uložen',
      })
      return state
    }

    case actions.DELETE_MY_SHAPE_SUCCESS: {
      const { _id } = action.meta.action

      // notifs.success({ title: 'Tvar byl smazán' })
      notifs.success({ title: i18n.t('Shape was deleted') })

      return {
        ...state,
        myLib: state.myLib.filter(s => s._id !== _id),
        myLibDeformed: state.myLibDeformed.filter(s => s._id !== _id),
      }
    }
    case actions.DELETE_MY_SHAPE_ERROR: {
      notifs.error({
        // title: 'Chyba smazání',
        title: i18n.t('Error deleting shape'),
        // message: 'Tvar nebyl smazán'
      })
      return state
    }

    // hijack fields and check if sth what changes calculation is changed
    case fieldsActions.FIELDS_SET_FIELD: {
      const { path, value } = action.payload
      const pairKeys = [
        // step 1
        'pd',
        'height',
        'lens',
        'sphere',
        'cylinder',
        'axis',
        'addition',
        'prism1',
        'prism2',
        'base1',
        'base2',
        'baseCurve',
        'diameter',
        'diameterPhysical',
        'cto',
        // step 2
        'minCenterThickness',
        'minEdgeThickness',
        'minHoleThickness',
      ]
      const keys = [
        ...pairKeys.map(k => `${k}R`),
        ...pairKeys.map(k => `${k}L`),
        'frameType',
        'frameCurve',
        'orderType',
        'rightLensEnabled',
        'leftLensEnabled',
      ]
      const deleteComparedVcaKeys = ['orderType']
      let vcaRecalcKeys = ['pd', 'height']
      vcaRecalcKeys = [...vcaRecalcKeys.map(k => `${k}R`), ...vcaRecalcKeys.map(k => `${k}L`)]

      const invalidateCalc = keys.includes(path[1])
      const invalidateVcaRecalc = vcaRecalcKeys.includes(path[1])
      const deleteCalculatedComparedVca = deleteComparedVcaKeys.includes(path[1])

      if (
        path[0] !== 'OrderForm' ||
        (!invalidateCalc && !invalidateVcaRecalc && !deleteCalculatedComparedVca)
      ) {
        return state
      }

      let { selectedVca, validCalculation, calculatedComparedVca } = state

      if (invalidateCalc) {
        // console.log('INVALIDATE  CALC')
        validCalculation = false
      }

      if (invalidateVcaRecalc && selectedVca) {
        // console.log('INVALIDATE  VCA RECALC')
        selectedVca = recalcVca({
          vca: state.selectedVca,
          orderValues: selectOrderValues(rootState),
        })
      }

      if (deleteCalculatedComparedVca) {
        calculatedComparedVca = null
      }

      // sth was changed
      return {
        ...state,
        validCalculation,
        selectedVca,
        calculatedComparedVca,
      }
    }

    case ordersActions.RESET_ORDER: {
      return {
        ...state,
        ...cleanOrder(),
      }
    }

    case ordersActions.FETCH_ORDER_SUCCESS: {
      const { data } = action.payload

      let vca = data.orderType !== orderTypes.DIAMETER_ONLY ? processVca(data, data) : null
      if (vca && !vca.shapeData) {
        vca = null
      }
      // const vca = (
      //   data.orderType !== orderTypes.DIAMETER_ONLY &&
      //   ((data.shapeData.r && data.shapeData.r.length > 0) || (data.shapeData.l && data.shapeData.l.length > 0))
      // ) ? processVca(data) : data

      // console.log('vca', vca)

      // if we have imported miLens BOX, save it to deformThumbsHBox
      return {
        ...state,
        deformThumbsHBox: data.importedHBox,
        deformThumbsVBox: data.importedVBox,
        selectedVca: vca,
        selectedVcaDeformed: { ...vca },
        fetchingOneVca: false,
        validCalculation: false,
      }
    }

    case actions.REMOVE_ALL_HOLES: {
      return {
        ...state,
        holes: [],
      }
    }
    case actions.ADD_OR_EDIT_HOLE: {
      let { holes } = state
      const { startX, startY, id, remove, side } = action.payload

      // delete hole?
      if (id && remove) {
        return {
          ...state,
          holes: holes.filter(h => h.id !== id),
        }
      }

      if (!id && holes.length >= 8) {
        notifs.error({
          // title: 'Nejvyšší počet děr je 8!',
          title: `${i18n.t('Maximum number of holes is') + 8}!`,
        })
        return state
      }

      // editing (or adding)?
      if (id) {
        holes = holes.map(h => {
          if (h.id !== id) return h
          return {
            ...h,
            ...action.payload,
          }
        })
      } else {
        const hole = {
          startX,
          startY,
          endX: startX,
          endY: startY,
          side,
          diameter: 1,
          depth: 0,
          id: uuid(),
        }
        holes = [...holes, hole]
      }
      return {
        ...state,
        holes,
      }
    }

    default:
      return state
  }
}

export default vcaReducer
