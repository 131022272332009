import { appTypeConfig } from '../config'

export const getFrameBrandName = (frameBrands, key) => {
  const frameBrand = frameBrands.find(brand => brand.key === key)
  let brandLabel = frameBrand?.label
  if (!brandLabel) {
    // temporary fallback to labels in appTypeConfig
    brandLabel = appTypeConfig.suppliersMap[key]
  }
  return brandLabel || key
}
