import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import PopupPresentational from '../PopupPresentational'
import { TextField, SelectField, CheckboxField } from '../../fields'
import { Row, Col, FieldsGroup, Button, Text, Table, Icon, InfoMessage } from '../..'
import {
  lensMaterialsFilters,
  progressiveLengths,
  lensVariantSelectTypes,
  L,
  R,
  BOTH,
  appTypeConfig,
} from '../../../../common/config'
import { fetchLensSelectList } from '../../../../common/catalog/actions'
import { fields } from '../../../../common/lib/redux-fields'
import { getLensFieldsValuesBySide } from '../../../../common/helpers'
import { getFinalRadiiByShapeAndLenses } from '../../../../common/lib/vca'
import { filterLensByNameFunction } from '../../../../common/lib/text'
import {
  getAllFramesWithCondition,
  getIsLensCompatibleWithFrameBrand,
  getCompatibleFrameBrands,
} from '../../../../common/catalogExtensions'
import { BrandsIconsList, BrandIcon } from '../../order/FrameBrandIcons'
import { getFrameBrandName } from '../../../../common/catalog/utils'

const { lensFocusTypes, lensBrands } = appTypeConfig

const isLensVariantWhite = lens => !(!!lens.polarised || !!lens.photocromic)
const isLensVariantOther = lens => !(!lens.polarised && !lens.photocromic)

const INITIAL_ROWS_COUNT = 100
const ROWS_TO_LOAD_COUNT = 50

const createSortProps = fields => item => ({
  sortable: true,
  sortHandler: item => {
    const sortBy = fields.sortBy.value
    const newValue = sortBy === item && fields.sortDirection.value === 1 ? -1 : 1
    fields.sortBy.onChange({ value: item })
    fields.sortDirection.onChange({ value: newValue })
  },
  sortState: fields.sortBy.value === item ? fields.sortDirection.value : 0,
})

const createFocusTypesOptions = ({ lensFocusTypes, t }) => {
  const options = Object.keys(lensFocusTypes).reduce((result, key) => {
    const focusType = lensFocusTypes[key]
    if (typeof focusType === 'object') {
      result.push({ value: key, label: t(key), isTopLevel: true })
      Object.keys(focusType).forEach(key => result.push({ value: key, label: t(focusType[key]) }))
    } else {
      result.push({ value: key, label: t(focusType), isTopLevel: true })
    }
    return result
  }, [])
  return options
}

const getCompareFunction = (item, direction) => {
  let result = () => 0
  switch (item) {
    case 'name': {
      if (direction === 1) {
        result = (a, b) => a.names[0].long > b.names[0].long ? 1 : -1
      } else {
        result = (a, b) => a.names[0].long > b.names[0].long ? -1 : 1
      }
      break
    }
    case 'opticalIndex': {
      if (direction === 1) {
        result = (a, b) => a.opticalIndex - b.opticalIndex
      } else {
        result = (a, b) => b.opticalIndex - a.opticalIndex
      }
      break
    }
    case 'orderFrequency': {
      if (direction === 1) {
        result = (a, b) => b.orderFrequency - a.orderFrequency
      } else {
        result = (a, b) => a.orderFrequency - b.orderFrequency
      }
      break
    }
    // no default
  }
  return result
}

const getIsLoadingDone = (prev, now) => prev === true && now === false

const fieldsNames = [
  'name',
  'variant',
  'isStockLens',
  'isNotStockLens',
  'focusTypes',
  'material',
  'progressiveLength',
  'lensBrand',

  'sortBy',
  'sortDirection',
  'frameBrandCompatible',
]
class CatalogPopup extends Component {
  constructor(props) {
    super(props)

    this.tableRef = createRef()

    const { t } = props
    const materialOptions = Object.keys(lensMaterialsFilters).map(key => ({
      value: key,
      label: t(key),
    }))
    const variantOptions = Object.keys(lensVariantSelectTypes).map(key => ({
      value: key,
      label: t(lensVariantSelectTypes[key]),
    }))
    const progressiveLengthsOptions = Object.keys(progressiveLengths).map(key => ({
      value: key,
      label: t(progressiveLengths[key]),
    }))
    const lensBrandsOptions = Object.keys(lensBrands).map(key => ({
      value: lensBrands[key],
      label: lensBrands[key],
    }))

    const focusTypesOptions = createFocusTypesOptions({ lensFocusTypes, t })

    const { limitStart, limitEnd } = this.getListRenderLimits()

    this.state = {
      limitEnd,
      limitStart,
      materialOptions,
      variantOptions,
      progressiveLengthsOptions,
      focusTypesOptions,
      lensBrandsOptions,
    }
  }

  componentDidMount() {
    // našíst data, pokud nejsou načteny
    const {
      lensesListR,
      lensesListIsLoadingR,
      lensesListL,
      lensesListIsLoadingL,
      args,
      fetchLensSelectList,
    } = this.props
    const { side, orderFields } = args
    if (!orderFields) return
    const lensValues = getLensFieldsValuesBySide(orderFields)
    if (lensesListL.length === 0 && (side === L || side === BOTH) && !lensesListIsLoadingL) {
      fetchLensSelectList(lensValues.l, L, orderFields.$values())
    }
    if (lensesListR.length === 0 && (side === R || side === BOTH) && !lensesListIsLoadingR) {
      fetchLensSelectList(lensValues.r, R, orderFields.$values())
    }
  }

  componentDidUpdate(prevProps) {
    const { lensesListIsLoadingR: prevIsLoadingR, lensesListIsLoadingL: prevIsLoadingL } = prevProps
    const { lensesListIsLoadingR, lensesListIsLoadingL } = this.props

    const loadingDoneR = getIsLoadingDone(prevIsLoadingR, lensesListIsLoadingR)
    const loadingDoneL = getIsLoadingDone(prevIsLoadingL, lensesListIsLoadingL)

    if (loadingDoneR || loadingDoneL) {
      const newLimits = this.getListRenderLimits()
      this.setState({ ...newLimits }) // eslint-disable-line
    }
  }

  getListRenderLimits() {
    const {
      args: { selectedId },
    } = this.props
    if (!selectedId) return { limitStart: 0, limitEnd: INITIAL_ROWS_COUNT }
    const currentLensesList = this.getLensesListSortedAndFiltered()

    const index = currentLensesList.findIndex(({ _id }) => _id === selectedId)
    const limitStart = Math.max(index - INITIAL_ROWS_COUNT / 2, 0)
    const limitEnd = limitStart + INITIAL_ROWS_COUNT

    return { limitStart, limitEnd }
  }

  lazyRenderOnScroll = e => {
    const element = e.nativeEvent.target
    const maxScroll = element.scrollHeight - element.offsetHeight
    // console.log('element', element.scrollHeight, element.offsetHeight, element.scrollTop)
    const currentScroll = element.scrollTop
    if (maxScroll - currentScroll < 300) {
      requestAnimationFrame(() => {
        this.setState({ limitEnd: this.state.limitEnd + ROWS_TO_LOAD_COUNT })
      })
    }
    if (this.state.limitStart > 0 && element.scrollTop < 300) {
      let newLimitStart = this.state.limitStart - ROWS_TO_LOAD_COUNT
      if (newLimitStart < 0) newLimitStart = 0
      requestAnimationFrame(() => {
        this.setState({ limitStart: newLimitStart })
      })
    }
  }

  getPossibleFocusTypesOptionsSet = () => {
    const { fields } = this.props
    const { focusTypes } = fields.$values()
    const possibleFocusTypesOptionsSet = new Set()
    if (focusTypes && focusTypes.length) {
      focusTypes.forEach(focusType => {
        if (typeof lensFocusTypes[focusType] === 'object') {
          Object.keys(lensFocusTypes[focusType]).forEach(key => {
            possibleFocusTypesOptionsSet.add(key)
          })
        } else {
          possibleFocusTypesOptionsSet.add(focusType)
        }
      })
    }
    return possibleFocusTypesOptionsSet
  }

  filterLensList = list => {
    const { fields, selectedVca, args, currentLensR, currentLensL, catalogExtensions } = this.props
    const {
      isStockLens,
      isNotStockLens,
      name,
      material,
      progressiveLength,
      variant,
      lensBrand,
      frameBrandCompatible,
    } = fields.$values()
    const { filterByVcaDiameter, orderFields } = args

    const possibleFocusTypesOptionsSet = this.getPossibleFocusTypesOptionsSet()

    // this is used for step 3 - so pick the bigger radius of both lenses
    let finalRadii = {}
    if (filterByVcaDiameter) {
      finalRadii = getFinalRadiiByShapeAndLenses({
        vca: selectedVca,
        lensR: currentLensR,
        lensL: currentLensL,
        order: orderFields.$values(),
      })
    }
    const shouldFilterByDiameter = !!filterByVcaDiameter && selectedVca && finalRadii.b

    return list.filter(lens => {
      if (frameBrandCompatible && frameBrandCompatible.length > 0) {
        const isCompatible = frameBrandCompatible.every(frameBrand =>
          getIsLensCompatibleWithFrameBrand({ lens, frameBrand, catalogExtensions }),
        )
        if (!isCompatible) return false
      }
      if (shouldFilterByDiameter) {
        const selectedDiameter = finalRadii.b * 2
        const diameters = lens.diameters || []
        const isDiameterInRange = diameters.some(({ physical }) => physical > selectedDiameter)
        if (!isDiameterInRange) return false
      }

      if (!filterLensByNameFunction(lens, name)) return false

      if (
        possibleFocusTypesOptionsSet.size > 0 &&
        !possibleFocusTypesOptionsSet.has(lens.filterProductKey)
      ) {
        return false
      }

      if (isStockLens || isNotStockLens) {
        if (lens.stockLens && !isStockLens) return false
        if (!lens.stockLens && !isNotStockLens) return false
      }
      if (variant) {
        if (variant === lensVariantSelectTypes.WHITE && !isLensVariantWhite(lens)) return false
        if (variant === lensVariantSelectTypes.OTHER && !isLensVariantOther(lens)) return false
      }
      if (lensBrand) {
        if (lensBrand !== lens.lensBrand) return false
      }
      if (material) {
        const materialFilter = lensMaterialsFilters[material]
        if (materialFilter.material !== lens.material) return false
        if (materialFilter.indexFrom && materialFilter.indexFrom > lens.opticalIndex) return false
        if (materialFilter.indexTo && materialFilter.indexTo < lens.opticalIndex) return false
      }
      if (progressiveLength && progressiveLength !== lens.filterProgressiveLengthKey) return false

      return true
    })
  }

  getFiltersOptions = () => {
    const {
      lensesListL,
      lensesListR,
      args: { side },
    } = this.props
    const lensesList = side === L ? lensesListL : lensesListR

    const occurringIsStock = new Set()
    const occurringFocusTypes = new Set()
    const occurringProgressiveLengths = new Set()
    const occurringMaterials = new Set()
    const occurringVariants = new Set()
    const occurringLensBrands = new Set()

    lensesList.forEach(lens => {
      occurringIsStock.add(!!lens.stockLens)

      if (isLensVariantWhite(lens)) {
        occurringVariants.add(lensVariantSelectTypes.WHITE)
      } else {
        occurringVariants.add(lensVariantSelectTypes.OTHER)
      }
      if (lens.lensBrand) {
        occurringLensBrands.add(lens.lensBrand)
      }
      if (lens.filterProductKey) {
        occurringFocusTypes.add(lens.filterProductKey)
      }
      if (lens.filterProgressiveLengthKey) {
        occurringProgressiveLengths.add(lens.filterProgressiveLengthKey)
      }
      if (lens.material) {
        if (lensMaterialsFilters[lens.material]) {
          occurringMaterials.add(lens.material)
        } else {
          const materialFilterKey = Object.keys(lensMaterialsFilters).find(materialFilterKey => {
            const { material, indexFrom, indexTo } = lensMaterialsFilters[materialFilterKey]
            return (
              material === lens.material &&
              indexFrom <= lens.opticalIndex &&
              indexTo >= lens.opticalIndex
            )
          })
          occurringMaterials.add(materialFilterKey)
        }
      }
    })
    // add parent focus types
    if (
      occurringFocusTypes.has('A') ||
      occurringFocusTypes.has('B') ||
      occurringFocusTypes.has('C')
    ) {
      occurringFocusTypes.add('Single vision')
    }
    if (
      occurringFocusTypes.has('D') ||
      occurringFocusTypes.has('E') ||
      occurringFocusTypes.has('F')
    ) {
      occurringFocusTypes.add('Progressive')
    }

    const {
      materialOptions,
      variantOptions,
      progressiveLengthsOptions,
      focusTypesOptions,
      lensBrandsOptions,
    } = this.state

    const filteredFocusTypesOptions = focusTypesOptions.filter(({ value }) =>
      occurringFocusTypes.has(value),
    )
    const filteredMaterialOptions = materialOptions.filter(({ value }) =>
      occurringMaterials.has(value),
    )
    const filteredVariantOptions = variantOptions.filter(({ value }) =>
      occurringVariants.has(value),
    )
    const filteredProgressiveLengthsOptions = progressiveLengthsOptions.filter(({ value }) =>
      occurringProgressiveLengths.has(value),
    )
    const filteredLensBrandOptions = lensBrandsOptions.filter(({ value }) =>
      occurringLensBrands.has(value),
    )

    return {
      isStockOptions: Array.from(occurringIsStock),
      focusTypesOptions: filteredFocusTypesOptions,
      progressiveLengthsOptions: filteredProgressiveLengthsOptions,
      materialOptions: filteredMaterialOptions,
      variantOptions: filteredVariantOptions,
      lensBrandOptions: filteredLensBrandOptions,
    }
  }

  getLensesListSortedAndFiltered = () => {
    const {
      lensesListL,
      lensesListR,
      args: { side },
      fields,
    } = this.props

    let lensesList = side === L ? lensesListL : lensesListR
    if (side === BOTH) {
      lensesList = lensesListL.filter(lensL => lensesListR.some(lensR => lensL._id === lensR._id))
    }
    lensesList = this.filterLensList(lensesList)
    const sortBy = fields.sortBy.value
    if (sortBy) {
      const compareFunction = getCompareFunction(sortBy, fields.sortDirection.value)
      lensesList = lensesList.sort((a, b) => compareFunction(a, b))
    }

    return lensesList
  }

  renderFrameBrandFilter = () => {
    const { catalogExtensions, fields, t, frameBrands } = this.props

    const frameBrandsToFilter = getAllFramesWithCondition(catalogExtensions)
    if (!frameBrandsToFilter || frameBrandsToFilter.length === 0) return null

    const options = frameBrandsToFilter.map(frameBrand => ({
      value: frameBrand,
      label: getFrameBrandName(frameBrands, frameBrand),
    }))

    const renderCheckbox = option => {
      let frameBrandCompatible = fields.frameBrandCompatible.value || []

      if (!Array.isArray(frameBrandCompatible)) {
        frameBrandCompatible = []
      }

      const isChecked = frameBrandCompatible?.includes?.(option.value)

      return (
        <CheckboxField
          key={option.value}
          label={t(`${option.label} compatible`)}
          name={option.value}
          value={isChecked}
          onChange={() => {
            if (!isChecked) {
              fields.frameBrandCompatible.onChange({
                value: [...frameBrandCompatible, option.value],
              })
            } else {
              fields.frameBrandCompatible.onChange({
                value: frameBrandCompatible?.filter?.(v => v !== option.value) || [],
              })
            }
          }}
        />
      )
    }

    return (
      <Row>
        <Col width="100%">{options.map(renderCheckbox)}</Col>
      </Row>
    )
  }

  render() {
    const {
      t,
      close,
      fields,
      args,
      lensesListIsLoadingL,
      lensesListIsLoadingR,
      catalogExtensions,
    } = this.props
    const { side, onLensSelect, filterByVcaDiameter, selectedId, disableShapeMandatoryLenses } =
      args
    const { limitStart, limitEnd } = this.state
    const isFilteredByDiameter = !!filterByVcaDiameter

    fieldsNames.forEach(fieldName => {
      const f = fields[fieldName]
      const prevOnChange = f.onChange
      f.onChange = (...props) => {
        prevOnChange(...props)
        this.setState({ limitStart: 0, limitEnd: INITIAL_ROWS_COUNT })
        // scroll to top after filter or sort change
        if (this.tableRef.current) {
          this.tableRef.current.scrollTop = 0
        }
      }
    })

    const {
      materialOptions,
      variantOptions,
      progressiveLengthsOptions,
      focusTypesOptions,
      isStockOptions,
      lensBrandOptions,
    } = this.getFiltersOptions()

    // todo - sorting a filtering by asi neměl probíhat při každém renderu
    // mělo by to být ve statu, nebo zkusi použít memoize
    const sortProps = createSortProps(fields)

    let lensesList = this.getLensesListSortedAndFiltered()

    lensesList = lensesList.slice(limitStart || 0, limitEnd)

    let isLoading = side === L ? lensesListIsLoadingL : lensesListIsLoadingR
    if (side === BOTH) isLoading = lensesListIsLoadingL || lensesListIsLoadingR

    return (
      <PopupPresentational
        title={t('searching in catalog')}
        close={close}
        type="big"
        showControlButtons={false}
        style={{ width: '90%', maxWidth: '130rem', overflow: 'visible' }}
        contentStyle={{ overflow: 'visible' }}
        displayChildrenInMessage={false}
      >
        <Row>
          <Col width="28rem" shrink="0">
            <FieldsGroup title={t('filters')}>
              <Row>
                <Col width="100%">
                  <TextField label={t('lens name')} {...fields.name} />
                </Col>
              </Row>
              <Row>
                <Col width="50%">
                  <CheckboxField
                    label={t('stock')}
                    {...fields.isStockLens}
                    value={
                      (isStockOptions.length === 1 && isStockOptions[0] === true) ||
                      fields.isStockLens.value
                    }
                    disabled={isStockOptions.length < 2}
                  />
                </Col>
                <Col width="50%">
                  <CheckboxField
                    label={t('manufactured')}
                    {...fields.isNotStockLens}
                    value={
                      (isStockOptions.length === 1 && isStockOptions[0] === false) ||
                      fields.isNotStockLens.value
                    }
                    disabled={isStockOptions.length < 2}
                  />
                </Col>
              </Row>

              {this.renderFrameBrandFilter()}

              <Row>
                <Col width="100%">
                  <SelectField
                    label={t('variant')}
                    options={variantOptions}
                    isClearable
                    {...fields.variant}
                  />
                </Col>
              </Row>
              <Row>
                <Col width="100%">
                  <SelectField
                    label={t('focus type')}
                    isMulti
                    options={focusTypesOptions}
                    {...fields.focusTypes}
                  />
                </Col>
              </Row>
              <Row>
                <Col width="100%">
                  <SelectField
                    label={t('material')}
                    options={materialOptions}
                    isClearable
                    {...fields.material}
                  />
                </Col>
              </Row>
              {lensBrandOptions.length > 0 && (
                <Row>
                  <Col width="100%">
                    <SelectField
                      label={t('reize; lens brand')}
                      options={lensBrandOptions}
                      isClearable
                      {...fields.lensBrand}
                    />
                  </Col>
                </Row>
              )}
              {progressiveLengthsOptions.length > 0 && (
                <Row>
                  <Col width="100%">
                    <SelectField
                      label={t('corridor')}
                      isClearable
                      options={progressiveLengthsOptions}
                      {...fields.progressiveLength}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  {isFilteredByDiameter && (
                    <InfoMessage>
                      {t('Only lenses suitable for calculated diameter are shown')}
                    </InfoMessage>
                  )}
                </Col>
              </Row>
              <Row>
                <Col width="100%">
                  <Button id="cleanFilters" onClick={fields.$reset}>
                    {t('clean filters')}
                  </Button>
                </Col>
              </Row>
            </FieldsGroup>
          </Col>
          <Col grow={1}>
            <Table
              // onScroll={this.lazyRenderOnScroll} stopped working in react 17. Delete later after tests
              onBodyScroll={this.lazyRenderOnScroll}
              // TODO:  tenhle mapping by moh byt ve statu
              name="catalog-table"
              data={lensesList.map(l => ({
                _id: l._id,
                name: l.names[0].long,
                isDisabled: disableShapeMandatoryLenses && l.isAvailableOnlyWithShape,
                disabledReasonText: t('Available only with specified shape'),
                ...l,
              }))}
              activeItemId={selectedId}
              bodyRef={this.tableRef}
              columns={[
                {
                  header: t('lens type'),
                  item: 'name',
                  colProps: {
                    grow: 1,
                  },
                  ...sortProps('name'),
                  renderCol: (value, row) => {
                    const compatibleFrameBrands = getCompatibleFrameBrands({
                      lens: row,
                      catalogExtensions,
                    })
                    return (
                      <Row justifyContent="space-between" alignItems="center">
                        <Text>{value}</Text>
                        <BrandsIconsList brands={compatibleFrameBrands} />
                      </Row>
                    )
                  },
                },
                {
                  header: t('index'),
                  item: 'opticalIndex',
                  colProps: {
                    width: '7rem',
                    shrink: 0,
                  },
                  ...sortProps('opticalIndex'),
                  renderCol: value => <Text color="black">{Math.round(value * 100) / 100}</Text>,
                },
                {
                  header: t('material'),
                  item: 'material',
                  colProps: {
                    width: '15rem',
                    shrink: 0,
                  },
                  renderCol: material => <Text>{t(material, { context: 'material' })}</Text>,
                },
                {
                  header: t('order frequency'),
                  item: 'orderFrequency',
                  colProps: {
                    width: '7rem',
                    shrink: 0,
                  },
                  ...sortProps('orderFrequency'),
                  renderCol: (orderFrequency, { hearts }) => {
                    switch (hearts) {
                      case 4: // workaround for hearts algorithm. It can by 4 for first element
                      case 3:
                        return <Icon size={25} name="CustomHeartFull" />
                      case 2:
                        return <Icon size={25} name="CustomHeartHalf" />
                      case 1:
                        return <Icon size={25} name="CustomHeartEmpty" />
                      default:
                        return null
                    }
                  },
                },
                {
                  header: t('availability'),
                  item: 'stockLens',
                  colProps: {
                    width: '12rem',
                    shrink: 0,
                  },
                  renderCol: stockLens => {
                    const text = stockLens ? t('stock') : t('manufactured')
                    const iconName = stockLens ? 'CustomStock' : 'CustomManufactured'
                    return (
                      <Row alignItems="center">
                        <Icon size={30} name={iconName} withoutTooltip />
                        &nbsp;
                        <Text color="black">{text}</Text>
                      </Row>
                    )
                  },
                },
                {
                  header: '',
                  headerColProps: {
                    alignItems: 'center',
                  },
                  colProps: {
                    width: '10rem',
                    shrink: 0,
                  },
                  renderCol: (value, row) => (
                    <Button
                      onClick={() => {
                        onLensSelect(row._id, row)
                        close()
                      }}
                      disabled={row.isDisabled}
                    >
                      {t('choose')}
                    </Button>
                  ),
                },
              ]}
            />
            {isLoading && (
              <Text big center style={{ marginTop: '2rem' }}>
                {t('Loading')}
              </Text>
            )}
            {(!lensesList || lensesList.length === 0) && !isLoading && (
              <Text big center style={{ marginTop: '2rem' }}>
                {t('No options for entered refraction')}
              </Text>
            )}
          </Col>
        </Row>
      </PopupPresentational>
    )
  }
}

CatalogPopup.defaultProps = {
  args: {},
  lensesListR: [],
  lensesListL: [],
  selectedVca: null,
  selectedId: null,
  currentLensL: null,
  currentLensR: null,
}

CatalogPopup.propTypes = {
  close: PropTypes.func.isRequired, // is injected by PopupsGate
  lensesListR: PropTypes.array,
  lensesListL: PropTypes.array,
  args: PropTypes.object,
  fields: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  fetchLensSelectList: PropTypes.func.isRequired,
  lensesListIsLoadingR: PropTypes.bool.isRequired,
  lensesListIsLoadingL: PropTypes.bool.isRequired,
  selectedVca: PropTypes.object,
  selectedId: PropTypes.number,
  currentLensR: PropTypes.object,
  currentLensL: PropTypes.object,
}

const enhance = compose(
  connect(
    ({ catalog, vca }) => ({
      lensesListR: catalog.lensesListR,
      lensesListL: catalog.lensesListL,
      lensesListIsLoadingR: catalog.lensesListIsLoadingR,
      lensesListIsLoadingL: catalog.lensesListIsLoadingL,
      selectedVca: vca.selectedVca,
      currentLensR: catalog.currentLensR,
      currentLensL: catalog.currentLensL,
      catalogExtensions: catalog.catalogExtensions,
      frameBrands: catalog.frameBrands,
    }),
    {
      fetchLensSelectList,
    },
  ),
  fields({
    path: 'CatalogForm',
    getInitialState: () => ({
      frameBrandCompatible: [],
    }),
    fields: fieldsNames,
  }),
  withTranslation(),
)

export default enhance(CatalogPopup)
