import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation, useTranslation } from 'react-i18next'

import { fields } from '../../../common/lib/redux-fields'
import { getFormKeys } from '../../../common/schemaConfig'
import { withNotifs } from '../hoc'

import {
  Page,
  Steps,
  Box,
  Row,
  Button,
  Link,
  Gap,
  CreateOrderButtonsWrapper,
  FieldsGroup,
  Col,
  GroupTitle,
  Text,
  HiddenInPrint,
  Icon,
  WarningMessage,
} from '..'
import Popup from '../popups/Popup'

import { Visualiser } from '../vca'
import {
  fetchVca,
  calcVca3d,
  clearCalculatedVca,
  clearCalculatedComparedVca,
  setWantCalculation,
  pickOneLens,
  invalidateCalculation,
} from '../../../common/vca/actions'
import {
  orderTypes,
  frameTypes,
  R,
  L,
  BOTH,
  appTypeConfig,
} from '../../../common/config'
import { prepareOrderToSend } from '../../../common/orders/utils'
import { setActiveOrderStep } from '../../../common/orders/actions'
import { showPopup } from '../../../common/popups/actions'
import { fetchLens, validateLensParams } from '../../../common/catalog/actions'
import { NumberField } from '../fields'
import {
  clearLensFieldsValues,
  getIndividualMenuKey,
  setLensToFields,
} from '../../app/helpers'
import { getLensFieldsValuesBySide } from '../../../common/helpers'

import { processErrorsAndShowNotifs } from '../../helpers'
import withRouter from '../hoc/withRouter'
import { roundNumber } from '../../../common/lib/numbers'
import BasicCol from '../BasicCol'
import Loading from '../Loading'
import BasicRow from '../BasicRow'
import { BasicValueLoading } from '../Skeleton'
import { transformCalculationStatus } from '../../../common/orders/calculationStatus'

const { individualMenus } = appTypeConfig

// TODO: move to standalone component?
const SectionWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    border: 1px solid ${colors.groupBorder};
    border-radius: 0.6rem;
    display: flex;
    overflow: hidden;
    flex-grow: 1;
    flex-direction: 'row';
    justify-content: space-between;
  `}
`

const Table = styled.div`
  ${({ theme: { colors } }) => css`
    /* border-bottom: 1px solid ${colors.groupBorder}; */
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
  `}
`

const TableCell = styled.div`
  ${({ theme: { colors }, borderLeft, align, bold, width, withBackground, large }) => css`
    padding: 1rem 1rem 1rem 1rem;
    ${borderLeft &&
    `
    border-left: 1px solid ${colors.groupBorder};
  `}
    justify-content: ${align};
    align-items: center;
    font-size: ${large ? 1.6 : 1.4}rem;
    color: ${colors.text2};
    display: flex;
    /* flex-grow: 1; */
    flex-direction: row;
    /* justify-content: space-between; */
    font-weight: ${bold && 'bold'};
    width: ${width || '23rem'};
    background: ${withBackground && colors.comparingTableBackground};
    /* vertical-align: bottom; */
    /* display: table-cell; */
    :last-child {
      border: none;
    }
  `}
`

const TableRow = styled.div`
  ${({ theme: { colors }, withBackground }) => css`
    border-bottom: 1px solid ${colors.groupBorder};
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: stretch;
    background: ${withBackground && colors.comparingTableBackground};

    :last-child {
      border: none;
    }
  `}
`

const TableCellComparedMessage = styled.span`
  ${({ theme: { colors } }) => css`
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0 0 0 1rem;
    font-style: italic;
  `}
`

const Dot = styled.div`
  ${({ theme: { colors }, compared }) => css`
    width: 1.2rem;
    height: 1.2rem;
    background: ${compared ? 'green' : 'orange'};
    border-radius: 100rem;
    margin-right: 1rem;
  `}
`

const CalculationStatusRow = ({ original, compared, label }) => {
  const hasData = original?.hasData || compared?.hasData

  // hide the row if no data to show
  if (!hasData) return null

  const renderCell = values => {
    return (
      <Col>
        {values?.map(({ text }) => 
          <div style={{ color: 'red' }}>{text}</div>,
        )}
      </Col>
    )
  }

  return (
    <TableRow>
      <TableCell width="17rem" withBackground align="right">
        <WarningMessage>{label}</WarningMessage>
      </TableCell>
      <TableCell borderLeft bold>
        {renderCell(original?.r)}
      </TableCell>
      <TableCell bold>{renderCell(compared?.r)}</TableCell>
      <TableCell borderLeft bold>
        {renderCell(original?.l)}
      </TableCell>
      <TableCell bold>{renderCell(compared?.l)}</TableCell>
    </TableRow>
  )
}

const CalculationStatus = ({
  calculationStatus,
  calculationStatusCompared,
}) => {
  const { t } = useTranslation()
  // return null if no status
  if (!calculationStatus && !calculationStatusCompared) return null

  const originalLensStatus = transformCalculationStatus(calculationStatus, t)
  const comparedLensStatus = transformCalculationStatus(calculationStatusCompared, t)

  return (
    <>
      <CalculationStatusRow
        original={originalLensStatus?.errors}
        compared={comparedLensStatus?.errors}
        label={t('error')}
      />
      <CalculationStatusRow
        original={originalLensStatus?.warnings}
        compared={comparedLensStatus?.warnings}
        label={t('Warning')}
      />
    </>
  )
}

class CreateOrderStep3Page extends Component {
  state = {
    closestIndex: 0,
    thicknessSet: 'd3',
    validatingComparedLens: false,
    optionsAvailability: {},
  }

  componentDidMount() {
    if (process.env.IS_BROWSER) {
      window.scrollTo(0, 0)
    }
    const {
      clearCalculatedVca,
      clearCalculatedComparedVca,
      setWantCalculation,
      setActiveOrderStep,
      validCalculation,
      comparedLens,
    } = this.props
    setWantCalculation(true)

    setActiveOrderStep(3)

    // TODO: asi ne vzdy - nejlepsi by bylo invalidovat calcedVca na nejake dane zmeny ve fields...
    // nebo nechat z kroku 4 pokud pujdu zpet...
    if (!validCalculation) {
      clearCalculatedVca()
      clearCalculatedComparedVca()
      this.calcThickness()
      if (comparedLens && comparedLens.code) {
        this.calcThickness(comparedLens.code, comparedLens)
      }
    }
  }

  onComparedLensSelected = async (lensId, lens) => {
    this.calcThickness(lens.code, lens)
  }

  validateComparedLens = async () => {
    const { validateLensParams, fields, comparedLens, currentLensL, currentLensR } = this.props
    const lensFieldsValues = getLensFieldsValuesBySide(fields)
    const values = lensFieldsValues.r
    const response = await validateLensParams({
      id: comparedLens._id,
      customQuery: values,
      side: R,
      fieldValues: fields.$values(),
    }).meta.action.payload
    const optionsAvailability = response.data ? response.data.optionsAvailability : {}
    const currentIndividualMenuKey = getIndividualMenuKey(currentLensR, currentLensL)
    const currentIndividualMenuConf = individualMenus[currentIndividualMenuKey]
    const individualMenuConf = individualMenus[comparedLens.individualMenu]
    const fieldsValues = fields.$values()

    if (currentIndividualMenuConf && !individualMenuConf) {
      optionsAvailability.individualMenu = false
    }
    if (individualMenuConf) {
      if (appTypeConfig.APP_TYPE === 'omega') {
        const isIndividualFieldNotAvailable = (name, side = '') =>
          currentIndividualMenuConf[name] &&
          fieldsValues[`${name}${side}`] &&
          individualMenuConf[name] === false
        if (currentIndividualMenuKey !== comparedLens.individualMenu && currentIndividualMenuConf) {
          optionsAvailability.someIndividualMenuValuesWillBeLost =
            isIndividualFieldNotAvailable('bvd', R) ||
            isIndividualFieldNotAvailable('bvd', L) ||
            isIndividualFieldNotAvailable('panto') ||
            isIndividualFieldNotAvailable('frameBowAngle')
        }
        optionsAvailability.individualMenuMustBeFilled =
          individualMenuConf &&
          ((individualMenuConf.bvd && !fieldsValues.bvdR) ||
            (individualMenuConf.panto && !fieldsValues.panto) ||
            (individualMenuConf.frameBowAngle && !fieldsValues.frameBowAngle))
      } else {
        const areLimitsOk = (fieldConfig = {}, fieldValue) => {
          const { min, max, options } = fieldConfig
          if ((min || min === 0) && fieldValue < min) return false
          if ((max || max === 0) && fieldValue > max) return false
          if (options && !options.includes(fieldValue)) return false
          return true
        }

        // TODO approx fields validation

        // vzít vyplněné položky z aktuálního individual menu
        // kouknout se, jestli jsou dostupné i v novém menu (včetně rozsahů a options)
        if (currentIndividualMenuConf) {
          optionsAvailability.someIndividualMenuValuesWillBeLost = Object.keys(
            currentIndividualMenuConf,
          ).some(fieldKey => {
            const fieldValue = fieldsValues[fieldKey]
            if (!fieldValue && fieldValue !== 0) return false
            const newFieldConf = individualMenuConf[fieldKey]
            if (typeof newFieldConf === 'undefined') return true
            if (!areLimitsOk(newFieldConf, fieldValue)) return true
            return false
          })
        }
        // projít povinné položky nadcházejícího menu
        // zjistit, jestli jsou potřebné hodnoty již ve fields (včetně rozsahů a options)
        optionsAvailability.individualMenuMustBeFilled = Object.keys(individualMenuConf).some(
          fieldKey => {
            const newFieldConf = individualMenuConf[fieldKey]
            if (newFieldConf === true || newFieldConf.isRequired === true) {
              const fieldValue = fieldsValues[fieldKey]
              if (!fieldValue && fieldValue !== 0) return true

              if (!areLimitsOk(newFieldConf, fieldValue)) return true
            }
            return false
          },
        )
      }
    }

    this.setState({
      optionsAvailability,
      validatingComparedLens: false,
    })
  }

  onVisualiserMouseMove = ({ lenseMouseX, lenseMouseY, onRight, shapeMouseX, shapeMouseY }) => {
    // console.log('',  lenseMouseX, lenseMouseY, onRight)
    const { calculatedVca } = this.props
    if (!calculatedVca) {
      return
    }
    const points = calculatedVca.d3[onRight ? 'r' : 'l']
    const edgePoints = calculatedVca.edgeThickness[onRight ? 'r' : 'l']
    let closestIndex = 0
    let closestDistance = 1000000
    let thicknessSet = 'd3'
    points.forEach((p, index) => {
      const dx = p.x - lenseMouseX
      const dy = p.y - lenseMouseY
      const distance = dx ** 2 + dy ** 2
      if (distance < closestDistance) {
        closestIndex = index
        closestDistance = distance
      }
    })
    edgePoints.forEach((p, index) => {
      const dx = p.x - shapeMouseX
      const dy = p.y - shapeMouseY
      const distance = dx ** 2 + dy ** 2
      if (distance < closestDistance) {
        closestIndex = index
        closestDistance = distance
        thicknessSet = 'edgeThickness'
      }
    })
    this.setState({
      closestIndex,
      thicknessSet,
      closestPointSide: onRight ? 'r' : 'l',
      mouseX: shapeMouseX || lenseMouseX,
      mouseY: shapeMouseY || lenseMouseY,
    })
  }

  getThickness = vca => {
    const { closestIndex, thicknessSet, closestPointSide } = this.state

    return (
      (vca &&
        vca[thicknessSet] &&
        vca[thicknessSet][closestPointSide] &&
        vca[thicknessSet][closestPointSide][closestIndex] &&
        vca[thicknessSet][closestPointSide][closestIndex].thickness &&
        vca[thicknessSet][closestPointSide][closestIndex].thickness.toFixed(2)) ||
      ''
    )
  }

  // eslint-disable-next-line
  calcThickness = (lensCode, lens) => {
    const { selectedVca, calcVca3d, fields, currentLensR, currentLensL } = this.props

    let orderValues = {
      ...fields.$cleanValues(),
    }

    orderValues = prepareOrderToSend({ orderValues, selectedVca, currentLensR, currentLensL })

    if (currentLensR) {
      orderValues.lensCodeR = currentLensR.code
    }
    if (currentLensL) {
      orderValues.lensCodeL = currentLensL.code
    }
    if (lensCode) {
      orderValues.lensCodeR = lensCode
      orderValues.lensCodeL = lensCode
    }
    if (lens) {
      orderValues.lensR = lens._id
      orderValues.lensL = lens._id
    }

    calcVca3d({ orderValues, comparing: !!lensCode, lens })
  }

  createCalculatedSet = () => {
    const calculatedPairKeys = [
      'weight',
      'centerThickness',
      'calculatedBaseCurve',
      'thinnestPointShape',
      'thickestPointShape',
      'thinnestPointLense',
      'thickestPointLense',
      'calculatedDiameter',
      'lenseOffsetX',
      'lenseOffsetY',
    ]
    const calculatedSingleKeys = ['d3', 'edgeThickness']

    const { calculatedVca, calculatedComparedVca } = this.props

    const result = {}

    calculatedSingleKeys.forEach(k => {
      result[k] = {
        original: calculatedVca && calculatedVca[k],
        compared: calculatedComparedVca && calculatedComparedVca[k],
      }
    })
    calculatedPairKeys.forEach(k => {
      result[`${k}R`] = {
        original: calculatedVca && calculatedVca[`${k}R`],
        compared: calculatedComparedVca && calculatedComparedVca[`${k}R`],
      }
    })
    calculatedPairKeys.forEach(k => {
      result[`${k}L`] = {
        original: calculatedVca && calculatedVca[`${k}L`],
        compared: calculatedComparedVca && calculatedComparedVca[`${k}L`],
      }
    })

    return result
  }

  getCalcValue = key => {
    const { calculatedVca, calculatedComparedVca } = this.props

    return {
      original: (calculatedVca && calculatedVca[key]) || '',
      compared: (calculatedComparedVca && calculatedComparedVca[key]) || '',
    }
  }

  getComparedData = () => {
    const { fields, t } = this.props
    const values = fields.$values()

    const mmConf = {
      comparedMessagePositive: t('thinner by'),
      comparedMessageNegative: t('thicker by'),
      unit: 'mm',
      comparing: true,
    }
    const keys = {
      weight: {
        title: t('weight'),
        comparedMessagePositive: t('lighter by'),
        comparedMessageNegative: t('heavier by'),
        unit: 'g',
        comparing: true,
      },
      centerThickness: {
        title: t('center thickness'),
        ...mmConf,
      },
      thinnestPointShape: {
        title: t('minEdgeThickness'),
        withShapeOnly: true,
        ...mmConf,
      },
      thickestPointShape: {
        title: t('maxEdgeThickness'),
        withShapeOnly: true,
        ...mmConf,
      },
      calculatedDiameter: {
        title: t('diameter'),
      },
      calculatedBaseCurve: {
        title: t('lens base'),
      },
    }

    if (values.orderType === orderTypes.DIAMETER_ONLY) {
      Object.keys(keys).forEach(k => {
        if (keys[k].withShapeOnly) {
          delete keys[k]
        }
      })
    }

    Object.keys(keys).forEach(k => {
      const data = keys[k]
      let comparedMessageR = ''
      let comparedMessageL = ''
      const r = this.getCalcValue(`${k}R`)
      const l = this.getCalcValue(`${k}L`)
      const diffR = r.original - r.compared || undefined
      const diffL = l.original - l.compared || undefined
      const diffPercentageR = diffR && (diffR / r.original) * 100
      const diffPercentageL = diffL && (diffL / r.original) * 100
      const betterR = !data.comparing ? '' : diffR > 0 ? 'compared' : 'original'
      const betterL = !data.comparing ? '' : diffL > 0 ? 'compared' : 'original'

      if (
        data.comparedMessagePositive &&
        data.comparing &&
        !isNaN(diffR) &&
        !isNaN(diffL) &&
        r.compared &&
        isFinite(diffPercentageR) &&
        isFinite(diffPercentageL)
      ) {
        comparedMessageR = `${
          diffR > 0 ? data.comparedMessagePositive : data.comparedMessageNegative
        } ${Math.abs(diffR).toFixed(2)}${data.unit} (${-diffPercentageR.toFixed(1)}%)`

        comparedMessageL = `${
          diffL > 0 ? data.comparedMessagePositive : data.comparedMessageNegative
        } ${Math.abs(diffL).toFixed(2)}${data.unit} (${-diffPercentageL.toFixed(1)}%)`
      }

      keys[k] = {
        ...data,
        comparedMessageR,
        comparedMessageL,
        r,
        l,
        diffR,
        diffL,
        diffPercentageR,
        betterR,
        diffPercentageL,
        betterL,
      }
    })
    return keys
  }

  validateAndRedirect = link => {
    const { history, fields, currentLensR, currentLensL, selectedVca, t } = this.props

    const errors1 = fields.$validate({ step: 1, selectedVca, currentLensR, currentLensL })
    const errors2 =
      fields.$values().orderType !== orderTypes.DIAMETER_ONLY
        ? fields.$validate({ step: 2, selectedVca, currentLensR, currentLensL })
        : []

    const errors = [...errors1, ...errors2]

    if (errors.length === 0) {
      history.push(link)
    } else {
      processErrorsAndShowNotifs(errors1, 1)
      processErrorsAndShowNotifs(errors2, 2)
    }
  }

  withOpenCatalogPopup = (side, render) => {
    const { fields } = this.props
    const selectedId = side === L ? fields.lensL.value : fields.lensR.value
    return (
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() =>
          this.props.showPopup('catalog', {
            side: BOTH,
            onLensSelect: this.onComparedLensSelected,
            orderFields: this.props.fields,
            selectedId,
            filterByVcaDiameter: true,
            disableShapeMandatoryLenses: fields.orderType.value === orderTypes.DIAMETER_ONLY,
            miLensId: fields?.miLensId?.value,
          })
        }
      >
        {render()}
      </div>
    )
  }

  renderPopupContent = () => {
    const { optionsAvailability, validatingComparedLens } = this.state
    const { t, fields } = this.props
    const values = fields.$values()

    const messages = []
    if (values.orderType === orderTypes.DIAMETER_ONLY && !optionsAvailability.diameter) {
      messages.push(t('Diameter is not available'))
    }
    if (!optionsAvailability.coating) messages.push(t('Coating is not available'))
    if (!optionsAvailability.color) messages.push(t('Color is not available'))
    if (!optionsAvailability.uv) messages.push(t('Uv is not available'))
    if (values.isExpress && !optionsAvailability.express)
      messages.push(t('Express is not available'))
    if (optionsAvailability.coatingIsMandatory) messages.push(t('Coating is mandatory'))
    if (optionsAvailability.colorIsMandatory) messages.push(t('Color is mandatory'))
    if (optionsAvailability.individualMenuMustBeFilled)
      messages.push(t('Individual parameters must be filled'))
    if (optionsAvailability.someIndividualMenuValuesWillBeLost)
      messages.push(t('Some individual parameters are not be available'))
    if (optionsAvailability.individualMenu === false)
      messages.push(t('Individual parameters are not available!'))
    // cto was unchecked
    if ( // TODO - zkontrolovat, zda toto funguje správně v případě JZO
      (values.isCtoCapableR || values.isCtoCapableL) &&
      (values.ctoR === null || values.ctoL === null)
    ) {
      // cto was unchecked, but it wont be available
      if (!optionsAvailability.cto) {
        messages.push(t('Diameter is not available'))
      }
      // cto was unchecked, is available but diameter wont be available
      if (
        !optionsAvailability.diameter &&
        optionsAvailability.cto &&
        values.orderType !== orderTypes.DIAMETER_ONLY
      ) {
        messages.push(t('Diameter is not available'))
      }
    }

    return (
      <>
        {validatingComparedLens 
          ? <>{t('Loading')}</>
         : (
          <>
            <Row>{t('Do you really want to replace original lens with comparison lens?')}</Row>
            {messages.length !== 0 && (
              <>
                <Row>{t('Check these in step 1')}:</Row>
                <Row>
                  <Col justifyContent="flex-start">
                    <ul style={{ textAlign: 'left' }}>
                      {messages.map((m, key) => 
                        <li key={key}>{m}</li>,
                      )}
                    </ul>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </>
    )
  }

  render() {
    const {
      selectedVca,
      calculatedVca,
      calculatedComparedVca,
      calculationError,
      calculationStatus,
      calculationStatusCompared,
      fields,
      showPopup,
      currentLensR,
      currentLensL,
      comparedLens,
      pickOneLens,
      calculating,
      calculatingCompared,
      fetchLens,
      validCalculation,
      invalidateCalculation,
      t,
    } = this.props

    const {
      closestIndex,
      thicknessSet,
      closestPointSide,
      mouseX,
      mouseY,
      optionsAvailability,
      validatingComparedLens,
    } = this.state

    const values = fields.$values()
    const { orderType } = values

    const calcedData = this.createCalculatedSet()
    const comparedData = this.getComparedData()

    let visualiserMessage
    if (calculating) {
      visualiserMessage = t('Calculating, please wait')
    } else if (calculationError === 'server') {
      visualiserMessage = t('Calculation error, try it later')
    } else if (calculationError && calculationError !== 'server') {
      // TODO přidat telefonní číslo do error zprávy
      visualiserMessage = t('Calculation error, contact lab')
    }
    // wrap it into component
    let visualiserMessageComponent
    if (visualiserMessage) {
      visualiserMessageComponent = (
        <div>
          <Row>
            <BasicCol alignItems="center">
              {calculating && <Loading type="puff" />}
              <Gap />
              <Text>{visualiserMessage}</Text>
            </BasicCol>
          </Row>
          <Row justifyContent="center">
            {calculationError === 'server' && !calculating && 
              <Button onClick={() => this.calcThickness()}>{t('try again')}</Button>
            }
          </Row>
        </div>
      )
    }

    const backLink =
      values.orderType === orderTypes.DIAMETER_ONLY
        ? Link.CREATE_ORDER_STEP_1
        : Link.CREATE_ORDER_STEP_2

    // TODO: nazvy kroku nekam centralizovat?? budou se opakovat..
    let backLinkText
    switch (backLink) {
      case Link.CREATE_ORDER_STEP_1: {
        backLinkText = t('STEP_1_NAME')
        break
      }
      case Link.CREATE_ORDER_STEP_2: {
        backLinkText = t('STEP_2_NAME')
        break
      }
      // no default
    }

    const stockLensR = !!(currentLensR && currentLensR.ranges[0].range.stockLens)
    const stockLensL = !!(currentLensL && currentLensL.ranges[0].range.stockLens)
    const lenseOffsetYR = (stockLensR && calculatedVca && -calculatedVca.segmentDropR) || 0
    const lenseOffsetYL = (stockLensL && calculatedVca && -calculatedVca.segmentDropL) || 0
    const lenseOffsetXR = (stockLensR && calculatedVca && calculatedVca.totalInsetR) || 0
    const lenseOffsetXL = (stockLensR && calculatedVca && calculatedVca.totalInsetL) || 0
    const comparedStockLens = !!comparedLens?.stockLens

    // console.log(
    //   'lenseOffsetYR - segmentDropR',
    //   lenseOffsetYR,
    //   'lenseOffsetXR - totalInsetR',
    //   lenseOffsetXR,
    //   stockLensR,
    //   currentLensR,
    //   comparedLens
    // )

    return (
      <>
        <Box>
          <Box.Header>
            <Box.Title>{t('lenses calculation')}</Box.Title>
          </Box.Header>
          <Box.Content>
            <Row marginBottom="0" marginTop="2rem" justifyContent="space-between">
              <GroupTitle>{t('visualization')}</GroupTitle>
              {/* <div>tlacitka tady</div> */}
            </Row>
            <SectionWrapper>
              <Visualiser
                {...selectedVca}
                // hide holes if we have DIA_ONLY order (we keep frame for better UX)
                holes={values.orderType === orderTypes.DIAMETER_ONLY ? null : selectedVca.holes}
                d3={calcedData.d3}
                calculatedDiameterR={calculatedVca && calculatedVca.calculatedDiameterR}
                calculatedDiameterL={calculatedVca && calculatedVca.calculatedDiameterL}
                calculatedDiameterER={calculatedVca && calculatedVca.calculatedDiameterER}
                calculatedDiameterEL={calculatedVca && calculatedVca.calculatedDiameterEL}
                edgeThickness={calcedData.edgeThickness}
                lenseOffsetXR={lenseOffsetXR}
                lenseOffsetXL={lenseOffsetXL}
                lenseOffsetYR={lenseOffsetYR}
                lenseOffsetYL={lenseOffsetYL}
                // calced data and stock is used for offesetting shape thickness (jzo introduced this)
                calcedData={calcedData}
                stockLensR={stockLensR}
                stockLensL={stockLensL}
                comparedStockLens={comparedStockLens}
                // lenseOffsetXR={calculatedVca && calculatedVca.totalInsetR}
                // lenseOffsetXL={calculatedVca && calculatedVca.lenseOffsetXL / 2}
                // lenseOffsetYR={calculatedVca && (-2 * calculatedVca.lenseOffsetYR)}
                // shapeOffsetYR={calculatedVca && calculatedVca.segmentDropR}
                // lenseOffsetYR={calculatedVca && -calculatedVca.segmentDropR}
                // shapeOffsetYR={calculatedVca && (-calculatedVca.segmentDropR)}
                // lenseOffsetYL={calculatedVca && (-calculatedVca.lenseOffsetYL)}
                onMouseMove={this.onVisualiserMouseMove}
                closest3dIndex={closestIndex}
                thicknessSet={thicknessSet}
                withoutShape={orderType === orderTypes.DIAMETER_ONLY}
                closestPointSide={closestPointSide}
                showRadius
                showPupil
                pdR={values.pdR}
                pdL={values.pdL}
                heightR={values.heightR}
                heightL={values.heightL}
                decXR={values.decXR}
                decYR={values.decYR}
                decXL={values.decXL}
                decYL={values.decYL}
                placeholder={!calculatedVca}
                width="800"
                height="400"
                disableL={!values.leftLensEnabled}
                disableR={!values.rightLensEnabled}
                hideToggleButton
                noBorder
                overlayMessage={visualiserMessageComponent}
                hidden={!validCalculation || calculating}
                debug={appTypeConfig.showCalcSurfacePoints || window.CALC_DEBUG}
              />
              <Col paddingRight="3rem">
                <Gap gap="2rem" />
                <FieldsGroup title={t('lens thickness')} noBorder>
                  <Row>
                    <NumberField
                      shrink="0"
                      label={t('original')}
                      name="original"
                      value={this.getThickness(calculatedVca)}
                      disabled
                    />
                    <Gap />
                    <NumberField
                      shrink="0"
                      label={t('comparison')}
                      name="comparison"
                      value={this.getThickness(calculatedComparedVca)}
                      disabled
                    />
                  </Row>
                </FieldsGroup>
                <FieldsGroup title={t('cursor position')} noBorder>
                  <Row>
                    <NumberField
                      shrink="0"
                      label="X"
                      name="X"
                      value={mouseX && mouseX.toFixed(2)}
                      disabled
                    />
                    <Gap />
                    <NumberField
                      shrink="0"
                      label="Y"
                      name="Y"
                      value={mouseY && mouseY.toFixed(2)}
                      disabled
                    />
                  </Row>
                </FieldsGroup>
              </Col>
            </SectionWrapper>
            <Row marginBottom="0" marginTop="2rem" justifyContent="space-between">
              <GroupTitle>{t('calculation and comparison of lenses')}</GroupTitle>
            </Row>

            <SectionWrapper>
              <Table>
                <TableRow withBackground>
                  <TableCell width="17rem" withBackground />
                  <TableCell borderLeft large align="center">
                    <Dot /> {t('original lens')}
                  </TableCell>
                  <TableCell large align="center">
                    {this.withOpenCatalogPopup(R, () => (
                      <>
                        <Dot compared /> {t('comparison lens')}
                      </>
                    ))}
                  </TableCell>
                  <TableCell borderLeft large align="center">
                    <Dot /> {t('original lens')}
                  </TableCell>
                  <TableCell large align="center">
                    {this.withOpenCatalogPopup(L, () => (
                      <>
                        <Dot compared /> {t('comparison lens')}
                      </>
                    ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="17rem" align="flex-end" withBackground>
                    {t('lens type')}
                  </TableCell>
                  <TableCell borderLeft>{currentLensR && currentLensR.names[0].long}</TableCell>
                  <TableCell align={!comparedLens && 'center'}>
                    {comparedLens?.names?.[0]?.long}
                    {calculatingCompared && (
                      <>
                        <Gap />
                        <Loading type="smallPuff" />
                      </>
                    )}
                    {!comparedLens &&
                      this.withOpenCatalogPopup(R, () => <Button>{t('add to comparison')}</Button>)}
                    {comparedLens && !calculatingCompared && (
                      <>
                        <Gap />
                        <Button
                          disabled={calculatingCompared}
                          onClick={() => {
                            pickOneLens()
                          }}
                        >
                          X
                        </Button>
                      </>
                    )}
                  </TableCell>
                  <TableCell borderLeft>{currentLensL && currentLensL.names[0].long}</TableCell>
                  <TableCell align={!comparedLens && 'center'}>
                    {comparedLens && comparedLens.names[0].long}
                    {calculatingCompared && (
                      <>
                        <Gap />
                        <Loading type="smallPuff" />
                      </>
                    )}

                    {!comparedLens &&
                      !calculatingCompared &&
                      this.withOpenCatalogPopup(L, () => <Button>{t('add to comparison')}</Button>)}
                    {comparedLens && !calculatingCompared && (
                      <>
                        <Gap />
                        <Button
                          disabled={calculatingCompared}
                          onClick={() => {
                            pickOneLens()
                          }}
                        >
                          X
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>

                <CalculationStatus
                  calculationStatus={calculationStatus}
                  calculationStatusCompared={calculationStatusCompared}
                />

                {Object.keys(comparedData).map(k => {
                  const data = comparedData[k]
                  // todo duplicated code on PrintOrder
                  const decimals = k === 'calculatedBaseCurve' ? 2 : 1
                  return (
                    <TableRow key={k}>
                      <TableCell width="17rem" align="flex-end" withBackground>
                        {data.title}
                      </TableCell>
                      <TableCell bold={data.betterR === 'original'} borderLeft>
                        {calculating && !data.r.original && <BasicValueLoading />}
                        {roundNumber(data.r.original, decimals)}
                      </TableCell>
                      <TableCell bold={data.betterR === 'compared'}>
                        {calculatingCompared && !data.r.compared && 
                          <BasicValueLoading widthMin={40} widthMax={80} />
                        }
                        {roundNumber(data.r.compared, decimals)}

                        {data.comparedMessageR && (
                          <TableCellComparedMessage>
                            {' '}
                            - {data.comparedMessageR}
                          </TableCellComparedMessage>
                        )}
                      </TableCell>
                      <TableCell bold={data.betterL === 'original'} borderLeft>
                        {calculating && !data.l.original && <BasicValueLoading />}
                        {roundNumber(data.l.original, decimals)}
                      </TableCell>
                      <TableCell bold={data.betterL === 'compared'}>
                        {calculatingCompared && !data.l.compared && 
                          <BasicValueLoading widthMin={40} widthMax={80} />
                        }
                        {roundNumber(data.l.compared, decimals)}
                        {data.comparedMessageL && (
                          <TableCellComparedMessage>
                            {' '}
                            - {data.comparedMessageL}
                          </TableCellComparedMessage>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </Table>
            </SectionWrapper>
            <Row justifyContent="center" marginTop="2rem">
              <Button
                disabled={!comparedLens || calculatingCompared}
                onClick={() => {
                  pickOneLens()
                }}
              >
                {t('choose original lens')}
              </Button>
              <Gap />
              <Popup
                title={t('Replacing of the original lens')}
                text={this.renderPopupContent()}
                closeOnOutsideClick
                closeOnEsc
                onClose={async opts => {
                  if (opts.ok && comparedLens) {
                    clearLensFieldsValues({
                      fields,
                      side: 'R',
                      disableCleanOptions: optionsAvailability,
                    })
                    clearLensFieldsValues({
                      fields,
                      side: 'L',
                      disableCleanOptions: optionsAvailability,
                    })
                    fields.$setValue('lensR', comparedLens._id)
                    fields.$setValue('lensL', comparedLens._id)
                    const response = await fetchLens(comparedLens._id, 'BOTH').meta.action.payload
                    pickOneLens({ compared: true })
                    invalidateCalculation(true)
                    fields.$setValidationOptions({
                      mandatoryOptionsR: {
                        coating: optionsAvailability.coatingIsMandatory,
                        color: optionsAvailability.colorIsMandatory,
                      },
                      mandatoryOptionsL: {
                        coating: optionsAvailability.coatingIsMandatory,
                        color: optionsAvailability.colorIsMandatory,
                      },
                    })
                    setLensToFields({ fields, side: R, lens: response.data })
                    setLensToFields({ fields, side: L, lens: response.data })

                    fields.$setValue('isCtoCapableR', response.data.isCtoCapable)
                    fields.$setValue('isCtoCapableL', response.data.isCtoCapable)
                    // reset cto codes
                    if (fields.ctoR.value || fields.ctoL.value) {
                      fields.$setValue('ctoR', response.data.ctoCode)
                      fields.$setValue('ctoL', response.data.ctoCode)
                    }
                  }
                }}
                okText={t('change')}
                closeText={t('cancel')}
              >
                {({ open }) => (
                  <>
                    <Button
                      onClick={e => {
                        this.setState({ validatingComparedLens: true })
                        this.validateComparedLens()
                        open(e)
                      }}
                      disabled={!comparedLens || calculatingCompared}
                    >
                      {t('choose comparison lens')}
                    </Button>
                  </>
                )}
              </Popup>
            </Row>
          </Box.Content>
        </Box>
        <CreateOrderButtonsWrapper>
          <Row>
            <Button.StepBack to={backLink}>{backLinkText}</Button.StepBack>
          </Row>
          <Row>
            <Button.TrashOrder fields={fields} />
            <Gap />
            <Button.SaveConcept fields={fields} />
            <Gap />
            <Button.PrintOrder />
          </Row>
          <Row>
            <Button.Step4 onClick={() => this.validateAndRedirect(Link.CREATE_ORDER_STEP_4)} />
          </Row>
        </CreateOrderButtonsWrapper>
      </>
    )
  }
}

CreateOrderStep3Page.defaultProps = {
  calculatedVca: null,
  calculatedComparedVca: null,
  calculationError: '',
  currentLensR: null,
  currentLensL: null,
  calculationStatus: null,
  calculationStatusCompared: null,
}

CreateOrderStep3Page.propTypes = {
  fetchVca: PropTypes.func.isRequired,
  calcVca3d: PropTypes.func.isRequired,
  clearCalculatedVca: PropTypes.func.isRequired,
  setWantCalculation: PropTypes.func.isRequired,
  selectedVca: PropTypes.object,
  calculatedVca: PropTypes.object,
  calculatedComparedVca: PropTypes.object,
  calculationError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  calculationStatus: PropTypes.object,
  calculationStatusCompared: PropTypes.object,
  setActiveOrderStep: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
  pickOneLens: PropTypes.func.isRequired,
  currentLensR: PropTypes.object,
  currentLensL: PropTypes.object,
  fields: PropTypes.object.isRequired,
  calculating: PropTypes.bool.isRequired,
  calculatingCompared: PropTypes.bool.isRequired,
  validCalculation: PropTypes.bool.isRequired,
  invalidateCalculation: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  validateLensParams: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      holes: state.vca.holes,
      selectedVca: state.vca.selectedVca,
      calculatedVca: state.vca.calculatedVca,
      calculatedComparedVca: state.vca.calculatedComparedVca,
      calculationError: state.vca.calculationError,
      calculationStatus: state.vca.calculationStatus,
      calculationStatusCompared: state.vca.calculationStatusCompared,
      currentLensR: state.catalog.currentLensR,
      currentLensL: state.catalog.currentLensL,
      comparedLens: state.vca.comparedLens,
      calculating: state.vca.calculating,
      calculatingCompared: state.vca.calculatingCompared,
      validCalculation: state.vca.validCalculation,
    }),
    {
      fetchVca,
      calcVca3d,
      clearCalculatedVca,
      setWantCalculation,
      setActiveOrderStep,
      showPopup,
      pickOneLens,
      fetchLens,
      invalidateCalculation,
      validateLensParams,
      clearCalculatedComparedVca,
    },
  ),
  withRouter,
  withNotifs,
  withTranslation(),
)

export default enhance(CreateOrderStep3Page)
