import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { components as selectComponents } from 'react-select'
import { Row, Col, FieldsGroup, WarningMessage } from '..'
import { LensSelectField, SelectField, CheckboxField, NumberField } from '../fields'
import {
  lensOptionsSelectTypes,
  orderTypes,
  R,
  L,
  appTypeConfig,
  expressCodesArray,
} from '../../../common/config'
import { validateLensParams, fetchLensSelectList, fetchLens } from '../../../common/catalog/actions'
import { clearLensFieldsValues, getOptionsBySelectType, setLensToFields } from '../../app/helpers'
import { getLensFieldsValuesBySide } from '../../../common/helpers'
import { withNotifs } from '../hoc'
import { bugsnagClient, notifyInfo, notifyWarning } from '../../bugsnag'
import {
  filterColors,
  isColorAvailableWithNewCoating,
  isExpressDisabledWithCoating,
  getCompatibleFrameBrands,
  getIsColorRequiredWithCoating,
} from '../../../common/catalogExtensions'
import { BaseCurveSelect } from '.'
import { SelectValue, SelectOption } from '../fields/components/SelectComponents'

const CUSTOM = 'CUSTOM'

const IsSameAsRightField = styled(CheckboxField)`
  position: absolute;
  top: 0;
  right: 0;
`

const getDiameterOptions = (diameters, isElliptical) =>
  diameters
    .filter(d => !!d.elliptic === !!isElliptical)
    .sort((a, b) => a.physical >= b.physical)
    .map(d => ({
      label: `${d.physical}${d.optical ? ` / ${d.optical}` : ''}${isElliptical ? ' E' : ''}`,
      value: JSON.stringify(d),
    }))

const autoDiameterOnlyProps = t => ({
  options: [{ label: t('automatic'), value: 1 }],
  value: 1,
  disabled: true,
})

const CustomOption = ({ children, ...props }) => {
  const { label, value } = props.data
  return <SelectOption {...props}>{children}</SelectOption>
}

class LensTypeSection extends Component {
  dontClearLensOnUpdate = false

  async componentDidUpdate(prevProps) {
    const { side, fields, lensFields, catalogExtensions } = this.props
    const prevLens = side === L ? prevProps.currentLensL || {} : prevProps.currentLensR || {}
    const currentLens = side === L ? this.props.currentLensL || {} : this.props.currentLensR || {}
    if (prevLens._id !== currentLens._id) {
      await setLensToFields({
        fields,
        side,
        lens: currentLens,
        dontClearLensOnUpdate: this.dontClearLensOnUpdate,
        catalogExtensions,
      })
      this.dontClearLensOnUpdate = false

      // set cto values
      const { isCtoCapable, ctoCode } = currentLens
      this.getLensField(lensFields.isCtoCapable).onChange({ value: isCtoCapable })
      const ctoField = this.getLensField(lensFields.cto)

      const { isCtoCheckedByDefault, isCtoEnabled } = appTypeConfig
      if (isCtoCheckedByDefault && ctoField.value !== null && ctoCode)
        ctoField.onChange({ value: ctoCode })

      if (side === R && fields.isLeftLensSameAsRight.value) {
        fields.isCtoCapableL.onChange({ value: isCtoCapable })
        if (isCtoCheckedByDefault && ctoField.value !== null && ctoCode)
          fields.ctoL.onChange({ value: ctoCode })
      }

      // right now for JZO is isCtoCapable always true,
      // because there always have to be diameter selection available
      // logic is different from reize
      if (isCtoEnabled && !isCtoCheckedByDefault) {
        // if cto is checked and user switch to lens without cto, uncheck cto
        if (!ctoCode && ctoField.value !== null) ctoField.onChange({ value: null })
      }
    }
  }

  onIsLeftLensSameAsRightClick = async () => {
    const { validateLensParams, fields, notifs, t, currentLensR } = this.props

    const values = fields.$values()

    const valuesToValidate = Object.keys(values).reduce((result, key) => {
      if (
        [
          'diameterPhysicalL',
          'diameterOpticalL',
          'isEllipticalL',
          appTypeConfig.isBaseCurveSelectEnabled && 'baseCurveL',
        ].includes(key)
      ) {
        return result
      }
      /* Je potřeba do validace poslat průměr z pravé čočky,
      který chceme do levé nakopírovat */
      // a baseCurve v případě reize
      if (
        key.endsWith(L) ||
        [
          'diameterPhysicalR',
          'diameterOpticalR',
          'isEllipticalR',
          appTypeConfig.isBaseCurveSelectEnabled && 'baseCurveR',
        ].includes(key)
      ) {
        const { value } = fields[key]
        if (!value && value !== 0) return result
        const newKey = key.slice(0, key.length - 1)

        if (
          key === 'baseCurveR' &&
          appTypeConfig.isBaseCurveSelectEnabled &&
          !currentLensR?.baseCurve?.length > 0
        ) {
          return result
        }

        result[newKey] = value
      }
      return result
    }, {})

    const validationResult = values.lensR
      ? await validateLensParams({
          id: values.lensR,
          customQuery: { ...valuesToValidate, orderType: values.orderType },
          side: L,
          fieldValues: values,
        }).meta.action.payload
      : { data: { result: true } }
    const result = validationResult.data && validationResult.data.result

    if (
      appTypeConfig.isBaseCurveSelectEnabled &&
      validationResult.data.optionsAvailability &&
      !validationResult.data.optionsAvailability.baseCurve
    ) {
      return notifs.warning({
        title: t('Selected basecurve is not available for left eye!'),
      })
    }

    if (result) {
      fields.isLeftLensSameAsRight.onChange({ value: true })
      this.setRightLensParamsToLeftLens()
    } else {
      notifs.warning({
        title: t('Selected lens is not available for left eye!'),
      })
    }
  }

  setRightLensParamsToLeftLens = () => {
    const { fields, fetchLens } = this.props
    const copyValueToLeft = fieldNames => {
      const options = fieldNames.map(fieldName => ({
        fieldName: `${fieldName}L`,
        value: fields[`${fieldName}R`].value,
      }))
      fields.$setMultipleValues(options)
    }

    const fieldsToSet = [
      'lens',
      'diameterPhysical',
      'diameterOptical',
      'isElliptical',
      'coating',
      'color',
      'uv',
      'isDiameterCustom',
      'cto',
    ]

    if (appTypeConfig.isBaseCurveSelectEnabled) {
      fieldsToSet.push('baseCurve')
    }

    copyValueToLeft(fieldsToSet)
    this.dontClearLensOnUpdate = true
    const lensRId = fields.lensR.value
    if (lensRId) {
      fetchLens(lensRId, L, getLensFieldsValuesBySide(fields).l)
      // TODO , toto nikdy nefugovalo - čočky se stahují jinde a není to třeba
      // - smazat, pokud to bude ok
      // fetchLensSelectList(getLensFieldsValuesBySide(fields), L, fields.$values())
    }
  }

  getLensField = fieldName => {
    const { fields, side } = this.props
    const isLeftLensSameAsRight = fields.isLeftLensSameAsRight.value
    const field = fields[`${fieldName}${side}`]
    if (isLeftLensSameAsRight && side === R) {
      const onChangeL = fields[`${fieldName}${L}`]
      return {
        ...field,
        onChange: e => {
          field.onChange(e)
          // sets same value for left side, if isSameAsRight is checked
          onChangeL.onChange(e)
        },
      }
    }
    return {
      ...field,
      onChange: e => {
        field.onChange(e)
      },
    }
  }

  // we can have EXP24 and EXP48
  getAvailableExpressCodes = () => {
    const { currentLensL, currentLensR, fields } = this.props

    const isLeftSideEnabled = fields.leftLensEnabled.value
    const isRightSideEnabled = fields.rightLensEnabled.value

    const getOptionsBySide = side => {
      const lens = side === L ? currentLensL : currentLensR
      if (!lens) return []
      const options = getOptionsBySelectType(lens.optionsArray, lensOptionsSelectTypes.EXPRESS)
      return options.options
        .filter(({ originalId }) => expressCodesArray.includes(originalId))
        .map(({ originalId }) => originalId)
    }

    if (!isLeftSideEnabled && !isRightSideEnabled) return []
    if (isLeftSideEnabled && isRightSideEnabled) {
      const rightOptions = getOptionsBySide(R)
      const leftOptions = getOptionsBySide(L)
      return rightOptions.filter(option => leftOptions.includes(option))
    }
    if (isLeftSideEnabled) return getOptionsBySide(L)
    if (isRightSideEnabled) return getOptionsBySide(R)

    return []
  }

  getIsExpressPossible = () => {
    const { currentLensL, currentLensR, catalogExtensions, fields } = this.props
    const lensL = currentLensL || {}
    const lensR = currentLensR || {}
    const optionsL = getOptionsBySelectType(lensL.optionsArray, lensOptionsSelectTypes.EXPRESS)
    const optionsR = getOptionsBySelectType(lensR.optionsArray, lensOptionsSelectTypes.EXPRESS)
    const coatingOptionsL = getOptionsBySelectType(
      lensL.optionsArray,
      lensOptionsSelectTypes.COATING,
    )
    const coatingOptionsR = getOptionsBySelectType(
      lensR.optionsArray,
      lensOptionsSelectTypes.COATING,
    )

    const isExpressDisabledR = isExpressDisabledWithCoating({
      coatingId: fields.coatingR.value,
      catalogExtensions,
      coatingOptions: coatingOptionsR,
    })
    const isExpressDisabledL = isExpressDisabledWithCoating({
      coatingId: fields.coatingL.value,
      catalogExtensions,
      coatingOptions: coatingOptionsL,
    })
    if (isExpressDisabledR || isExpressDisabledL) return false
    return optionsL.isPossible && optionsR.isPossible
  }

  // create props for express checkboxes
  getExpressFieldConfig = expressCode => {
    const { fields, t } = this.props

    const isExpressPossible = this.getIsExpressPossible()
    const availableExpressCodes = this.getAvailableExpressCodes()

    const result = {
      name: `express-${expressCode}`,
      tooltip: t('paid service'),
      disabled: !isExpressPossible || !availableExpressCodes.includes(expressCode),
    }

    let currentValue = fields.isExpress.value && fields.expressCode.value === expressCode

    // if no express code is stored, it is EXP24
    if (fields.isExpress.value && !fields.expressCode?.value && expressCode === 'EXP24') {
      currentValue = true
    }
    result.value = currentValue

    result.onChange = ({ checked }) => {
      if (checked) {
        fields.expressCode.onChange({ value: expressCode })
        fields.isExpress.onChange({ value: true })
      } else {
        fields.expressCode.onChange({ value: null })
        fields.isExpress.onChange({ value: false })
      }
    }

    return result
  }

  clearLensFields = (side = R, disableCleanOptions) => {
    const { fields } = this.props
    clearLensFieldsValues({ fields, side, cleanLens: false, disableCleanOptions })
  }

  diameterOnChange = ({ value }) => {
    const { fields, side, notifs, currentLensL, currentLensR, lensFields, t } = this.props
    if (value === CUSTOM) {
      this.getLensField('isDiameterCustom').onChange({ value: true })
      this.getLensField(lensFields.diameterOptical).onChange({ value: undefined })
      return
    }

    // if backspace is pressed in select field, empty array is returned, which is causing crash here
    // this is workaround
    if (typeof value === 'object' && value.length === 0) return

    // should not happen anymore - delete later if ok
    try {
      JSON.parse(value)
    } catch (error) {
      notifyWarning(new Error('Pro Michala - diameter on change crash'), {
        fields: fields.$values(),
        diameterOnChangeValue: value,
        side,
        currentLensR,
        currentLensL,
      })
    }

    const { physical, optical } = JSON.parse(value)
    this.getLensField('isDiameterCustom').onChange({ value: false })

    if (
      side === R &&
      fields.isLeftLensSameAsRight.value &&
      // fields.leftLensEnabled.value !== false &&
      getDiameterOptions(
        (currentLensL && currentLensL.diameters) || [],
        this.getLensField(lensFields.isElliptical).value,
      ).some(d => d.value === value) === false
    ) {
      if (!currentLensL) {
        notifyWarning(
          new Error('Pro Michala - currentLensL is not defined when changing diameter'),
          {
            fields: fields.$values(),
          },
        )
      }
      notifs.warning({
        title: t('Selected diameter is not available for left eye!'),
      })
      fields.isLeftLensSameAsRight.onChange({ value: false })
    } else {
      this.getLensField(lensFields.diameterPhysical).onChange({ value: physical })
      this.getLensField(lensFields.diameterOptical).onChange({ value: optical || '' })
    }
  }

  setSelectedLens = ({ value }) => {
    const { fields, lensesL, side, notifs, t, lensFields } = this.props
    if (
      side === R &&
      fields.isLeftLensSameAsRight.value &&
      lensesL.some(lens => lens._id === value) === false // &&
      // fields.leftLensEnabled.value !== false
    ) {
      notifs.warning({
        title: t('Selected lens is not available for left eye!'),
      })
      fields.isLeftLensSameAsRight.onChange({ value: false })
    }
    // TODO - tady zkontrolovat platnosti options???
    this.getLensField(lensFields.lens).onChange({ value })
  }

  isSelectedCoatingUvAbsorbing = () => {
    const { side, lensFields, currentLensL, currentLensR } = this.props
    const currentLens = side === L ? currentLensL || {} : currentLensR || {}
    const { optionsArray } = currentLens
    const coatingOptions = getOptionsBySelectType(optionsArray, lensOptionsSelectTypes.COATING)
    const selectedCoating = this.getLensField(lensFields.coating).value
    if (!coatingOptions || !coatingOptions.options || !selectedCoating) return false
    const selectedCoatingOption = coatingOptions.options.find(({ _id }) => _id === selectedCoating)
    const uvAbsorbing = selectedCoatingOption && selectedCoatingOption.uvAbsorbing
    return uvAbsorbing === '1'
  }

  // adds extra data to coating options from catalog extensions
  applyCatalogExtensions = ({ lens, options }) => {
    const { catalogExtensions } = this.props

    const lensCompatibleFrameBrands = getCompatibleFrameBrands({ lens, catalogExtensions })

    // if lens is not compatible with any frame brand, we do not need to handle coating options
    if (!lensCompatibleFrameBrands || lensCompatibleFrameBrands?.length === 0) return

    options?.selectOptions?.forEach(option => {
      const compatibleFrameBrands = getCompatibleFrameBrands({ lens, option, catalogExtensions })
      if (!compatibleFrameBrands || compatibleFrameBrands?.length === 0) return
      // check if lens is compatible with frame brand,
      // otherwise is no point in showing it in the option
      const filteredCompatibleFrameBrands = compatibleFrameBrands?.filter(frameBrand =>
        lensCompatibleFrameBrands.includes(frameBrand),
      )
      option.compatibleFrameBrands = filteredCompatibleFrameBrands
    })
  }

  render() {
    const {
      fields,
      label,
      side,
      lensFields,
      currentLensL,
      currentLensR,
      fetchLensesActions,
      showIsLeftSameAsRight,
      t,
      selectedLensName,
      catalogExtensions,
      notifs,
    } = this.props
    const currentLens = side === L ? currentLensL || {} : currentLensR || {}
    const { optionsArray } = currentLens
    let colorOptions = getOptionsBySelectType(optionsArray, lensOptionsSelectTypes.COLOR)

    const coatingOptions = getOptionsBySelectType(optionsArray, lensOptionsSelectTypes.COATING)
    this.applyCatalogExtensions({ lens: currentLens, options: coatingOptions })

    const validationOptions = fields.$getValidationOptions()

    // color can be required because of catalog extension
    // that is why we also need to use validationOptions
    const isColorRequired =
      validationOptions?.[`mandatoryOptions${side}`]?.color || colorOptions?.isMandatory

    const uvOptions = getOptionsBySelectType(optionsArray, lensOptionsSelectTypes.UV)
    const ctoOptions = getOptionsBySelectType(optionsArray, lensOptionsSelectTypes.CTO)
    const ecsOptions = getOptionsBySelectType(optionsArray, lensOptionsSelectTypes.ECS)
    const diameters = currentLens.diameters || []
    const canDiameterBeElliptic = diameters.filter(d => !!d.elliptic).length > 0
    const fieldValues = fields.$values()

    colorOptions = filterColors({
      catalogExtensions,
      colorOptions,
      coatingOptions,
      selectedCoating: fields[`coating${side}`]?.value,
    })

    const diameterOptions = getDiameterOptions(
      diameters,
      this.getLensField(lensFields.isElliptical).value,
    )
    if (
      appTypeConfig.isCustomDiameterEnabled &&
      currentLens &&
      currentLens.ranges &&
      !currentLens.ranges[0].range.stockLens
    ) {
      diameterOptions.push({ label: t('Custom'), value: CUSTOM })
    }

    const isRxDesignCheckboxVisible =
      appTypeConfig.isCtoEnabled && fields.orderType.value !== orderTypes.DIAMETER_ONLY

    if (showIsLeftSameAsRight && fields.isLeftLensSameAsRight.value) {
      return (
        <Row compact>
          <Col width="100%">
            <FieldsGroup title={label}>
              <Row>
                <CheckboxField label={t('same as right')} {...fields.isLeftLensSameAsRight} />
              </Row>
            </FieldsGroup>
          </Col>
        </Row>
      )
    }
    const selectedDiameterValue = JSON.stringify({
      physical: this.getLensField(lensFields.diameterPhysical).value,
      optical: this.getLensField(lensFields.diameterOptical).value || undefined,
      elliptic: this.getLensField(lensFields.isElliptical).value ? 1 : 0,
    })
    if (
      appTypeConfig.isCustomDiameterEnabled &&
      this.getLensField(lensFields.diameterPhysical).value &&
      currentLens.diameters &&
      fields.orderType.value === orderTypes.DIAMETER_ONLY
    ) {
      const isDiameterCustom = !diameters.some(
        ({ physical }) => physical === this.getLensField(lensFields.diameterPhysical).value,
      )
      if (isDiameterCustom && !this.getLensField('isDiameterCustom').value) {
        this.diameterOnChange({ value: CUSTOM })
      }
    }

    const onCoatingChange = (...args) => {
      const newCoatingId = args[0].value
      const colorField = this.getLensField(lensFields.color)
      const isColorAvailable = isColorAvailableWithNewCoating({
        coatingId: newCoatingId,
        catalogExtensions,
        coatingOptions,
        colorOptions,
        colorId: colorField.value,
      })

      if (!isColorAvailable) {
        colorField.onChange({ value: null })
        if (fields.isLeftLensSameAsRight.value) {
          fields.colorL.onChange({ value: null })
        }
      }

      if (fieldValues.isExpress) {
        const isExpressDisabled = isExpressDisabledWithCoating({
          coatingId: newCoatingId,
          catalogExtensions,
          coatingOptions,
        })

        if (isExpressDisabled) {
          fields.isExpress.onChange({ value: false })

          notifs.warning({
            title: t('Express delivery is not possible with the selected coating!'),
          })
        }
      }
      this.getLensField(lensFields.coating).onChange(...args)

      // check MANDATORY_COMBINATION extension
      const isColorRequired = getIsColorRequiredWithCoating({
        coatingId: newCoatingId,
        catalogExtensions,
        coatingOptions,
      })
      
      // set validation options for color field to ensure correct validation
      fields.$setValidationOptions({
        [`mandatoryOptions${side}`]: {
          color: colorOptions.isMandatory || isColorRequired,
        },
      })
      if (side === R && fields.isLeftLensSameAsRight.value) {
        fields.$setValidationOptions({
          mandatoryOptionsL: {
            color: colorOptions.isMandatory || isColorRequired,
          },
        })
      }
    }

    return (
      <>
        <Row compact>
          <Col width="100%">
            <FieldsGroup title={label}>
              {showIsLeftSameAsRight && (
                <IsSameAsRightField
                  label={t('same as right')}
                  {...fields.isLeftLensSameAsRight}
                  onChange={this.onIsLeftLensSameAsRightClick}
                />
              )}
              <Row>
                <Col width="100%">
                  <LensSelectField
                    onFocus={fetchLensesActions}
                    {...this.getLensField(lensFields.lens)}
                    required
                    onChange={this.setSelectedLens}
                    side={side}
                    isLeftLensSameAsRight={fields.isLeftLensSameAsRight.value}
                    selectedLensName={selectedLensName}
                    disableShapeMandatoryLenses={
                      fields.orderType.value === orderTypes.DIAMETER_ONLY
                    }
                    fieldValues={fieldValues}
                  />
                  {currentLens.withDegression && (
                    <WarningMessage>
                      {t('reize; WARNING MESSAGE - lens with degression')}
                    </WarningMessage>
                  )}
                </Col>
              </Row>
              <Row alignItems="flex-end">
                <Col compact width="16rem">
                  <SelectField
                    label={t('lens diameter')}
                    options={diameterOptions}
                    {...this.getLensField(lensFields.diameterPhysical)}
                    value={selectedDiameterValue}
                    onChange={this.diameterOnChange}
                    required={fields.orderType.value === orderTypes.DIAMETER_ONLY}
                    {...(this.getLensField('isDiameterCustom').value
                      ? { value: CUSTOM, errors: null }
                      : {})}
                    // TODO REFACTOR
                    {...(fields.orderType.value !== orderTypes.DIAMETER_ONLY &&
                    (!currentLens.isCtoCapable ||
                      (currentLens.isCtoCapable && this.getLensField(lensFields.cto).value))
                      ? autoDiameterOnlyProps(t)
                      : {})}
                  />
                </Col>
                {this.getLensField('isDiameterCustom').value && (
                  <Col>
                    <NumberField after="mm" {...this.getLensField(lensFields.diameterPhysical)} />
                  </Col>
                )}

                {/* je to pro reize i jzo, ale s obrácenou logikou. Je potřeba to nějak rozdělit a ošetřit */}

                {isRxDesignCheckboxVisible && (
                  <Col>
                    <CheckboxField
                      label={t('cto')}
                      disabled={!ctoOptions.isPossible}
                      {...this.getLensField(lensFields.cto)}
                      value={
                        this.getLensField(lensFields.isCtoCapable).value &&
                        this.getLensField(lensFields.cto).value
                      }
                      style={{ whiteSpace: 'nowrap' }}
                      onChange={({ checked }) => {
                        const optionCode = ctoOptions.options[0].originalId
                        this.getLensField(lensFields.cto).onChange({
                          value: checked ? optionCode : null,
                        })
                      }}
                    />
                  </Col>
                )}
                {appTypeConfig.isEllipticalDiameterEnabled &&
                  (fields.orderType.value === orderTypes.DIAMETER_ONLY ||
                    (this.getLensField(lensFields.isCtoCapable).value &&
                      !this.getLensField(lensFields.cto).value)) && (
                    <Col>
                      <CheckboxField
                        label={t('elliptical shape')}
                        disabled={!canDiameterBeElliptic}
                        {...this.getLensField(lensFields.isElliptical)}
                      />
                    </Col>
                  )}
                {appTypeConfig.isBaseCurveSelectEnabled && (
                  <Col width="100%" maxWidth={!isRxDesignCheckboxVisible ? '19.2rem' : '22rem'}>
                    <BaseCurveSelect side={side} fields={fields} />
                  </Col>
                )}
              </Row>
              {appTypeConfig.isExpressOrderEnabled && (
                <Row isInvisible={side === L && fields.rightLensEnabled.value}>
                  {(appTypeConfig.isExpress48Enabled && (
                    <Col>
                      <CheckboxField
                        label={t('express order 24')}
                        {...this.getExpressFieldConfig('EXP24')}
                      />

                      <CheckboxField
                        label={t('express order 48')}
                        {...this.getExpressFieldConfig('EXP48')}
                      />
                    </Col>
                  )) || (
                    <CheckboxField
                      label={t('express order')}
                      {...this.getExpressFieldConfig('EXP24')}
                    />
                  )}
                </Row>
              )}
            </FieldsGroup>
          </Col>
        </Row>
        <Row>
          <Col width="100%">
            <FieldsGroup title={t('lens modifications')}>
              <Row>
                <Col width="50%">
                  <SelectField
                    label={t('coating')}
                    isClearable={!coatingOptions.isMandatory}
                    required={coatingOptions.isMandatory}
                    options={coatingOptions.selectOptions}
                    components={{ Option: CustomOption, SingleValue: SelectValue }}
                    {...this.getLensField(lensFields.coating)}
                    onChange={onCoatingChange}
                  />
                </Col>
                <Col width="50%">
                  <SelectField
                    label={t('color')}
                    isClearable={!colorOptions.isMandatory}
                    required={isColorRequired}
                    options={colorOptions.selectOptions}
                    {...this.getLensField(lensFields.color)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CheckboxField
                    label={t('UV')}
                    disabled={!uvOptions.isPossible}
                    {...this.getLensField(lensFields.uv)}
                    value={
                      uvOptions.isPossible && appTypeConfig.isUvSelectionEnabled
                        ? this.getLensField(lensFields.uv).value
                        : this.isSelectedCoatingUvAbsorbing()
                    }
                    onChange={({ checked }) => {
                      const { value } = uvOptions.selectOptions[0]
                      this.getLensField(lensFields.uv).onChange({ value: checked ? value : null })
                    }}
                  />
                </Col>
                <Col>
                  {ecsOptions?.isPossible && (
                    <CheckboxField
                      label="EyecuraSee"
                      {...this.getLensField(lensFields.ecs)}
                      disabled
                    />
                  )}
                </Col>
              </Row>
            </FieldsGroup>
          </Col>
        </Row>
      </>
    )
  }
}

LensTypeSection.defaultProps = {
  currentLensR: {},
  currentLensL: {},
  lensesR: [],
  lensesL: [],
  showIsLeftSameAsRight: false,
  selectedLensName: '',
  catalogExtensions: [],
}

LensTypeSection.propTypes = {
  fields: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  side: PropTypes.oneOf([R, L]).isRequired,
  lensFields: PropTypes.object.isRequired,
  currentLensR: PropTypes.object,
  currentLensL: PropTypes.object,
  notifs: PropTypes.object.isRequired,
  lensesR: PropTypes.array,
  lensesL: PropTypes.array,
  fetchLensesActions: PropTypes.func.isRequired,
  showIsLeftSameAsRight: PropTypes.bool,
  validateLensParams: PropTypes.func.isRequired,
  fetchLensSelectList: PropTypes.func.isRequired,
  fetchLens: PropTypes.func.isRequired,
  selectedLensName: PropTypes.string,
  catalogExtensions: PropTypes.array,
}

const enhance = compose(
  connect(
    ({ catalog }) => ({
      currentLensR: catalog.currentLensR,
      currentLensL: catalog.currentLensL,
      lensesR: catalog.lensesListR,
      lensesL: catalog.lensesListL,
      catalogExtensions: catalog.catalogExtensions,
    }),
    {
      validateLensParams,
      fetchLensSelectList,
      fetchLens,
    },
  ),
  withNotifs,
  withTranslation(),
)

export default enhance(LensTypeSection)
