// temporary solution for omega non cto lenses
const getIsOmegaLensCtoCapable = lens => {
  try {
    if (lens?.isNonCto) return false
    return true
  } catch (error) {
    console.notifyWarning(error, { msg: "error in getIsOmegaLensCtoCapable" })
    // if something fails, we consider the lens as cto capable to avoid blocking the user
    return true
  }
}

export default getIsOmegaLensCtoCapable
